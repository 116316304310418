import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export function EmptyResultsPlaceholder() {
    return (
        <Stack
            sx={{ height: '300px', textAlign: 'center', py: 5, px: 3 }}
            alignItems="center"
            justifyContent="center"
            gap={2}
        >
            <SearchOffIcon fontSize="large" />
            <Typography variant="h6">Nessun risultato</Typography>
            <Typography variant="body2" color="text-muted">
                La ricerca non ha prodotto risultati. Prova a cambiare i parametri di
                ricerca per ottenere più risultati.
            </Typography>
        </Stack>
    );
}
