import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BrowserRouter } from 'react-router-dom'
import SearchTabs from './SearchTabs'
import Grid from '@mui/material/Grid'
import SearchBar from './SearchBar'

interface ISearch {
  id: number | null
  name: string
  tel: string | null
}

interface ISearchData {
  search?: ISearch
}

const CandidatesIndexPage = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={4} sx={{ p: 2 }}>
        <SearchBar />
      </Grid>
      <Grid item xs={12} sm={8} sx={{ p: 2 }}>
        <SearchTabs />
      </Grid>
    </Grid>
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <CandidatesIndexPage {...props}></CandidatesIndexPage>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
