import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useGetCustomersQuery } from '../../../services/crmApi';
import LeadsTable from './LeadsTable';
import Pagination from '@mui/material/Pagination'
import { Stack } from '@mui/material';

const CUSTOMER_TABS = ["leads", "submitted", "completed"]

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function LeadsTabs(props: { searchValue: string }) {
    const [value, setValue] = React.useState<number>(0);
    const [page, setPage] = React.useState<number>(1);

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    // Reset page when searchValue changes
    React.useEffect(() => {
        setPage(1);
    }, [props.searchValue])

    const { data, isFetching } = useGetCustomersQuery({
        tab: CUSTOMER_TABS[value],
        query: props.searchValue,
        page: page
    })

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable tabs"
            >
                <Tab label="Potenziali" {...a11yProps(0)} />
                <Tab label="Da approvare" {...a11yProps(1)} />
                <Tab label="Approvati" {...a11yProps(2)} />
            </Tabs>
            {/* <TabPanel value={0} index={0}>
            </TabPanel>
                <LeadsTable data={data} /> */}
            {CUSTOMER_TABS.map((tab, index) => (
                <TabPanel key={tab} value={value} index={index}>
                    <LeadsTable data={data?.results || []} tab={CUSTOMER_TABS[value]} />
                </TabPanel>
            ))}
            {data && data.total > 0 &&
                <Stack direction="row" justifyContent="end">

                    <Pagination
                        onChange={handleChangePage}
                        count={Math.ceil(data.total / data.perPage)}
                        page={data.currentPage}
                        sx={{ p: 2 }}
                    />
                </Stack>
            }
        </Box>
    );
}

export { LeadsTabs }