import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  ICandidateAttributes
} from '../../../../../types'
import GeoSelect from '../../../../input/select/GeoSelect.jsx'
import { getGeocode, getLatLng, getZipCode, getDetails } from 'use-places-autocomplete'
import { CountrySelect } from '../../../../input/select/CountrySelect'
import { Button, TextField, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'

const AddressSection: React.FC<{}> = ({ }) => {
  const form = useFormContext<ICandidateAttributes>()

  function getAddressComponent(result: any, type: string, shortName = false) {
    const component = result.address_components.find((component) =>
      component.types.includes(type)
    );
    return component ? (shortName ? component.short_name : component.long_name) : null;
  }

  const handlePlaceChange = (place) => {
    if (!place) {
      // User cleared the geocomplete input
      form.setValue('street', '');
      form.setValue('street_number', '');
      form.setValue('city', '');
      form.setValue('province', '');
      form.setValue('zip', '');
      form.setValue('address_country', '');
      form.setValue('latitude', '');
      form.setValue('longitude', '');
      return;
    }

    const parameter = { placeId: place.place_id };
    getGeocode(parameter).then((results) => {
      if (results.length === 0) return;
      // const address = results[0].address_components
      console.log('result', results[0]);

      // Set coordinates
      const { lat, lng } = getLatLng(results[0]);
      form.setValue('latitude', lat.toString());
      form.setValue('longitude', lng.toString());

      // Set ZIP code
      const zip = getZipCode(results[0], false); // False for short_name
      form.setValue('zip', zip || '');

      form.setValue('street', getAddressComponent(results[0], 'route'));
      form.setValue('street_number', getAddressComponent(results[0], 'street_number'));
      form.setValue('city', getAddressComponent(results[0], 'locality'));
      form.setValue(
        'province',
        getAddressComponent(results[0], 'administrative_area_level_2', true)
      );
      form.setValue('address_country', getAddressComponent(results[0], 'country', true));
    });
  };

  const [showAllFields, setShowAllFields] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Indirizzo</Typography>
      </Grid>

      {/* Main search bar */}
      <Grid xs={12}>
        <GeoSelect name={'full_address'} control={form.control} onPlaceChange={handlePlaceChange} />
      </Grid>

      <Grid xs={12}>
        <Divider>
          <Typography variant="caption">Campi indirizzo</Typography>
        </Divider>
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="street"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Via" fullWidth required
              error={!!form.formState.errors.street}
              helperText={form.formState.errors.street?.message}
            />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="street_number"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Civico num." fullWidth />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="zip"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="CAP" fullWidth />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="city"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Città" fullWidth required
              error={!!form.formState.errors.city}
              helperText={form.formState.errors.city?.message}
            />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="province"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Provincia" fullWidth />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="address_country"
          control={form.control}
          render={({ field }) => (
            <CountrySelect label="Paese" fullWidth required
              error={!!form.formState.errors.address_country}
              helperText={form.formState.errors.address_country?.message}

              {...field} />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="latitude"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Latitudine" fullWidth />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name="longitude"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Longitudine" fullWidth />
          )}
        />
      </Grid>

    </Grid>
  );
};

export default AddressSection;
