import { Joi } from '../../utils/validation'
import { joiResolver } from '@hookform/resolvers/joi'

const schema = Joi.object({
  images: Joi.array().items(
    Joi.object({
      file: Joi.object().required()
    }).unknown()
  )
}).unknown() // fields not specified in this schema will be ignored by the resolver

export default joiResolver(schema)
