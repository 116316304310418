import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFiltersContext } from '../useFiltersContext'

function Actions(props) {
  const { isLoading } = useFiltersContext()

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      sx={{ mt: 2 }}
      fullWidth
      loading={isLoading}
    >
      Applica
    </LoadingButton>
  )
}

export default Actions
