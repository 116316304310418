import React, { useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'

const FocusTrapTextField: React.FC<React.ComponentProps<typeof TextField>> = (
  props
) => {
  const ref = useRef(null)

  useEffect(() => {
    setInterval(() => {
      // Check if the user is focused on an input. If so, do nothing
      // else, focus on the first input
      if (
        document.activeElement.tagName !== 'INPUT' &&
        document.activeElement.tagName !== 'TEXTAREA' &&
        document.activeElement.tagName !== 'TEXTFIELD'
      ) {
        ref.current.focus()
      }
    }, 50)
  }, [])

  return <TextField inputProps={{ ref }} {...props} />
}

export default FocusTrapTextField
