import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useGetQuickCandidateSearchQuery } from '../../../services/crmApi';
import { PendingCandidatesTable } from './PendingCandidatesTable';
import { CANDIDATE_VISIBLE_STEPS } from '../../../enums'

const CANDIDATE_TABS = CANDIDATE_VISIBLE_STEPS.filter(step => step.showTab)

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function PendingCandidatesTabs(props: { searchValue: string }) {
    const [value, setValue] = React.useState(0);

    const { data, isFetching } = useGetQuickCandidateSearchQuery({
        step: CANDIDATE_TABS[value].value.toString(),
        query: props.searchValue,
        //We use polling to update next step as elasticsearch is not realtime updated
        // and clicking next step may not show accurate results immediately
    }, { pollingInterval: 1000 })

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable tabs"
            >
                {CANDIDATE_TABS.map((tab, index) => (
                    <Tab key={tab.value.toString()} label={tab.label} {...a11yProps(index)} />
                ))}
            </Tabs>
            {CANDIDATE_TABS.map((tab, index) => (
                <TabPanel key={tab.value.toString()} value={value} index={index}>
                    <PendingCandidatesTable data={data} />
                </TabPanel>
            ))}
        </Box>
    );
}

export { PendingCandidatesTabs }