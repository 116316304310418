import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TabbedFiltersForm from '../../searches/form/TabbedFiltersForm'
import Fetcher from '../../../utils/Fetcher'
import { useSearchParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { ISearchAttributes } from '../../../types'

const DialogFiltersForm: React.FC<any> = ({}) => {
  const [open, setOpen] = React.useState(false)
  let [searchParams, setSearchParams] = useSearchParams()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleMutationSuccess = (search: ISearchAttributes) => {
    //TODO: deduplicate
    //@ts-ignore-next-line
    notice('Filtri aggiornati con successo')
    handleClose()
    if (!search) return
    if (search.id.toString() === searchParams.get('search')) return
    searchParams.set('search', search.id.toString())
    setSearchParams(searchParams)
  }

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={handleClickOpen}
        startIcon={<EditIcon />}
      >
        Modifica
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>Modifica ricerca</DialogTitle>
        <DialogContent>
          <Fetcher.SearchModel
            render={({ data }) => (
              <TabbedFiltersForm
                defaultValues={data}
                onMutationSuccess={handleMutationSuccess}
              />
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DialogFiltersForm
