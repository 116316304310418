import React from 'react'
import Stack from '@mui/material/Stack'
import RefFormSection from './sections/RefFormSection'
import JobFormSection from './sections/JobFormSection'
import PersonalityFormSection from './sections/PersonalityFormSection'
import ColfFormSection from './sections/ColfFormSection'
import CaretakerFormSection from './sections/CaretakerFormSection'
import NannyFormSection from './sections/NannyFormSection'
import OtherFormSection from './sections/OtherFormSection'
import AssistedFormSection from './sections/AssistedFormSection'
import { FormProvider } from 'react-hook-form'
import useUnsavedWarning from '../../../hooks/useUnsavedWarning'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'

interface IProps {
  defaultValues: any
  disabled?: boolean,
  onSubmit: (data) => void,
  children?: React.ReactNode,
}

/**
 * The body of the reference rapid form. It is a grid of fields
 * that can be filled in any order.
 *
 * The disabled prop is used to put the form in read-only mode, for
 * displaying the reference data in the carousel page.
 *
 * @param defaultValues The default values of the form. Must be a reference object
 * @param disabled If true, the form is disabled and can be used only for display purposes
 */
const RapidFormBody: React.FC<IProps> = ({
  defaultValues,
  disabled = false,
  onSubmit,
  children,
}) => {

  const form = useForm({ defaultValues })

  useUnsavedWarning(form.formState.isDirty)

  return (
    <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>

      {/* Form body */}
      <FormProvider {...form}>
        {/* <FloatingTrapTextField /> */}
        <fieldset disabled={disabled}>
          <Stack spacing={0}>
            <RefFormSection />
            <JobFormSection />
            <AssistedFormSection />
            <PersonalityFormSection />
            <ColfFormSection />
            <CaretakerFormSection />
            <NannyFormSection />
            <OtherFormSection />
          </Stack>
        </fieldset>
      </FormProvider>


      {/* Form actions */}
      {children}
    </Box>



  )
}

export default RapidFormBody
