import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import { default as MuiSlider } from '@mui/material/Slider'
import { nanoid } from 'nanoid'

interface Props extends React.ComponentProps<typeof MuiSlider> {
  label: string
  boldLabel?: boolean
}

const Slider = React.forwardRef<HTMLInputElement, Props>(
  ({ label, boldLabel = false, ...props }, ref) => {
    const id = `slider-${nanoid()}}`

    return (
      <Box>
        <Typography id={id} gutterBottom>
          {boldLabel ? <b>{label}</b> : label}
        </Typography>
        <MuiSlider
          valueLabelDisplay="auto" // come before props to allow override in score fields
          {...props}
          aria-labelledby={`slider-${id}}`}
          sx={{
            '& .MuiSlider-markLabel[style="left: 0%;"]': {
              transform: 'translateX(0%)'
            },
            '& .MuiSlider-markLabel[style="left: 100%;"]': {
              transform: 'translateX(-100%)'
            }
          }}
        />
      </Box>
    )
  }
)

export default Slider
