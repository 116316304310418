import React from 'react'
import { BOOL_OPTIONS } from '../../../../enums'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Colf"
    bgColor="#7F96FF"
    colSize={3}
    fields={[
      {
        name: 'colf_speed',
        label: '⏩ Velocità',
        options: REF.colf_speed
      },
      {
        name: 'colf_cleanliness',
        label: '🧽 Pulizia di casa',
        options: REF.colf_cleanliness
      },
      {
        name: 'colf_tidiness',
        label: '🏠 Ordine della casa',
        options: REF.colf_tidiness
      },
      {
        name: 'colf_precision',
        label: '🎯 Precisa',
        options: REF.colf_precision
      },
      {
        name: 'ironing_skills',
        label: '👚 Stiro',
        options: REF.ironing_skills
      },
      {
        name: 'cooking_skills',
        label: '🍳 Cucina',
        options: REF.cooking_skills
      },
      {
        name: 'serves_table',
        label: '🍽️ Serve a tavola',
        options: BOOL_OPTIONS
      }
    ]}
  />
)

export default FormSection
