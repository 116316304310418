import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select, { SelectProps } from './Select';

interface ControlledSelectProps extends Omit<SelectProps, 'name' | 'value'> {
    name: string;
}

const ControlledSelect: React.FC<ControlledSelectProps> = ({ name, ...other }) => {
    const methods = useFormContext();

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field }) => (
                <Select
                    {...field}
                    value={field.value ?? ''}
                    {...other}
                />
            )}
        />
    );
};

export default ControlledSelect;