import React from 'react'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import 'moment/locale/it'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';

interface IProps extends React.ComponentProps<typeof DesktopDatePicker> {
  value: string
  onChange: (val: string) => void
}
import { DateTimePickerToolbar } from '@mui/x-date-pickers/DateTimePicker';

/**
 * A DateInput with default italian locale.
 * @param value The value of the DateInput. Must be a string in the format YYYY-MM-DD
 * @param onChange The function to call when the DateInput value changes. Receives a string in the format YYYY-MM-DD
 * @param other The other props to pass to the DateInput
 *
 * @example
 * <DateInput
 *  value={"2021-12-31"}
 *  onChange={(val) => console.log(val)}
 * />
 *
 */
const DateInput = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const handleClearDate = () => {
    props.onChange('') // Reset the value to an empty string
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <DesktopDatePicker
          {...props}
          // Convert the value to a moment date
          value={props.value ? moment(props.value) : null}
          // Convert the moment date to a UTC string YYYY-MM-DD
          onChange={(date: moment.Moment) => {
            // if (date?.isValid() && date?.year() > 1900 && date?.year() < 2100) {
            // Call the onChange function with the date in the format YYYY-MM-DD
            props.onChange(date?.format('YYYY-MM-DD'))
            // } else {
            //   // Invalid date, reset the value
            //   props.onChange('')
            // }
          }}
          ref={ref}
        />
        <IconButton size="small" onClick={handleClearDate}>
          <BackspaceOutlinedIcon />
        </IconButton>
      </Stack>
    </LocalizationProvider >
  )
})

export default DateInput
