import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'

/**
 * RapidFormGrid is a Mui Grid with some defaults. Used to
 * arrange RapidFormTiles in a RapidFormSection.
 */

const RapidFormGrid = (props) => {
  const { sx, ...rest } = props
  return <Grid container spacing={0.4} sx={{ px: 0.3, ...sx }} {...rest} />
}

export { RapidFormGrid }
