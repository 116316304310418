// Replace null or undefined values with empty string
// This is used to prevent the error "A component is changing an uncontrolled input of type text to be controlled"
// when the value is null or undefined
//
// Operates in an immutable way, so it returns a new object
// instead of mutating the original object
//
// TODO: move to services/railsApiUtils
export default function setNullToEmptyString(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => setNullToEmptyString(item))
  } else if (typeof obj !== 'object') {
    return obj
  }

  const changed = Object.keys(obj).reduce((newObj, key) => {
    const value = obj[key]
    newObj[key] =
      value === null || value === undefined
        ? // Value is null or undefined, so replace with empty string
          ''
        : Array.isArray(value)
        ? // Value is an array, so recursively call this function on it
          setNullToEmptyString(value)
        : value

    return newObj
  }, {})

  return changed
}
