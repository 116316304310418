import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { RapidFormGrid } from './RapidFormGrid'
import Grid from '@mui/material/Unstable_Grid2'
import { IChipInputTileProps, RapidFormTile } from './RapidFormTile'

interface IRapidFormSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  bgColor: string
}
interface ISectionSubComponentProps {
  ChipSection: typeof ChipSection
}

/**
 * A section of a RapidForm, with a title rotated 270 degrees
 * and a free form content. It is used to group together related
 * inputs.
 *
 * @param title The title of the section
 * @param bgColor The background color of the section
 * @param children The content of the section
 *
 */
const RapidFormSection: React.FC<IRapidFormSectionProps> &
  ISectionSubComponentProps = (props) => {
    return (
      <Stack
        direction="row"
        sx={{ py: 0.2 }}
        style={{ backgroundColor: props.bgColor }}
      >
        {/* Section title */}
        <Box
          style={{
            width: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <b style={{ transform: 'rotate(270deg)' }}>{props.title}</b>
        </Box>
        {/* Section content */}
        <Box sx={{ flexGrow: 1 }}>{props.children}</Box>
      </Stack>
    )
  }

interface IChipSectionProps extends IRapidFormSectionProps {
  fields: Array<IChipInputTileProps>
  colSize?: number
}

/**
 * A section of a RapidForm, preconfigured to only contain an array of chip inputs.
 * It is used as a conveniente way to create a section that groups together related radio inputs.
 * @param fields An array of props for the radio inputs
 */
const ChipSection: React.FC<IChipSectionProps> = (props) => {
  const { fields, colSize, ...rest } = props
  return (
    <RapidFormSection {...rest}>
      <RapidFormGrid>
        {fields.map((fieldProps: IChipInputTileProps) => (
          <Grid xs={colSize || 3} key={fieldProps.name}>
            <RapidFormTile.ChipInput {...fieldProps} />
          </Grid>
        ))}
      </RapidFormGrid>
    </RapidFormSection>
  )
}

RapidFormSection.ChipSection = ChipSection

export { RapidFormSection }
