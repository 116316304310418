import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import COUNTRIES_OPTIONS from '../../../../locales/countries'
import Select from '../../../input/select/Select'
import SelectMulti from '../../../input/select/SelectMulti'
import Slider from '../../../input/Slider'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'
import {
  useGetDegreesQuery,
  useGetCoursesQuery
} from '../../../../services/crmApi'

function Education(props) {
  const { control } = useFormContext()
  const { data: coursesOptions } = useGetCoursesQuery({})
  const { data: degreesOptions } = useGetDegreesQuery({})

  return (
    <Stack gap={2}>
      {coursesOptions && (
        <Controller
          name="course_ids"
          control={control}
          render={({ field }) => (
            <SelectMulti
              label="Corsi"
              options={coursesOptions}
              fullWidth
              {...field}
            />
          )}
        />
      )}
      {degreesOptions && (
        <Controller
          name="degree_ids"
          control={control}
          render={({ field }) => (
            <SelectMulti
              label="Lauree"
              options={degreesOptions}
              fullWidth
              {...field}
            />
          )}
        />
      )}
    </Stack>
  )
}

export default Education
