import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import GeoSelect from '../../../input/select/GeoSelect'
import Slider from '../../../input/Slider'
import Stack from '@mui/material/Stack'
import {
  useGetAvailabilitiesQuery,
  useGetJobsQuery
} from '../../../../services/crmApi'
import { getGeocode, getLatLng } from 'use-places-autocomplete'

function Location({ subformPrefix = '' }: { subformPrefix?: string }) {
  const { control, setValue } = useFormContext()

  const addPrefix = (name: string) => {
    return subformPrefix + name
  }

  const handlePlaceChange = (place) => {
    if (!place) {
      // User cleared the geocomplete input
      setValue(addPrefix('latitude'), '')
      setValue(addPrefix('longitude'), '')
      return
    }

    const parameter = { placeId: place.place_id }
    getGeocode(parameter).then((results) => {
      if (results.length === 0) return
      const { lat, lng } = getLatLng(results[0])
      setValue(addPrefix('latitude'), lat)
      setValue(addPrefix('longitude'), lng)
    })
  }

  return (
    <Stack gap={2}>
      <GeoSelect
        name={addPrefix('full_address')}
        control={control}
        onPlaceChange={handlePlaceChange}
      />
      <Controller
        name={addPrefix('range')}
        control={control}
        render={({ field }) => (
          <Slider {...field} label="Raggio (Km)" min={1} max={99} step={1} />
        )}
      />
    </Stack>
  )
}

export default Location
