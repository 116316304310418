import * as React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
// import { useGetMostFreqValsQuery } from '../../../services/crmApi'

type ChipSuggestionsProps = Omit<
  React.ComponentProps<typeof Stack>,
  'onChange'
> & {
  name: string
  options: string[]
  onChange: (option: string) => void
  chipProps?: React.ComponentProps<typeof Chip>
}

const ChipSuggestions: React.FC<Omit<ChipSuggestionsProps, 'name'>> = ({
  options,
  onChange,
  chipProps,
  ...other
}) => {
  return (
    <Stack direction="row" gap={1} flexWrap="wrap" {...other}>
      {options.map((option, i) => (
        <Chip
          key={i}
          label={`+ ${option}`}
          onClick={() => onChange(option)}
          {...chipProps}
        />
      ))}
    </Stack>
  )
}

// const ClickableChipsSuggestions: React.FC<ClickableChipsProps> = (props) => {
//   const { data } = useGetMostFreqValsQuery(props.name)

//   return (
//     <ClickableChips
//       {...props}
//       onChange={props.onChange}
//       options={data || ['Caricamento', 'in', 'corso', '...']}
//     />
//   )
// }

export { ChipSuggestions }
