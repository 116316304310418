import { useSearchParams } from 'react-router-dom'
import { ModelId } from '../../types'
import {
  useCreateOrUpdateSearchMutation,
  useGetSearchQuery
} from '../../services/crmApi'

const useSearch = () => {
  // Parse search ID from URL
  const [searchParams] = useSearchParams()
  const searchId: ModelId = searchParams.get('search')

  // Query
  const query = useGetSearchQuery(searchId)

  // Mutation
  const mutation = useCreateOrUpdateSearchMutation()

  return { searchId, query, mutation }
}

export { useSearch }
