import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import { MuiTelInputInfo } from 'mui-tel-input'
import { ICandidateAttributes, INestedArrayAttributes, INoteAttributes } from '../../../../../types'
import { ControlledTelInput } from '../../../../input/TelInput'
import SuggestiontextField from '../../../../input/text/SuggestionTextField'
import ClassicRadioInput from '../../../../input/radio/ClassicRadioInput'
import { REF } from '../../../../../enums'
import {
  Button, Stack, Typography,
  IconButton
} from '@mui/material'
import DateInput from '../../../../input/DateInput'
import { CountrySelect } from '../../../../input/select/CountrySelect'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from '../../../../input/select/Select'
import { useLazyGetDuplicateCandidatesQuery } from '../../../../../services/crmApi'
import { debounce } from '@mui/material/utils'

const FormSection: React.FC<{}> = ({ }) => {

  const [trigger, { data: duplicates }] = useLazyGetDuplicateCandidatesQuery()
  const debouncedTrigger = debounce(trigger, 1000)



  const form = useFormContext<ICandidateAttributes>()
  const { fields: notesFields, append: notesAppend, remove: notesRemove } = useFieldArray({
    control: form.control,
    name: 'notes_attributes',
    keyName: 'form_key',
  })


  // Trigger duplicate search on user typing of these fields:
  // gender
  // phone
  // name
  // surname
  // birthdate
  React.useEffect(() => {
    debouncedTrigger({
      id: form.watch('id'),
      name: form.watch('name'),
      surname: form.watch('surname'),
      gender: form.watch('gender'),
      phone: form.watch('phone'),
      birthdate: form.watch('birthdate'),
    })
  }, [
    form.watch('name'),
    form.watch('surname'),
    form.watch('gender'),
    form.watch('phone'),
    form.watch('birthdate'),
  ])

  React.useEffect(() => {
    if (duplicates?.result_profile_url) {
      //@ts-ignore-next-line
      warn(`<a href="${duplicates.result_profile_url}" target="_blank" rel="noopener noreferrer" class="clickablewarn"><i class="fa fa-exclamation-circle"></i> Duplicato trovato. Clicca per verificare.</a>`);
    }
  }, [duplicates])



  const renderNoteFields = (note: (INoteAttributes & INestedArrayAttributes), index: number) => {

    if (form.watch(`notes_attributes.${index}._destroy`)) return null

    return (
      <Grid xs={12} key={note.form_key}>
        <Stack direction="row">
          <Controller
            name={`notes_attributes.${index}.text`}
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Note"
                fullWidth
                required
                error={!!form.formState.errors?.notes_attributes?.[index]?.text}
                helperText={form.formState.errors?.notes_attributes?.[index]?.text?.message}
                sx={{ flexGrow: 1 }}
                multiline
                minRows={2}
              />
            )}
          />
          <IconButton
            color="error"
            onClick={() => form.setValue(`notes_attributes.${index}._destroy`, true)}
          >
            <ClearOutlinedIcon />
          </IconButton>
        </Stack>
      </Grid>
    )
  }





  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Anagrafica</Typography>
      </Grid>
      {/* Name */}
      <Grid xs={12} md={6}>
        <Controller
          name="name"
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome"
              fullWidth
              required
              error={!!form.formState.errors?.name}
              helperText={form.formState.errors?.name?.message}
            />
          )}
        />
      </Grid>
      {/* Surname */}
      <Grid xs={12} md={6}>
        <Controller
          name="surname"
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Cognome"
              fullWidth
              required
              error={!!form.formState.errors?.surname}
              helperText={form.formState.errors?.surname?.message}
            />
          )}
        />
      </Grid>
      {/* Nickname */}
      <Grid xs={12} md={6}>
        <Controller
          name="nickname"
          control={form.control}
          render={({ field }) => (
            <SuggestiontextField
              {...field}
              label="Soprannome"
              fullWidth
              options={[
                ...(form.watch('name') || '').split(' '),
                ...(form.watch('surname') || '').split(' ')
              ].filter((val) => val)}
            />
          )}
        />
      </Grid>
      {/* birthdate */}
      <Grid xs={12} md={6}>
        {/* <Typography variant="subtitle1">
          Data di nascita: {form.watch('birthdate').toString()}
        </Typography> */}
        <Controller
          name="birthdate"
          control={form.control}
          render={({ field }) => (
            <DateInput
              label="Data di nascita"
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  error: !!form.formState.errors?.birthdate,
                  helperText: form.formState.errors?.birthdate?.message
                }
              }}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Gender */}
      <Grid xs={12}>
        <Controller
          name="gender"
          control={form.control}
          render={({ field }) => (
            <ClassicRadioInput
              {...field}
              label="Sesso"
              options={REF.gender}
              required
              error={!!form.formState.errors?.gender}
              helperText={form.formState.errors?.gender?.message}
            />
          )}
        />
      </Grid>
      {/* Email */}
      <Grid xs={12} md={6}>
        <Controller
          name="mail"
          control={form.control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label="Email"
              helperText={
                fieldState.error && 'Inserisci un indirizzo e-mail valido'
              }
              error={!!fieldState.error}
              fullWidth
              type="email"
            />
          )}
        />
      </Grid>
      {/* Phone */}
      <Grid xs={12} md={6}>
        <ControlledTelInput
          controllerProps={{
            name: 'full_phone',
            control: form.control
          }}
          telInputRenderProps={({ field }) => {
            return {
              label: 'Cellulare',
              // Override onchange
              onChange: (value: string, info: MuiTelInputInfo) => {
                form.setValue('phone_prefix', info.countryCallingCode)
                form.setValue('phone', info.nationalNumber)
                field.onChange(value)
              }
            }
          }}
        />
      </Grid>
      {/* Country */}
      <Grid xs={12} md={4}>
        <Controller
          name='country'
          control={form.control}
          render={({ field }) => (
            <CountrySelect
              label="Paese"
              fullWidth
              {...field}
            />
          )}
        />
        {/* Button to copy the same value to citizenship */}
        <Button
          variant="text"
          size="small"
          sx={{ cursor: 'pointer' }}
          onClick={() => form.setValue('citizenship', form.watch('country'))}
          startIcon={<ContentCopyIcon />}
        >
          Copia in cittadinanza
        </Button>


      </Grid>
      {/* Citizenship */}
      <Grid xs={12} md={4}>
        <Controller
          name='citizenship'
          control={form.control}
          render={({ field }) => (
            <CountrySelect
              label="Cittadinanza"
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      {/* Arrival date */}
      <Grid xs={12} md={4}>
        <Controller
          name={`it_arrival_year`}
          control={form.control}
          render={({ field }) => (
            <Select
              {...field}
              label="Anno di arrivo in Italia"
              fullWidth
              options={
                // 70 years ago to this year
                Array.from(Array(70).keys()).map((val) => ({
                  label: (new Date().getFullYear() - val).toString(),
                  value: (new Date().getFullYear() - val).toString()
                }))
              }
              error={!!form.formState.errors?.it_arrival_year}
            />
          )}
        />
      </Grid>


      {/* note pubbliche */}
      <Grid xs={12}>
        <Controller
          name={`notes_public`}
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              minRows={3}
              label="Descrizione di Accademia"
              placeholder="Scrivi qui una descrizione del candidato da includere nel CV pubblico."
            />
          )}
        />
      </Grid>

      {/* Notes */}
      <Grid xs={12}>
        <Typography variant="h6">Note</Typography>
      </Grid>
      <Grid xs={12}>
        {notesFields.length === 0 &&
          <Stack direction="column" alignItems={"center"} spacing={1} sx={{
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: '5px',
            p: 2
          }}>
            <SubjectIcon sx={{ fontSize: 40 }} />
            <Typography variant="caption">Nessuna nota</Typography>
          </Stack>
        }
      </Grid>

      {notesFields.map(renderNoteFields)}
      <Grid xs={12}>
        <Button
          variant="outlined"
          onClick={() => notesAppend({ id: '', text: '', _destroy: false })}
          fullWidth
          startIcon={<PlaylistAddOutlinedIcon />}
        >
          Aggiungi nota
        </Button>
      </Grid>

    </Grid >
  )
}
export default FormSection
