import React from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Typography from '@mui/material/Typography'

interface IProps {
  value: string
  onChange: (value: string) => void
}

const NumericCounter: React.FC<IProps> = ({ value, onChange }) => {
  const handleIncrement = () => {
    onChange((Number(value) + 1).toString())
  }

  const handleDecrement = () => {
    onChange((Number(value) - 1).toString())
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <IconButton
        onClick={handleDecrement}
        disabled={Number(value) === 0}
        color="primary"
        size="large"
      >
        <RemoveCircleIcon />
      </IconButton>
      <Typography variant="body1">{value || "?"}</Typography>
      <IconButton onClick={handleIncrement} color="primary" size="large">
        <AddCircleIcon />
      </IconButton>
    </Stack>
  )
}

export default NumericCounter
