import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButton, Button, DialogActions, DialogContentText } from '@mui/material'
import IosShareIcon from '@mui/icons-material/IosShare';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  link: string
}

export default function ShareLinkDialog(props: Props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{ flexGrow: 1 }}
        startIcon={<IosShareIcon />}
        onClick={handleClickOpen}
        color="warning"
      >
        Condividi link cliente
      </Button>
      {/* Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Condividi link cliente</DialogTitle>

        <DialogContent>
          <DialogContentText gutterBottom>
            Condividi questo link con il cliente per permettergli di inserire i propri dati autonomamente.
            La procedura di inserimento dati avrà alcuni campi già precompilati a partire da quelli inseriti nella ricerca preliminare.
            Una volta completata la procedura, il cliente comparirà nella tab "Da approvare" e la nota rimarrà abbinata al cliente.
          </DialogContentText>
          <TextField
            fullWidth
            label="Link"
            value={props.link}
            variant="outlined"
            sx={{ mt: 2 }}
            disabled
            onFocus={(event) => event.target.select()}
            InputProps={{
              endAdornment: <CopyToClipboard text={props.link} />
            }}
          />
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="contained" onClick={handleClose}>Chiudi</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const CopyToClipboard = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  useEffect(() => {
    if (isCopied) {
      // Show toast
      //@ts-ignore-next-line
      notice("Copiato negli appunti")
    }
  }, [isCopied])


  const handleClick = () => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      // note: this will happen on Safari unless page is served over HTTPS
      alert("Impossibile copiare negli appunti. Seleziona e copia manualmente il testo.")
      return;
    }

    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  return (
    <IconButton
      onClick={handleClick}
      disabled={isCopied}
    >
      {isCopied ? <CheckIcon /> : <ContentCopyIcon />}
    </IconButton>
  );
}