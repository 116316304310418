import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

export default function LoadingWrapper({
  fakeDelayMs = 600,
  loading: isRealLoading,
  onClick = () => { },
  askConfirm = false,
  confirmTitle = "Conferma azione",
  confirmMsg = "Sei sicuro di voler eliminare l'elemento?",
  children,
  ...other
}) {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleBtnClick = () => {
    if (askConfirm) {
      setOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    setIsFakeLoading(true);
    setTimeout(() => {
      onClick();
      setIsFakeLoading(false);
    }, fakeDelayMs);
  };

  const handleAbort = () => {
    setOpen(false);
  };

  return (
    <>
      {children({
        loading: isRealLoading || isFakeLoading,
        onClick: handleBtnClick,
        ...other,
      })}
      <Dialog
        open={open}
        onClose={handleAbort}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort}>Annulla</Button>
          <Button onClick={handleConfirm} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const IconBtn = ({ children, ...props }) => (
  <LoadingWrapper {...props}>
    {({ loading, onClick, ...other }) => (
      <IconButton disabled={loading} onClick={onClick} {...other}>
        {children}
      </IconButton>
    )}
  </LoadingWrapper>)

const Btn = ({ children, ...props }) => (
  <LoadingWrapper {...props}>
    {({ loading, onClick, ...other }) => (
      <LoadingButton loading={loading} onClick={onClick} {...other}>
        {children}
      </LoadingButton>
    )}
  </LoadingWrapper>
)

export { Btn, IconBtn }