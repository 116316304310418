import React from 'react'
import { BOOL_OPTIONS } from '../../../../enums'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Personalità"
    bgColor="#FFAE42"
    colSize={3}
    fields={[
      {
        label: '💁 Autonoma',
        name: 'autonomy',
        options: REF.autonomy
      },
      {
        label: '☝️ Rigida',
        name: 'rigidity',
        options: REF.rigidity
      },
      {
        label: '💪 Energica',
        name: 'energy',
        options: REF.energy
      },
      {
        label: '🙂 Carattere',
        name: 'temper',
        options: REF.temper
      },
      {
        label: '☀️ Solare',
        name: 'solarity',
        options: REF.solarity
      },
      {
        label: '🗣️ Verbosità',
        name: 'verbosity',
        options: REF.verbosity
      },
      {
        label: '😶 Paziente',
        name: 'is_patient',
        options: BOOL_OPTIONS
      },
      {
        label: '🙄 Permalosa',
        name: 'is_touchy',
        options: BOOL_OPTIONS
      },
      {
        label: '🪄 Ordine di sé',
        name: 'self_care',
        options: REF.self_care
      },
      {
        label: '🤫 Discreta e riservata',
        name: 'is_reserved',
        options: BOOL_OPTIONS
      },
      {
        label: '⏰ Puntuale',
        name: 'punctuality',
        options: REF.punctuality
      },
      {
        label: '🙋‍♀️ Con capacità gestionali',
        name: 'has_management_skills',
        options: BOOL_OPTIONS
      },
      {
        label: '📢 Sindacalizzata',
        name: 'unionized',
        options: BOOL_OPTIONS
      },
      {
        label: '🚗 Abilità di guida',
        name: 'driving_lvl',
        options: REF.driving_lvl
      },
      {
        label: '💕 Dolce e affettuosa',
        name: 'sweetness',
        options: REF.sweetness
      }
    ]}
  />
)

export default FormSection
