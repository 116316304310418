import React, { useState, useEffect } from 'react'
import { Container, Draggable } from 'react-smooth-dnd'
import List from '@mui/material/List'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconBtn } from '../../utils/Btn'
import {
  useSwapCvsOrderMutation,
  useGetCvsQuery,
  useDestroyCvMutation
} from '../../services/crmApi'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

const SortableList = (props) => {
  const { data, isFetching, isError } = useGetCvsQuery(props.candidateId)
  const [swapCvsOrder, { isFetching: isSwapFetching, isSuccess: isSwapSuccess, isError: isSwapError }] = useSwapCvsOrderMutation()
  const [destroyCv, { isFetching: isDestroyFetching, isSuccess: isDestroySuccess, isError: isDestroyError }] = useDestroyCvMutation()

  const [showDialog, setShowDialog] = useState(false);
  const [forceProgress, setForceProgress] = useState(false);

  const onDrop = ({ removedIndex, addedIndex }) => {
    setForceProgress(true);
    swapCvsOrder({ cvId: data[removedIndex].id, otherCvId: data[addedIndex].id })
  };

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    // When forceProgress is set to true, make sure to reset it after N second.
    // force progress is used to display the progress spinner for at least N second
    // even if the request is very fast.
    if (forceProgress) {
      setTimeout(() => {
        setForceProgress(false);
      }, 500);
    }
  }, [forceProgress]);

  useEffect(() => {
    // Display success messages on destroy
    if (isDestroySuccess) {
      notice('Allegato eliminato con successo');
    }
  }, [isDestroySuccess]);

  useEffect(() => {
    // Display error messages on swap, destroy or fetch
    if (isError || isSwapError || isDestroyError) {
      alert('Si è verificato un errore');
    }
  }, [isError, isSwapError, isDestroyError]);



  const displayProgress = isFetching || isSwapFetching || forceProgress;
  const stackHeight = (data && data.length) ? data.length * 88 : 88;

  return (
    <>
      <a href="#" style={{ color: 'white' }} onClick={handleOpenDialog}>
        <span className="fa fa-paperclip" /> Allegati: {data ? data.length : '?'}
      </a>
      <Dialog open={showDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Allegati</DialogTitle>
        <DialogContent>
          {displayProgress ?
            <Stack alignItems="center" justifyContent="center" sx={{ minHeight: stackHeight }}>
              <CircularProgress />
            </Stack>
            : <List>
              <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {data.length ? (
                  <>
                    {data.map((attachment) => (
                      <Draggable key={attachment.id}>
                        <ListItem>
                          <ListItemAvatar className="drag-handle">
                            <DragHandleIcon />
                          </ListItemAvatar>
                          <ListItemButton onClick={() => window.location.href = `/cvs/${attachment.id}`}>
                            <ListItemText primary={attachment.created_at} secondary={attachment.created_at} />
                          </ListItemButton>
                          <ListItemSecondaryAction>
                            <IconBtn edge="end" aria-label="more" askConfirm fakeDelayMs={0} onClick={() => destroyCv(attachment.id)} loading={isDestroyFetching}>
                              <DeleteOutlineOutlinedIcon />
                            </IconBtn>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Draggable>
                    ))}
                  </>

                ) : (
                  <ListItem>
                    <ListItemText primary="Nessun allegato presente" />
                  </ListItem>
                )}
              </Container>
            </List>}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.href = props.newPdfAttachmentPath} startIcon={<UploadFileOutlinedIcon />} variant="contained">Allega PDF</Button>
          <Button onClick={() => window.location.href = props.newAttachmentPath} startIcon={<AddPhotoAlternateOutlinedIcon />} variant="contained">Allega immagini</Button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SortableList {...props}></SortableList>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
