import React, { createContext, useContext } from 'react'

const FiltersContext = createContext(undefined)

function FiltersProvider({ children, ...props }) {
  //TODO: this is kind of deprecated, we should use the useFormContext hook instead
  // now it is only used in the Actions component to pass the isLoading prop
  return (
    <FiltersContext.Provider value={{ ...props }}>
      {children}
    </FiltersContext.Provider>
  )
}

function useFiltersContext() {
  const context = useContext(FiltersContext)
  if (context === undefined) {
    throw new Error('Must be used inside a FiltersContext Provider')
  }
  return context
}

export { FiltersProvider, useFiltersContext }
