import React from 'react'
import SelectMulti, { SelectMultiProps } from './SelectMulti'

interface FetchSelectMultiProps extends Omit<SelectMultiProps, 'options'> {
  // Options accepts hooks such as useGetAvailabilitiesQuery
  // TODO: improve this type
  options: any
}

const FetchSelectMulti: React.FC<FetchSelectMultiProps> = React.forwardRef<
  HTMLInputElement,
  FetchSelectMultiProps
>((props, ref) => {
  const { data } = props.options()

  return data ? (
    // Options loaded, display real input
    <SelectMulti ref={ref} {...props} options={data} />
  ) : (
    // Options missing or loading. Display fake disabled field
    <SelectMulti
      {...props}
      name="fake_loading_field"
      options={[]}
      disabled
    />
  )
})

export default FetchSelectMulti
