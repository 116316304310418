import React, { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useCreateAttachmentMutation } from '../../services/crmApi'
import {
  Alert,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from '@mui/material'
import {
  Delete,
  AddCircle,
  ArrowUpward,
  ArrowDownward,
  CheckCircle
} from '@mui/icons-material'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Container } from '@mui/system'
import useDirectUpload from '../../utils/useDirectUpload'
import joiSchema from './joiSchema'
import LoadingButton from '@mui/lab/LoadingButton'
import * as Sentry from '@sentry/react'

interface ImageFormProps {
  candidateId: number
}

interface ImageFormData {
  images: { id: string; file: File }[]
}

const FileUpload: React.FC<ImageFormProps> = ({ candidateId }) => {
  const { control, handleSubmit, setValue, getValues, getFieldState } =
    useForm<ImageFormData>({
      defaultValues: {
        images: [
          {
            id: Date.now().toString(),
            file: null
          }
        ]
      },
      resolver: joiSchema
    })
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'images'
  })
  const [
    submitAttachment,
    {
      isLoading: isAttachLoading,
      isSuccess: isAttachSuccess,
      isError: isAttachError,
      data: attachData
    }
  ] = useCreateAttachmentMutation()

  const [
    triggerDirectUpload,
    {
      isAllSuccess: isUploadSuccess,
      isAnyError: isUploadError,
      isAnyUploading: isUploading,
      uploadStates
    }
  ] = useDirectUpload()

  const handleDirectUpload = async (data: ImageFormData) => {
    const files = data.images.map((image) => image.file)
    triggerDirectUpload(files)
  }

  // On upload success, submit the signed ids to the server
  useEffect(() => {
    if (isUploadSuccess) {
      // Check that uploads contains all the blobs
      if (uploadStates.some((u) => !u.blob)) {
        // This should never happen. But maybe it's better to be safe than sorry
        alert(
          'Alcuni file non sono stati caricati. Controlla la connessione e riprova.'
        )
        Sentry.captureMessage('File upload error', {
          extra: {
            candidateId,
            images: getValues('images'),
            uploadStates,
            isUploadSuccess
          }
        })
        return
      }
      let blob_signed_ids = uploadStates.map((u) => u.blob.signed_id)
      submitAttachment({
        candidateId,
        blob_signed_ids
      })
    }
  }, [isUploadSuccess, uploadStates, submitAttachment, candidateId])

  // On submit success, redirect according to server response
  useEffect(() => {
    if (isAttachSuccess) {
      //@ts-ignore-next-line
      notice('CV creato con successo')
      window.location.href = attachData.redirect
    }
  }, [isAttachSuccess, attachData])

  // On upload or submit error, show an alert
  useEffect(() => {
    if (isUploadError) {
      alert(
        'Errore durante il caricamento dei file. Controlla di essere connesso.'
      )
    } else if (isAttachError) {
      alert("Errore durante l'invio dei file. Controlla di essere connesso.")
    }
  }, [isUploadError, isAttachError])

  const handleFileChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      setValue(`images.${index}.file`, file)
    }
  }

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      move(index, index - 1)
    }
  }

  const handleMoveDown = (index: number) => {
    if (index < fields.length - 1) {
      move(index, index + 1)
    }
  }

  const appendNewEmptyField = () =>
    append({ id: Date.now().toString(), file: null })

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" component="h1" gutterBottom>
        Allega immagini
      </Typography>
      <form onSubmit={handleSubmit(handleDirectUpload)}>
        <Stack spacing={2}>
          {fields.map((field, index) => (
            <Paper
              elevation={2}
              key={field.id}
              style={{
                padding: 10,
                backgroundColor: '#f8f8f8'
              }}
            >
              <Stack direction="row" alignItems="center">
                <Stack>
                  <IconButton
                    onClick={() => handleMoveUp(index)}
                    disabled={index === 0}
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    onClick={() => handleMoveDown(index)}
                    disabled={index === fields.length - 1}
                  >
                    <ArrowDownward />
                  </IconButton>
                </Stack>
                <input
                  style={{ flexGrow: 1 }}
                  type="file"
                  accept="image/jpg,image/jpeg,image/png"
                  onChange={(event) =>
                    handleFileChange(
                      index,
                      event as React.ChangeEvent<HTMLInputElement>
                    )
                  }
                />
                <IconButton onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Stack>
              {getFieldState(`images.${index}.file`).error && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {getFieldState(`images.${index}.file`).error?.message}
                </Alert>
              )}
              {uploadStates && uploadStates[index].error && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {uploadStates[index].error}
                </Alert>
              )}
              <LinearProgress
                variant="determinate"
                value={(uploadStates && uploadStates[index].progress) || 0}
              />
            </Paper>
          ))}
          <Grid item xs={12}>
            <Button
              startIcon={<AddCircle />}
              onClick={appendNewEmptyField}
              disabled={isUploading || isAttachLoading}
              fullWidth
            >
              Aggiungi immagine
            </Button>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              fullWidth
              loading={isUploading || isAttachLoading}
              variant="contained"
              startIcon={<CheckCircle />}
            >
              Salva
            </LoadingButton>
          </Grid>
        </Stack>
      </form>
    </Container>
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <FileUpload {...props}></FileUpload>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
