import React, { useEffect, useContext } from 'react'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import CarouselIconButton from './CarouselIconButton'
import { ModelId } from '../../types'
import {
  useCreateFavouriteMutation,
  useDestroyFavouriteMutation
} from '../../services/crmApi'
import { useSearch } from '../searches/SearchContext'
import Tooltip from '@mui/material/Tooltip'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

interface CarouselToolbarProps {
  candidateId: ModelId
  isFavorite: boolean
  onClose: (event: any) => void
}

/**
 * Top-right carousel toolbar
 */
export function CarouselToolbar({
  candidateId,
  isFavorite,
  onClose
}: CarouselToolbarProps): JSX.Element {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ position: 'absolute', top: 5, right: 5 }}
    >
      {/* Favorite button */}
      <ToggleFavoriteButton candidateId={candidateId} isFavorite={isFavorite} />

      {/* Close button */}
      <CarouselIconButton onClick={onClose} size="large">
        <CloseIcon />
      </CarouselIconButton>
    </Stack>
  )
}

interface Props {
  candidateId: ModelId
  isFavorite: boolean
}

/**
 * Carousel favorite toggle icon button
 */
function ToggleFavoriteButton({ candidateId, isFavorite }: Props): JSX.Element {
  const { searchId } = useSearch()

  const [
    mutateCreate,
    {
      isLoading: isLoadingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate
    }
  ] = useCreateFavouriteMutation()

  const [
    mutateDestroy,
    {
      isLoading: isLoadingDestroy,
      isSuccess: isSuccessDestroy,
      isError: isErrorDestroy
    }
  ] = useDestroyFavouriteMutation()

  const mutate = isFavorite ? mutateDestroy : mutateCreate

  useEffect(() => {
    if (isSuccessCreate) {
      // @ts-ignore
      notice('Candidato aggiunto ai preferiti')
    } else if (isErrorCreate) {
      alert(
        'Errore: impossibile aggiungere candidato ai preferiti. Controlla la connessione.'
      )
    } else if (isSuccessDestroy) {
      // @ts-ignore
      notice('Candidato rimosso dai preferiti')
    } else if (isErrorDestroy) {
      alert(
        'Errore: impossibile rimuovere candidato dai preferiti. Controlla la connessione.'
      )
    }
  }, [isSuccessCreate, isErrorCreate, isSuccessDestroy, isErrorDestroy])

  const isLoading = isLoadingCreate || isLoadingDestroy

  return (
    <Tooltip title="Aggiungi ai preferiti">
      <CarouselIconButton
        onClick={() => mutate({ searchId, candidateId })}
        size="large"
        disabled={isLoading}
      >
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </CarouselIconButton>
    </Tooltip>
  )
}
