import React from 'react'
import { BOOL_OPTIONS } from '../../../../enums'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Assistito"
    bgColor="#B6B900"
    colSize={3}
    fields={[
      {
        name: 'assist_whom',
        label: '🧑‍🤝‍🧑 Chi ha assistito',
        options: REF.assist_whom,
        variant: 'checkbox'
      },
      {
        name: 'assist_selfsufficient',
        label: '♿ Autosufficiente',
        options: BOOL_OPTIONS
      },
      {
        name: 'assist_difficulty',
        label: '😃 Carattere',
        options: REF.assist_difficulty
      }
    ]}
  />
)

export default FormSection
