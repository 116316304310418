import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import {
  ICandidateAttributes
} from '../../../../../types'
import FetchSelectMulti from '../../../../input/select/FetchSelectMulti'
import { useGetCoursesQuery, useGetDegreesQuery } from '../../../../../services/crmApi'
import { TextField, Typography } from '@mui/material';

const EducationSection: React.FC<{}> = ({ }) => {

  const form = useFormContext<ICandidateAttributes>()

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Istruzione</Typography>
      </Grid>

      {/* Courses */}
      <Grid xs={12}>
        <Controller
          name={`course_ids`}
          control={form.control}
          render={({ field }) => (
            <FetchSelectMulti
              {...field}
              label="Corsi"
              fullWidth
              options={useGetCoursesQuery}
              error={!!form.formState.errors?.course_ids}
              // helperText={form.formState.errors?.course_ids?.message}
              size="small"

            />
          )}
        />
      </Grid>

      {/* Degrees */}
      <Grid xs={12}>
        <Controller
          name={`degree_ids`}
          control={form.control}
          render={({ field }) => (
            <FetchSelectMulti
              {...field}
              label="Corsi"
              fullWidth
              options={useGetDegreesQuery}
              error={!!form.formState.errors?.degree_ids}
              // helperText={form.formState.errors?.degree_ids?.message}
              size="small"

            />
          )}
        />
      </Grid>


      {/* Other certifications */}
      <Grid xs={12}>
        <Controller
          name={`certifications_other`}
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Altre certificazioni"
              fullWidth
              error={!!form.formState.errors?.certifications_other}
              // helperText={form.formState.errors?.certifications_other?.message}
              size="medium"
              multiline
            />
          )}
        />
      </Grid>

    </Grid >
  )
}
export default EducationSection
