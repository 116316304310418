import React, { useState, useEffect } from 'react'
import List from '@mui/material/List'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconBtn } from '../../utils/Btn'
import {
  useContractAttachmentsQuery,
  useDestroyAttachmentMutation,
  useAppendAttachmentMutation
} from '../../services/crmApi'

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LoadingButton from '@mui/lab/LoadingButton'

const ContractAttachmentList = (props) => {
  const { data: attachments, isFetching: isAttachmentsFetching, isError: isAttachmentsError } = useContractAttachmentsQuery(props.contractId)
  const [destroyAttachment, { isLoading: isDestroyLoading, isSuccess: isDestroySuccess, isError: isDestroyError }] = useDestroyAttachmentMutation()
  const [appendAttachment, { isLoading: isAppendLoading, isSuccess: isAppendSuccess, isError: isAppendError }] = useAppendAttachmentMutation()

  const [showDialog, setShowDialog] = useState(false);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };


  useEffect(() => {
    // Display success messages on destroy
    if (isDestroySuccess) {
      //@ts-ignore-next-line
      notice('Allegato eliminato con successo');
    }
  }, [isDestroySuccess]);

  useEffect(() => {
    // Display error messages on destroy or fetch
    if (isAttachmentsError || isDestroyError) {
      alert('Si è verificato un errore');
    }
  }, [isAttachmentsError, isDestroyError]);

  const handleAppendAttachment = (signed: boolean) => {
    appendAttachment({ contractId: props.contractId, signed: signed })
  }


  const stackHeight = (attachments && attachments.length) ? attachments.length * 88 : 88;

  return (
    <>
      <a href="#" style={{ color: 'white' }} onClick={handleOpenDialog}>
        <span className="fa fa-paperclip" /> Allegati: {attachments ? attachments.length : '?'}
      </a>
      <Dialog open={showDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Allegati</DialogTitle>
        <DialogContent>
          {isAttachmentsFetching || isAppendLoading ?
            <Stack alignItems="center" justifyContent="center" sx={{ minHeight: stackHeight }}>
              <CircularProgress />
            </Stack>
            : <List>
              {attachments.length ? (
                <>
                  {attachments.map((attachment) => (
                    <ListItem key={attachment.id} >
                      <ListItemButton onClick={() => window.location.href = attachment.url}>
                        <ListItemText primary={attachment.filename} secondary={attachment.filename} />
                      </ListItemButton>
                      <ListItemSecondaryAction>
                        <IconBtn edge="end" askConfirm fakeDelayMs={0} onClick={() => destroyAttachment(attachment.blob_id)} loading={isDestroyLoading}>
                          <DeleteOutlineOutlinedIcon />
                        </IconBtn>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </>

              ) : (
                <ListItem>
                  <ListItemText primary="Nessun allegato presente" />
                </ListItem>
              )}
            </List>}

        </DialogContent>
        <DialogActions>
          <LoadingButton disabled={isAppendLoading} onClick={() => handleAppendAttachment(false)} startIcon={<NoteAddIcon />} variant="contained">Sigilla PDF</LoadingButton>
          <LoadingButton disabled={isAppendLoading} onClick={() => handleAppendAttachment(true)} startIcon={<NoteAddIcon />} variant="contained">Sigilla PDF (firmato)</LoadingButton>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ContractAttachmentList {...props}></ContractAttachmentList>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
