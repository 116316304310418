import React from 'react'
import Button from '@mui/material/Button'
import {
  useGetSearchQuery
} from '../../../services/crmApi'
import { useSearchParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { ModelId } from '../../../types'

interface Props {
  candidateId: ModelId
}

export default function EmployDialog({ candidateId }: Props) {
  // If ?search=id in URL, get contractId of this search and use it
  // for employment form
  const [searchParams] = useSearchParams()
  const { data: search } = useGetSearchQuery(searchParams.get('search'), {
    skip: !searchParams.get('search')
  })

  const contractId = search?.contract_id

  return (
    <>
      <Tooltip
        title={
          contractId ? '' : 'Collega un contratto per abilitare il collocamento'
        }
        placement="top"
        style={{ flexGrow: 1 }}
      >
        <span>
          <Button
            fullWidth
            variant="outlined"
            disabled={!contractId}
            component="a"
            // Open candiate edit in a new tab
            href={`/candidates/${candidateId}/edit_advanced?add_contract_empl=${contractId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Collocamento
          </Button>
        </span>
      </Tooltip>
    </>
  )
}
