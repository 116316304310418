import Grid from '@mui/system/Unstable_Grid'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BOOL_OPTIONS } from '../../../../enums'
import { ChipRadioInput } from '../../../input/chip/ChipInput'
import { RapidFormGrid } from '../../../input/RapidForm/RapidFormGrid'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { RapidFormTile } from '../../../input/RapidForm/RapidFormTile'
import RapidNumberField from '../../../input/RapidNumberField'

const FormSection = () => {
  const { control } = useFormContext()

  return (
    <RapidFormSection title="Mansione" bgColor="#7F96FF">
      <RapidFormGrid>
        <Grid xs={3}>
          <RapidFormTile
            label="👥 Con colleghi"
            description="La risorsa ha lavorato con colleghi?"
          >
            <Controller
              name="had_colleagues"
              control={control}
              render={({ field }) => (
                <ChipRadioInput {...field} options={BOOL_OPTIONS} />
              )}
            />
          </RapidFormTile>
        </Grid>
        <Grid xs={3}>
          <RapidFormTile label="📅 Quanti anni ha lavorato">
            <Controller
              name="work_years"
              control={control}
              render={({ field }) => <RapidNumberField {...field} />}
            />
          </RapidFormTile>
        </Grid>
      </RapidFormGrid>
    </RapidFormSection>
  )
}

export default FormSection
