import React from 'react'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Tata"
    bgColor="#FF7F50"
    colSize={3}
    fields={[
      {
        name: 'playfulness',
        label: '🪁 Giocosa',
        options: REF.playfulness
      },
      {
        name: 'authority',
        label: '☝️ Di Polso',
        options: REF.authority
      },
      {
        name: 'obedience',
        label: '💬 Segue le istruzioni',
        options: REF.obedience
      },
      {
        name: 'nanny_predisposition',
        label: '🧸 Predisposizione',
        options: REF.nanny_predisposition
      }
    ]}
  />
)

export default FormSection
