import React from 'react'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Stack } from '@mui/system'
import {
  ICandidateAttributes,
  INestedArrayAttributes,
  ISpokenLanguageAttributes
} from '../../../../../types'
import {
  Card,
  CardContent,
  IconButton
} from '@mui/material'

import Select from '../../../../input/select/Select'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import { SPOKEN_LANGUAGES_LEVELS } from '../../../../searches/form/sections/Language';
import FetchSelect from '../../../../input/select/FetchSelect'
import { useGetLangsQuery } from '../../../../../services/crmApi'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
const SpokenLanguagesSection: React.FC<{}> = ({ }) => {

  const form = useFormContext<ICandidateAttributes>()
  const { fields, append } = useFieldArray({
    control: form.control,
    name: 'spoken_languages_attributes',
    keyName: 'form_key' // Rename key to avoid conflicts with `id` field
  })

  const handleAddSpokenLanguage = () => {
    append({ id: '', language_id: '', level: '', _destroy: false })
  }

  const LanguageFields = (lang: (ISpokenLanguageAttributes & INestedArrayAttributes), index: number) => {

    if (form.watch(`spoken_languages_attributes.${index}._destroy`)) return null

    return (
      <Grid xs={12} key={lang.form_key}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems="center" alignContent="center">
          {/* Language */}
          <Controller
            name={`spoken_languages_attributes.${index}.language_id`}
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <FetchSelect
                {...field}
                label="Lingua"
                fullWidth
                required
                options={useGetLangsQuery}
                error={!!form.formState.errors?.spoken_languages_attributes?.[index]?.language_id}
                helperText={!!form.formState.errors?.spoken_languages_attributes?.[index]?.language_id && "Campo obbligatorio"}
                size="small"

              />
            )}
          />

          {/* Level */}
          <Controller
            name={`spoken_languages_attributes.${index}.level`}
            control={form.control}
            render={({ field }) => (
              <Select
                {...field}
                label="Livello"
                fullWidth
                options={SPOKEN_LANGUAGES_LEVELS}
                size="small"
              />
            )}
          />
          {/* Delete item button */}
          <IconButton
            color="error"
            onClick={() => form.setValue(`spoken_languages_attributes.${index}._destroy`, true)}
          >
            <ClearOutlinedIcon />
          </IconButton>
        </Stack>
      </Grid >

    )
  }


  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Lingue</Typography>
      </Grid>
      {fields.length === 0 &&
        <Grid xs={12}>
          <Stack direction="column" alignItems={"center"} spacing={1} sx={{
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: '5px',
            p: 2
          }}>
            <TranslateOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography variant="caption">Nessuna lingua inserita</Typography>
          </Stack>
        </Grid>}
      {fields.map(LanguageFields)}
      <Grid xs={12}>
        <Button
          variant="outlined"
          onClick={handleAddSpokenLanguage}
          fullWidth
          startIcon={<PlaylistAddOutlinedIcon />}
        >
          Aggiungi lingua
        </Button>
      </Grid>


    </Grid >
  )
}
export default SpokenLanguagesSection
