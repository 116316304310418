import React from 'react'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Badante"
    bgColor="#AA61E4"
    colSize={3}
    fields={[
      {
        name: 'companionship',
        label: '🧑‍🤝‍🧑 Di compagnia',
        options: REF.companionship
      },
      {
        name: 'nursing_skills',
        label: '👩‍⚕️ Competenze para-inf',
        options: REF.nursing_skills
      },
      {
        name: 'scolarship',
        label: '📚 Di cultura',
        options: REF.scolarship
      }
    ]}
  />
)

export default FormSection
