import React, { useEffect } from 'react'
import { FiltersProvider } from './useFiltersContext'
import Anagraphic from './sections/Anagraphic'
import Language from './sections/Language'
import Job from './sections/Job'
import Education from './sections/Education'
import Location from './sections/Location'
import {
  PersonalityScores,
  ColfScores,
  CaretakerScores,
  NannyScores
} from './sections/Scores'
import Settings from './sections/Settings'
import Characteristic from './sections/Characteristic'
import Actions from './sections/Actions'
import { useForm, FormProvider } from 'react-hook-form'
import { useCreateOrUpdateSearchMutation } from '../../../services/crmApi'

export interface FiltersFormProps {
  defaultValues: object
  onMutationSuccess?: (data: object) => void
  children: React.ReactNode
}

function FiltersForm({
  defaultValues,
  onMutationSuccess,
  children
}: FiltersFormProps) {
  const form = useForm({ defaultValues })
  const { handleSubmit } = form

  const [createOrUpdate, { data, isSuccess, isLoading, isError }] =
    useCreateOrUpdateSearchMutation()

  // Show a success message when the mutation is successful
  useEffect(() => {
    // Handle success
    if (isSuccess) {
      onMutationSuccess && onMutationSuccess(data)
      return
    }
    // Handle error
    if (isError) {
      alert("Errore durante l'aggiornamento dei filtri")
      return
    }
  }, [isSuccess, isError, onMutationSuccess])

  return (
    <FiltersProvider value={{ isLoading }}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(createOrUpdate)}>{children}</form>
      </FormProvider>
    </FiltersProvider>
  )
}

FiltersForm.Settings = Settings
FiltersForm.Anagraphic = Anagraphic
FiltersForm.Language = Language
FiltersForm.Job = Job
FiltersForm.Actions = Actions
FiltersForm.Education = Education
FiltersForm.Characteristic = Characteristic
FiltersForm.Location = Location
FiltersForm.PersonalityScores = PersonalityScores
FiltersForm.ColfScores = ColfScores
FiltersForm.CaretakerScores = CaretakerScores
FiltersForm.NannyScores = NannyScores

export default FiltersForm
