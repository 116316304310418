import React from 'react'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn
} from 'react-hook-form'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

interface IRenderProps {
  field: ControllerRenderProps<FieldValues, string>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<FieldValues>
}
interface IControlledTelInputProps {
  telInputRenderProps: (
    renderProps: IRenderProps
  ) => React.ComponentProps<typeof MuiTelInput>
  // TODO: probably wrap the component in a hook so that we can pass <T> and
  // infer the type of the controller fields
  // See: https://stackoverflow.com/a/73446747
  controllerProps: any
}

/**
 * Controlled MuiTelInput.
 *
 * Applies a Controller wrapper and a few default props to MuiTelInput.
 */
const ControlledTelInput: React.FC<IControlledTelInputProps> = (props) => {
  return (
    <Controller
      // Ignore validation if the field is empty (need to check length because of the country code)
      rules={{ validate: (tel: string) => tel.length < 5 || matchIsValidTel(tel) }}
      render={(renderProps) => (
        <MuiTelInput
          defaultCountry="IT"
          langOfCountryName="IT"
          preferredCountries={['IT']}
          forceCallingCode
          focusOnSelectCountry
          helperText={
            renderProps.fieldState.error && 'Inserisci un numero valido'
          }
          error={!!renderProps.fieldState.error}
          {...renderProps.field}
          fullWidth
          // show numeric keypad on mobile devices
          inputProps={{ inputMode: 'numeric' }}
          {...renderProps.field}
          {...props.telInputRenderProps(renderProps)}
        />
      )}
      {...props.controllerProps}
    />
  )
}

export { ControlledTelInput }
