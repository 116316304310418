import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

interface ControlledTextFieldProps extends Omit<TextFieldProps, "name"> {
    name: string;
}

const ControlledTextField: React.FC<ControlledTextFieldProps> = ({ name, ...other }) => {
    const methods = useFormContext();

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field }) => (
                <TextField
                    {...field}
                    value={field.value || ""}
                    fullWidth
                    size="small"
                    {...other}
                    variant="outlined"
                />
            )}
        />
    )
};

export default ControlledTextField;