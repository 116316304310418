import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { ISelectOption } from '../../../types'
import { FormHelperText } from '@mui/material'

interface IProps extends React.ComponentProps<typeof RadioGroup> {
  label: string
  options: ISelectOption[]
  required?: boolean,
  error?: boolean,
  helperText?: string
}

const ClassicRadioInput = React.forwardRef<HTMLDivElement, IProps>(
  ({ label, options, required, error, helperText, ...other }, ref) => {
    return (
      <FormControl ref={ref} required={required}
        error={error}
      >
        <FormLabel id={`${other.name}-radio-group`}>{label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby={`${other.name}-radio-group`}
          {...other}
        >
          {options.map((option, i) => (
            <FormControlLabel
              control={<Radio required={required} />}
              value={option.value}
              label={option.label}
              key={i}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    )
  }
)

export default ClassicRadioInput
