import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import Backdrop from '@mui/material/Backdrop'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { ICandidateResult } from '../../types'
import LoadingButton from '@mui/lab/LoadingButton'
import Chip from '@mui/material/Chip'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'
import { useSearchParams } from 'react-router-dom'
import { useGetSearchResultsQuery } from '../../services/crmApi'
import Fetcher from '../../utils/Fetcher'
import { CarouselToolbar } from './CarouselToolbar'
import Stack from '@mui/material/Stack'

interface CarouselPageProps {
  pageLabel: string
  handleNext: () => void
  handlePrev: () => void
  onClose: (e: any) => void
  candidate: ICandidateResult
  onFavorite: () => void
}

export default function Carousel() {
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const handleNext = (resultsLength: number) => {
    setIndex((prev) => (prev + 1) % resultsLength)
  }

  const handlePrev = (resultsLength: number) => {
    setIndex((prev) => (prev + resultsLength - 1) % resultsLength)
  }

  const handleClose = (e) => {
    setOpen(false)
    setIndex(0)
  }

  const OpenCarouselBtn = (props) => (
    <LoadingButton
      fullWidth
      variant="contained"
      onClick={() => setOpen(true)}
      startIcon={<ViewCarouselIcon />}
      {...props}
    >
      Carousel
    </LoadingButton>
  )

  return (
    <Fetcher.SearchResults
      render={({ data }) => (
        <>
          <OpenCarouselBtn
            onClick={() => setOpen(true)}
            disabled={data.results.length < 1}
          />
          {open && data.results.length > 0 && (
            <CarouselPage
              pageLabel={`${index + 1}/${data.results.length}`}
              handlePrev={() => handlePrev(data.results.length)}
              handleNext={() => handleNext(data.results.length)}
              onClose={handleClose}
              candidate={data.results[index]}
              onFavorite={() => alert('Favorite')}
            />
          )}
        </>
      )}
      loadingFallback={() => <OpenCarouselBtn loading />}
    />
  )
}

function CarouselPage({
  pageLabel,
  handleNext,
  handlePrev,
  onClose,
  candidate,
  onFavorite
}: CarouselPageProps) {

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') {
      handleNext()
    } else if (e.key === 'ArrowLeft') {
      handlePrev()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={(e) => e.currentTarget == e.target && onClose(e)}
        open
      >
        {/* Page count chip */}
        <Stack direction="row" gap={1} sx={{ position: 'absolute', top: 5, left: 5, zIndex: 100 }}>
          <Chip
            style={{
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)'
            }}
            label={pageLabel}
          />
          {candidate.score.length > 0 && (
            <Chip
              style={{
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)'
              }}
              label={`Pertinenza: ${candidate.score}`}
            />
          )}
        </Stack>
        <CarouselToolbar
          isFavorite={candidate.is_favorite[0]}
          candidateId={candidate.id}
          onClose={onClose}
        />
        <IconButton
          onClick={handleNext}
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            color: 'white',
            top: '50%',
            right: 5,
            zIndex: 100
          }}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          size="large"
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          onClick={handlePrev}
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            color: 'white',
            top: '50%',
            left: 5
          }}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <iframe
          src={`/candidates/${candidate.id}/carousel_slide`}
          style={{
            height: '97vh',
            width: '90%',
            maxWidth: 900,
            backgroundColor: 'white'
          }}
          allowTransparency
        />
      </Backdrop>
    </>
  )
}
