import React, { forwardRef } from 'react'
import Chip from '@mui/material/Chip'
import { ISelectOption } from '../../../types'
import Stack from '@mui/material/Stack'

interface IChipCheckboxInputProps {
  options: ISelectOption[]
  value: null | (string | number | boolean)[]
  onChange: (value: (string | number | boolean)[]) => void
}

const ChipCheckboxInput = React.forwardRef<
  HTMLSelectElement,
  IChipCheckboxInputProps
>(({
  options,
  value,
  onChange
}, ref) => {
  const handleClick = (newOption: ISelectOption) => {
    const newValue = value
      ? value.includes(newOption.value)
        ? value.filter((val) => val !== newOption.value) // Remove the option if already selected
        : [newOption.value, ...value] // Add the option if not selected
      : [newOption.value] // Add the option if no value is selected
    onChange(newValue)
  }

  return (
    <Stack direction="row" flexWrap="wrap" ref={ref}>
      {options.map((option) => (
        <Chip
          key={option.value.toString()}
          label={option.label}
          onClick={() => handleClick(option)}
          color={value && value.includes(option.value) ? 'primary' : 'default'}
          sx={{
            mr: '0.1rem',
            mb: '0.1rem',
            flexGrow: 1
          }}
          size="small"
          clickable
        />
      ))}
    </Stack>
  )
}
)

interface IChipRadioInputProps {
  options: ISelectOption[];
  value: string | number | boolean;
  onChange: (value: string | number | boolean) => void;
}

const ChipRadioInput = React.forwardRef<
  HTMLSelectElement,
  IChipRadioInputProps
>(
  (
    { options, value, onChange },
    ref
  ) => {
    const handleCheckboxChange = (newValue: (string | number | boolean)[]) => {
      // Set the first selected value as the value for radio-like feel
      onChange(newValue.length > 0 ? newValue[0] : '');
    };

    // Debugger if value is boolean
    // if (typeof value === 'boolean') {
    //   console.log('ChipRadioInput: value is boolean');
    //   debugger;
    // }

    return (
      <ChipCheckboxInput
        options={options}
        value={(value === '' || value === null || value === undefined) ? [] : [value]}
        onChange={handleCheckboxChange}
        ref={ref}
      />
    );
  }
);

export { ChipRadioInput, ChipCheckboxInput }
