import { useEffect } from 'react';

const useUnsavedWarning = (isDirty: boolean) => {
    //TODO: re-enable this
    // useEffect(() => {
    //     window.onbeforeunload = function () {
    //         if (isDirty) {
    //             return 'Ci sono modifiche non salvate';
    //         }
    //     };
    // }, [isDirty]);
};

export default useUnsavedWarning;
