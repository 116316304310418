import React, { useRef } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BrowserRouter } from 'react-router-dom'
import { Search } from '@mui/icons-material';
import { Stack, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { LeadsTabs } from './LeadsTabs';

const LeadsIndexPage = (props) => {
    const [searchValue, setSearchValue] = React.useState("");
    const searchInputRef = useRef(null);

    // On search form submit, update searchValue using ref
    // we do not update it directly because we want to avoid
    // re-rendering the component each time the user types a letter
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchValue(searchInputRef.current.value);
    }

    return (
        <>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ my: 1 }}>
                <Typography variant="h4" component="h1">
                    Clienti
                </Typography>
                <form onSubmit={handleSearchSubmit}>
                    <TextField
                        placeholder="Cerca qualcosa..."
                        inputProps={{ 'aria-label': 'search' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit" aria-label="search">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        size="medium"
                        inputRef={searchInputRef}
                    />
                </form>
            </Stack >
            <LeadsTabs searchValue={searchValue} />
        </>

    )
}

export default (props) => (
    // Wrap in store to allow the use of RTK Query
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <LeadsIndexPage {...props}></LeadsIndexPage>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
)
