import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import {
  ICandidateAttributes
} from '../../../../../types'
import FetchSelectMulti from '../../../../input/select/FetchSelectMulti'
import { useGetAvailabilitiesQuery, useGetCoursesQuery, useGetDegreesQuery, useGetJobsQuery } from '../../../../../services/crmApi'
import { InputAdornment, TextField, Typography } from '@mui/material';
import Select from '../../../../input/select/Select';

const JobSection: React.FC<{}> = ({ }) => {

  const form = useFormContext<ICandidateAttributes>()

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Lavoro</Typography>
      </Grid>

      {/* Jobs */}
      <Grid xs={12}>
        <Controller
          name={`job_ids`}
          control={form.control}
          render={({ field }) => (
            <FetchSelectMulti
              {...field}
              label="Mansioni ricercate"
              fullWidth
              options={useGetJobsQuery}
              error={!!form.formState.errors?.job_ids}
              // helperText={form.formState.errors?.job_ids?.message}
              size="small"

            />
          )}
        />
      </Grid>

      {/* Availabilities */}
      <Grid xs={12}>
        <Controller
          name={`availability_ids`}
          control={form.control}
          render={({ field }) => (
            <FetchSelectMulti
              {...field}
              label="Disponibilità (cerco)"
              fullWidth
              options={useGetAvailabilitiesQuery}
              error={!!form.formState.errors?.availability_ids}
              size="small"
            />
          )}
        />
      </Grid>

      <Grid xs={12} sm={6}>
        <Controller
          name='avail_weekends'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Disponibilità weekend"
              options={[
                { label: 'Sì (tutti)', value: 'all' },
                { label: 'Sì (alcuni)', value: 'some' },
                { label: 'No', value: 'none' }
              ]}
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid xs={12} sm={6}>
        <Controller
          name='avail_trips'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Disponibilità trasferte"
              options={[
                { label: 'Si (tutte)', value: 'all' },
                { label: 'No', value: 'none' }
              ]}
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>

      {/* Desired time start */}
      <Grid xs={6} sm={4}>
        <Controller
          name="desired_work_time_start"
          control={form.control}
          render={({ field }) => (
            <Select
              {...field}
              label="Ora inizio"
              fullWidth
              allowEmpty
              options={
                // Range 0-23
                Array.from(Array(24), (_, i) => i).map((i) => ({ label: i.toString(), value: i.toString() }))
              }
              error={!!form.formState.errors?.desired_work_time_start}
              size="small"
            />
          )}
        />
      </Grid>

      {/* Desired time end */}
      <Grid xs={6} sm={4}>
        <Controller
          name="desired_work_time_end"
          control={form.control}
          render={({ field }) => (
            <Select
              {...field}
              label="Ora fine"
              fullWidth

              options={
                // Range 0-23
                Array.from(Array(24), (_, i) => i).map((i) => ({ label: i.toString(), value: i.toString() }))
              }
              error={!!form.formState.errors?.desired_work_time_end}
              size="small"
            />
          )}
        />
      </Grid>

      {/* Desired salary */}
      <Grid xs={12} sm={4}>
        <Controller
          name={`desired_salary`}
          control={form.control}
          render={({ field }) => (

            <Select
              {...field}
              label="Stipendio desiderato"
              fullWidth
              options={
                // 100 - 6000€
                Array.from(Array(60), (_, i) => (i + 1) * 100).map((i) => ({ label: `${i} €`, value: i.toString() }))
              }
              error={!!form.formState.errors?.desired_salary}
              size="small"
            />
          )}
        />
      </Grid>



    </Grid >
  )
}
export default JobSection
