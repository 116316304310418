import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import {
  ICandidateAttributes
} from '../../../../../types'
import { Typography } from '@mui/material';
import Select from '../../../../input/select/Select';
import { RATING_OPTIONS } from '../../../../../enums'
import { CANDIDATE_VISIBLE_STEPS } from "../../../../../enums";

const OtherSection: React.FC<{}> = ({ }) => {

  const form = useFormContext<ICandidateAttributes>()

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Altri dettagli</Typography>
      </Grid>

      <Grid xs={12} sm={6}>
        <Controller
          name='rating'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Rating"
              options={RATING_OPTIONS}
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Controller
          name='step'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Step"
              options={CANDIDATE_VISIBLE_STEPS}
              {...field}
              fullWidth
              allowEmpty={false}
            />
          )}
        />
      </Grid>



    </Grid >
  )
}
export default OtherSection
