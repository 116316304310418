import React from 'react'
import SelectMulti, { SelectMultiProps } from './SelectMulti'
import COUNTRIES_OPTIONS from '../../../locales/countries'
import Select, { SelectProps } from './Select'

const CountrySelectMulti = React.forwardRef<
    HTMLSelectElement,
    Omit<SelectMultiProps, "options">
>((props, ref) => (
    <SelectMulti
        options={COUNTRIES_OPTIONS}
        favourites={['IT', 'LK', 'PH', 'GE', 'PE', 'EC', 'SV']}
        {...props}
        ref={ref}
    />
));

const CountrySelect = React.forwardRef<
    HTMLSelectElement,
    Omit<SelectProps, "options">
>((props, ref) => (
    <Select
        options={COUNTRIES_OPTIONS}
        favourites={['IT', 'LK', 'PH', 'GE', 'PE', 'EC', 'SV']}
        {...props}
        ref={ref}
    />
));


export { CountrySelectMulti, CountrySelect }