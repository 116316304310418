import React, { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ICustomerAttributes } from '../../../types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import { EmptyResultsPlaceholder } from '../../candidates/index/tabpanels/EmptyResultsPlaceholder'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import ShareLinkDialog from './ShareLinkDialog'
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility';
import LaunchIcon from '@mui/icons-material/Launch';
import { useDestroyCustomerMutation } from '../../../services/crmApi'
import { Btn } from '../../../utils/Btn'
import DeleteIcon from '@mui/icons-material/Delete'


interface Props {
    data?: ICustomerAttributes[],
    tab: string
}

const LeadsTable: React.FC<Props> = (props) => {


    return props.data ? (
        // No results
        props.data.length === 0 ? (
            <EmptyResultsPlaceholder />
        ) : (
            // Results found
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome e note</TableCell>
                            <TableCell align="right">Ultima modifica</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRows data={props.data} tab={props.tab} />
                    </TableBody>
                </Table>
            </TableContainer>
        )
    ) : (
        // Results are not loaded yet
        <Stack
            sx={{ height: '300px', textAlign: 'center', py: 5, px: 3 }}
            alignItems="center"
            justifyContent="center"
            gap={2}
        >
            <HourglassEmptyOutlinedIcon fontSize="large" />
            <Typography variant="h6">Caricamento...</Typography>
        </Stack>
    )
}

function TableRows(props: { data: ICustomerAttributes[], tab: string }) {
    const [openId, setOpenId] = React.useState(null)

    return (
        <>
            {props.data.map((r) => (
                <Row
                    result={r}
                    key={r.id}
                    open={r.id === openId}
                    onClick={() => setOpenId(r.id === openId ? null : r.id)}
                    tab={props.tab}
                />
            ))}
        </>
    )
}

interface IRowProps {
    result: ICustomerAttributes
    open: boolean
    onClick: () => void
    tab: string
}


function Row({ result, open, onClick, ...props }: IRowProps): JSX.Element {
    const [destroyCustomer, { isLoading, isError, isSuccess }] = useDestroyCustomerMutation()

    // Show error / success message
    useEffect(() => {
        if (isError) {
            //@ts-ignore-next-line
            alert("Impossibile eliminare il cliente")
        }
        if (isSuccess) {
            //@ts-ignore-next-line
            notice("Cliente eliminato con successo")
        }
    }, [isError, isSuccess])


    return (
        <>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td': { borderBottom: 'none' }
                }}
                onClick={onClick}
            >
                <TableCell>
                    <ListItemText
                        primary={result.full_name}
                        secondary={
                            <Typography variant="body2"
                                color="text.secondary"
                            >{result.notes || "..."}</Typography>
                        }
                    />
                </TableCell>
                <TableCell align="right">
                    <Typography variant="body2">
                        {new Date(result.updated_at).toLocaleDateString('it-IT', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                        })}
                    </Typography>
                </TableCell >
            </TableRow >
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
                    colSpan={2}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 1, mb: 5 }}>
                            <Stack direction={{ md: 'row' }} gap={1} sx={{ mb: 2 }}>
                                <>
                                    {props.tab === "leads" && (<>
                                        <Button
                                            variant="contained"
                                            component="a"
                                            href={`/customers/leads/${result.id}/edit`}
                                            sx={{ flexGrow: 1 }}
                                            startIcon={<EditOutlinedIcon />}
                                        >
                                            Modifica
                                        </Button>
                                        <Button
                                            variant="contained"
                                            component="a"
                                            href={`/candidates?from_lead_id=${result.id}`}
                                            sx={{ flexGrow: 1 }}
                                            startIcon={<PersonSearchIcon />}
                                        >
                                            Ricerca preliminare
                                        </Button>

                                        <ShareLinkDialog link={`https://crm.accademiadellacasa.it/customer_wizard?c=${result.id}&utm_campaign=copy`} />
                                    </>)}
                                    {props.tab === "submitted" && (
                                        <>
                                            <Button
                                                variant="contained"
                                                component="a"
                                                href={`/contracts?page=1&tab=to-approve`}
                                                sx={{ flexGrow: 1 }}
                                                startIcon={<LaunchIcon />}
                                            >
                                                Vai ai contratti da approvare
                                            </Button>
                                        </>)}
                                    {props.tab === "completed" && (
                                        <>
                                            <Button
                                                variant="contained"
                                                component="a"
                                                href={`/customers/${result.id}`}
                                                sx={{ flexGrow: 1 }}
                                                startIcon={<VisibilityIcon />}
                                            >
                                                Visualizza profilo
                                            </Button>
                                        </>)}

                                    <Btn
                                        loading={isLoading}
                                        askConfirm
                                        sx={{ flexGrow: 1 }}
                                        startIcon={<DeleteIcon />}
                                        confirmTitle="Elimina cliente"
                                        confirmMsg="Sei sicuro di voler cancellare questo cliente? L'azione non può essere annullata."
                                        onClick={() => destroyCustomer(result.id)}
                                        variant="contained"
                                        color="error"
                                    >
                                        Elimina
                                    </Btn>
                                </>

                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}




export default LeadsTable
