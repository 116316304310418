import React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  useCreateOrUpdateSearchMutation
} from '../../../services/crmApi'
import { useForm, Controller } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import setNullToEmptyString from '../../../utils/setNullToEmptyString'
import LoadingButton from '@mui/lab/LoadingButton'
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'
import Fetcher from '../../../utils/Fetcher'
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import Select from '../../input/select/Select'
import { ISelectOption } from '../../../types';

const LAST_UPDATE_MONTH_LIMIT_OPTIONS: Array<ISelectOption> = [
  { label: "max 1 Mese fa", value: 1 },
  { label: "max 2 Mesi fa", value: 2 },
  { label: "max 3 Mesi fa", value: 3 },
  { label: "max 6 Mesi fa", value: 6 },
  { label: "max 12 Mesi fa", value: 12 },
  { label: "max 24 Mesi fa", value: 24 },
]

interface Props {
}

export default function DateFilterDialog({ }: Props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {/* List item */}
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={handleClickOpen}>
          <ListItemIcon>
            <EventRepeatOutlinedIcon />
          </ListItemIcon>
          <Fetcher.SearchModel
            render={({ data }) => {
              const selectedOption = LAST_UPDATE_MONTH_LIMIT_OPTIONS.find(option => option.value === data.last_update_months_limit);
              const label = selectedOption ? selectedOption.label : "Qualsiasi data";
              return (
                <ListItemText primary={label} secondary="Clicca per modificare" />
              );
            }}
            loadingFallback={() => <span>Caricamento...</span>}
          />
        </ListItemButton>
      </ListItem>
      {/* Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Limita risultati per data</DialogTitle>

        <Fetcher.SearchModel
          render={({ data }) => (
            <FormDialog
              initialValues={setNullToEmptyString(data)}
              onSuccess={handleClose}
            />
          )}
          loadingFallback={() => <DialogContent>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>}
        />
      </Dialog>
    </>
  )
}

function FormDialog({
  initialValues,
  onSuccess,
}: {
  initialValues: any
  onSuccess: () => void
}) {
  const { handleSubmit, control } = useForm({ defaultValues: initialValues })

  const [triggerUpdate, { isLoading, isSuccess, isError }] =
    useCreateOrUpdateSearchMutation()

  // Handle success and error
  React.useEffect(() => {
    // Handle success
    if (isSuccess) {
      onSuccess()
      return
    }
    // Handle error
    if (isError) {
      alert('Errore durante il salvataggio')
      return
    }
  }, [isSuccess, isError, onSuccess])



  return (
    <form onSubmit={handleSubmit(triggerUpdate)}>
      <DialogContent>
        <Stack gap={2} sx={{ minWidth: '500px' }}>
          {/* Limit by date */}
          <Controller
            name="last_update_months_limit"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Data ultimo aggiornamento"
                fullWidth
                options={
                  LAST_UPDATE_MONTH_LIMIT_OPTIONS

                }
                helperText="Mostra solo i risultati aggiornati recentemente. Lascia vuoto per disattivare questo filtro."
              />
            )}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            fullWidth
          >
            Salva
          </LoadingButton>
        </Stack>
      </DialogContent>
    </form>
  )
}
