import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Tooltip, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ChipRadioInput, ChipCheckboxInput } from '../chip/ChipInput'
import { Controller, useFormContext } from 'react-hook-form'
import { ISelectOption } from '../../../types'
import FocusTrapTextField from '../FocusTrapTextField'

interface IRapidFormTileProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  description?: string
}
interface ITileSubComponentProps {
  ChipInput: typeof ChipInputTile
  // FocusTrapTile: typeof FocusTrapTile
}

/**
 * A semi-transparent white box with a label and some space for content.
 * Used in a RapidFormSection
 *
 * @param label The label of the tile
 * @param children The content of the tile. Usually an input.
 *
 */
const RapidFormTile: React.FC<IRapidFormTileProps> & ITileSubComponentProps = (
  props
) => {
  return (
    <Box
      sx={{
        p: 0.5,
        backgroundColor: '#ffffffdd'
      }}
      style={{ height: '100%', backgroundColor: props.color }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="body2">
          <b>{props.label}</b>
        </Typography>
        {props.description && (
          <Tooltip title={props.description}>
            <IconButton size={'small'}>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Box>{props.children}</Box>
    </Box>
  )
}

interface IChipInputTileProps extends IRapidFormTileProps {
  name: string
  options: ISelectOption[]
  variant?: 'checkbox' | 'radio'
}

/**
 * A pre-configured RapidFormTile that contains a ChipInput
 * @param name The name of the field
 * @param options The options to display
 * @param variant The variant of the input. Either 'checkbox' or 'radio'
 * @see RapidFormTile
 */
const ChipInputTile: React.FC<IChipInputTileProps> = (props) => {
  const { control } = useFormContext()

  const { options, variant, ...rest } = props

  return (
    <RapidFormTile {...rest}>
      <Controller
        name={props.name}
        control={control}
        render={({ field }) =>
          props.variant === 'checkbox' ? (
            <ChipCheckboxInput options={options} {...field} />
          ) : (
            <ChipRadioInput options={options} {...field} />
          )
        }
      />
    </RapidFormTile>
  )
}

RapidFormTile.ChipInput = ChipInputTile

// interface IFocusTrapTileProps extends IRapidFormTileProps {
//   name: string
// }

// const FocusTrapTile: React.FC<IFocusTrapTileProps> = (props) => {
//   const { control } = useFormContext()

//   const { name, ...tileProps } = props

//   return (
//     <RapidFormTile {...tileProps}>
//       <Controller
//         name={name}
//         control={control}
//         render={({ field }) => <FocusTrapTextField {...field} />}
//       />
//     </RapidFormTile>
//   )
// }

// RapidFormTile.FocusTrapTile = FocusTrapTile

export { RapidFormTile, IChipInputTileProps }
