import setNullToEmptyString from '../utils/setNullToEmptyString'
import { addDestroyPseudoField } from './railsApiUtils'
import { IFavoriteAttributes, ISearchAttributes } from '../types'

/**
    Parse search response in a react-hook-form friendly way
 */
export function transformSearchAttributes(
  response: any,
  _meta
): ISearchAttributes {
  const { favorites, range, spoken_languages, ...rest } = response.search
  return setNullToEmptyString({
    ...rest,
    favorites_attributes: favorites
      .map(addDestroyPseudoField)
      .map(transformFavoriteAttributes),
    spoken_languages_attributes: spoken_languages.map(addDestroyPseudoField),

    range: range || 25 // Override range to 25 if null. TODO: set default in backend
  })
}

/**
    Replace candidate object with candidate_attributes object
*/
function transformFavoriteAttributes(attributes: any): IFavoriteAttributes {
  const { candidate, ...rest } = attributes
  return { ...rest, candidate_attributes: candidate }
}
