import React, { useCallback, useEffect } from 'react'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Stack } from '@mui/system'
import {
  ICandidateAttributes
} from '../../../../../../types'
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import { useSearchParams } from 'react-router-dom'
import EmploymentFormFields from "./EmploymentFormFields"
import { SortableAccordionsContainer, SortableAccordion } from "../../../../../developer/SortableAccordions"

const EmploymentsSection: React.FC<{}> = ({ }) => {
  const { control, setValue, formState: { errors } } = useFormContext<ICandidateAttributes>()
  const { fields, prepend, move, remove } = useFieldArray({
    control: control,
    name: 'employments_attributes',
    keyName: 'form_key' // Rename key to avoid conflicts with `id` field
  })

  // If the URL contains the query parameter `add_contract_empl`, go to the employments tab to rapidly add a new employment
  const [params] = useSearchParams()
  const contractToAddEmployment: number | null = parseInt(params.get('add_contract_empl')) || null

  useEffect(() => {
    if (contractToAddEmployment) {
      // Url specifies a contract to add employment to.
      // prepend a new employment with the contract_id set to the contract specified in the URL
      prepend({
        ...NEW_EMPL_DEFAULTS,
        contract_id: contractToAddEmployment,
        backed_by_adc: true,
        started_at: new Date().getFullYear().toString(),
      })
      // Return a remove action, so that the double-rerender in
      // dev mode (caused by React.StrictMode ) does not add a second employment
      return () => remove(0)
    }
  }, [])

  // Store index * -1 as sorting_priority. This is used to sort the array on the backend
  const updateSortingPriorities = () => {
    fields.forEach((field, index) => {
      // console.log(`employments_attributes.${index}.sorting_priority`, -index)
      setValue(`employments_attributes.${index}.sorting_priority`, -index);
    });
  };

  // Each time the fields array changes, update the sorting priorities
  useEffect(() => {
    updateSortingPriorities()
  }, [fields])

  // Add an employment on top of the list
  const handleAddEmployment = () => {
    prepend(NEW_EMPL_DEFAULTS)
  }

  // Move the employment to the new position
  const handleReorder = (oldIndex: number, newIndex: number) => {
    move(oldIndex, newIndex);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Esperienza (dalla più recente)</Typography>
      </Grid>

      {/* Add employment button */}
      <Grid xs={12}>
        <Button
          variant="outlined"
          onClick={handleAddEmployment}
          fullWidth
          startIcon={<PlaylistAddOutlinedIcon />}
        >
          Aggiungi esperienza
        </Button>
      </Grid>

      {fields.length === 0 ?
        <Grid xs={12}>
          <Stack direction="column" alignItems={"center"} spacing={1} sx={{
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: '5px',
            p: 2
          }}>
            <WorkHistoryOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography variant="caption">Nessuna esperienza inserita</Typography>
          </Stack>
        </Grid> : (
          <SortableAccordionsContainer onReorder={handleReorder} itemIds={fields.map((field) => field.form_key)}>
            {fields.map((field, index) => (
              <AccordionWrapper key={field.form_key} id={field.form_key} index={index} />
            ))}
          </SortableAccordionsContainer>
        )}


    </Grid >
  )
}



// Wrap accordion to limit the useWatch rerender scope to this component instead of whole array
const AccordionWrapper = ({ id, index }) => {

  const { setValue, formState: { errors } } = useFormContext();

  const _destroy = useWatch({ name: `employments_attributes.${index}._destroy` });
  const started_at = useWatch({ name: `employments_attributes.${index}.started_at` }) || "?";
  const ended_at = useWatch({ name: `employments_attributes.${index}.ended_at` }) || "?";
  const family_name = useWatch({ name: `employments_attributes.${index}.family_name` }) || "?";
  // const sorting_priority = useWatch({ name: `employments_attributes.${index}.sorting_priority`, defaultValue: "sconosciuto" });

  const setDestroyed = (destroyed: boolean) => {
    setValue(`employments_attributes.${index}._destroy`, destroyed)
  }

  return (
    <SortableAccordion
      key={id}
      id={id}
      onDelete={() => setDestroyed(true)}
      onRestore={() => setDestroyed(false)}
      title={`Dal ${started_at} al ${ended_at} presso ${family_name}`}
      isDeleted={_destroy}
      isError={!!errors?.employments_attributes?.[index]}
      defaultExpanded={false}
      sortButtons={true}
    >
      <EmploymentFormFields index={index} />
    </SortableAccordion>
  )
}



export default EmploymentsSection


const NEW_EMPL_DEFAULTS = {
  id: '',
  candidate_id: '',

  // Contract attributes
  contract_id: '',
  contract: {
    name_with_date: ''
  },

  // Job attributes
  started_at: '',
  ended_at: '',
  job_ids: [],
  job_other: '',
  salary: '',

  // Availability
  availability_id: '',
  work_time_start: '',
  work_time_end: '',

  // Employer attributes
  ref_phone: '',
  ref_name: '',
  family_name: '',
  end_reason: '',

  // Other attributes
  notes: '',
  notes_public: '',
  show_in_cv: true,
  backed_by_adc: false,

  // Internal utility fields
  sorting_priority: 0,
  created_at: '',
  updated_at: '',

  // Array
  _destroy: false
}