import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import FormWithSortableAccordions from './FormWithSortableAccordions'

function ReactTestPage({ }) {


  return (<>
    <FormWithSortableAccordions />
  </>)
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ReactTestPage />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
