import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import L from './locales/it.json'
import { IRefFieldProps, ISelectOption } from './types'

const BOOL_OPTIONS: ISelectOption[] = [
  { label: 'No', value: false, icon: <BlockIcon fontSize="large" /> },
  { label: 'Si', value: true, icon: <CheckIcon fontSize="large" /> }
]

const SORT_OPTIONS = [
  { label: 'Data di modifica', value: 'by_date' },
  { label: 'Pertinenza', value: 'by_score' }
]

const CANDIDATE_VISIBLE_STEPS: Array<ISelectOption & { showTab: boolean }> = [
  { value: "submitted", label: "Attesa interv. (liv.1)", showTab: true }, //TODO: move this
  { value: "interview_l1", label: "Attesa interv. (liv.2)", showTab: true },
  { value: "interview_l2", label: "Attesa referenze", showTab: true },
  { value: "ref_completed", label: "Attesa rating", showTab: true },
  { value: "completed", label: "Completato", showTab: false },
]

// Cannot use i18n-js ones as those are alphabetically sorted (RIP)
const REF = {
  gender: [
    { value: 'male', label: 'Uomo' },
    { value: 'female', label: 'Donna' }
  ],
  weight: [
    { value: 'weight0', label: 'No' },
    { value: 'weight1', label: 'Poco' },
    { value: 'weight2', label: 'Norm.' },
    { value: 'weight3', label: 'Molto' }
  ],
  satisfaction: [
    { value: 'unhappy', label: 'Poco cont.' },
    { value: 'happy', label: 'Contenta' },
    { value: 'very_happy', label: 'Molto cont.' }
  ],
  assist_whom: [
    { value: 'mum', label: 'Mamma' },
    { value: 'dad', label: 'Papà' },
    { value: 'other', label: 'Altro' }
  ],
  assist_difficulty: [
    { value: 'easy', label: 'Facile' },
    { value: 'difficult', label: 'Difficile' }
  ],
  autonomy: [
    { value: 'low', label: 'Da seguire' },
    { value: 'mid', label: 'Abbastanza' },
    { value: 'high', label: 'Molto' }
  ],
  rigidity: [
    { value: 'low', label: 'Disponibile' },
    { value: 'high', label: 'Rigida' }
  ],
  energy: [
    { value: 'low', label: 'Delicata' },
    { value: 'high', label: 'Energica' }
  ],
  temper: [
    { value: 'remissive', label: 'Remissivo' },
    { value: 'equilibrate', label: 'Equilibrato' },
    { value: 'strong', label: 'Forte' }
  ],
  solarity: [
    { value: 'low', label: 'Ombrosa' },
    { value: 'high', label: 'Solare' }
  ],
  verbosity: [
    { value: 'low', label: 'Parla poco' },
    { value: 'high', label: 'Parla tanto' }
  ],
  self_care: [
    { value: 'low', label: 'Sciatta' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Curata' },
    { value: 'very_high', label: 'Eccessiva' }
  ],
  punctuality: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  driving_lvl: [
    { value: 'low', label: 'Male' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto bene' }
  ],
  colf_speed: [
    { value: 'low', label: 'Lenta' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Veloce' }
  ],
  colf_cleanliness: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  colf_tidiness: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  colf_precision: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  ironing_skills: [
    { value: 'not_required', label: 'Non rich.' },
    { value: 'unsufficient', label: 'Insuff.' },
    { value: 'acceptable', label: 'Accettabile' },
    { value: 'good', label: 'Buono' },
    { value: 'very_good', label: 'Ottimo' }
  ],
  cooking_skills: [
    { value: 'not_required', label: 'Non rich.' },
    { value: 'unsufficient', label: 'Insuff.' },
    { value: 'kids', label: 'x Bambini' },
    { value: 'elders', label: 'x Anziani' },
    { value: 'adults', label: 'x Adulti' },
    { value: 'very_good', label: 'Eccellente' }
  ],
  sweetness: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  companionship: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  nursing_skills: [
    { value: 'low', label: 'Scarse' },
    { value: 'mid', label: 'Medie' },
    { value: 'high', label: 'Elevate' }
  ],
  scolarship: [
    { value: 'low', label: 'No' },
    { value: 'mid', label: 'Abbastanza' },
    { value: 'high', label: 'Sì' }
  ],
  playfulness: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  authority: [
    { value: 'low', label: 'Poco' },
    { value: 'mid', label: 'Normale' },
    { value: 'high', label: 'Molto' }
  ],
  obedience: [
    { value: 'low', label: 'No' },
    { value: 'mid', label: 'Abbastanza' },
    { value: 'high', label: 'Molto' }
  ],
  nanny_predisposition: [
    { value: 'newborns', label: 'Neonati' },
    { value: 'babies', label: 'Piccoli' },
    { value: 'kids', label: 'Età scolare' }
  ],
  end_reason: [
    { value: 'fired', label: 'Licenziata' },
    { value: 'quit', label: 'Dimessa' },
    { value: 'not_needed', label: 'Non serve più' },
    { value: 'no_employer', label: 'Mancato il datore' },
    { value: 'incompatible', label: 'Incompatibilità di carattere' },
    { value: 'other', label: 'Altro' }
  ]
}

function getReferenceFieldProps(name: string): IRefFieldProps {
  // Get field label
  // TODO: deprecated in backend, hardcode directly to frontend
  const label: string = L.it.simple_form.labels.reference[name]

  const options: ISelectOption[] | undefined = REF[name]
  if (options === undefined) {
    // Field is boolean
    return { name, label, options: BOOL_OPTIONS }
  }
  // Field is enum
  return { name, label, options }
}

const REF_FIELDS = {
  personality: [
    'autonomy',
    'rigidity',
    'energy',
    'temper',
    'solarity',
    'verbosity',
    'is_patient',
    'is_touchy',
    'self_care',
    'is_reserved',
    'punctuality',
    'has_management_skills',
    'unionized',
    'driving_lvl'
  ].map(getReferenceFieldProps),
  colf: [
    'colf_speed',
    'colf_cleanliness',
    'colf_tidiness',
    'colf_precision',
    'ironing_skills',
    'cooking_skills',
    'serves_table'
  ].map(getReferenceFieldProps),
  caretaker: ['sweetness', 'companionship', 'nursing_skills', 'scolarship'].map(
    getReferenceFieldProps
  ),
  nanny: ['playfulness', 'authority', 'obedience', 'nanny_predisposition'].map(
    getReferenceFieldProps
  )
}

// TODO: Get from i18n file
const CONTRACT_STATUS_OPTIONS = [
  { value: 'sent', label: 'Inviato' },
  { value: 'brand_new', label: 'Nuovo' },
  { value: 'signed', label: 'Firmato' },
  { value: 'closed', label: 'Chiuso' },
  { value: 'reopened', label: 'Riaperto' },
  { value: 'canceled', label: 'Annullato' },
  { value: 'never_signed', label: 'Mai firmato' },
  { value: 'failed', label: 'Fallito' }
]

// TODO: Get from i18n file
const RATING_OPTIONS = [
  { value: 'rejected', label: 'Scartato' },
  { value: 'low', label: 'Basso' },
  { value: 'medium', label: 'Medio' },
  { value: 'good', label: 'Alto' },
  // { value: 'none', label: 'Nessuno' },
]


export { REF, BOOL_OPTIONS, SORT_OPTIONS, REF_FIELDS, CONTRACT_STATUS_OPTIONS, RATING_OPTIONS, CANDIDATE_VISIBLE_STEPS }
