import React, { useEffect } from 'react'
import { ICandidateAttributes } from '../../../types'
import { useForm, FormProvider } from 'react-hook-form'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BrowserRouter } from 'react-router-dom'
import setNullToEmptyString from '../../../utils/setNullToEmptyString'
import Stack from '@mui/material/Stack'
import Tabs from '../../../utils/Tabs'
import LoadingButton from '@mui/lab/LoadingButton'
// import validSchema from './joiSchema'
import AnagraphicSection from './form/sections/00_Anagraphic'
import EmploymentSection from './form/sections/employment/EmploymentsSection'
// import BaseData from './form/sections/BaseData'
import AddressSection from './form/sections/00a_Address';
import CharacteristicsSection from './form/sections/01_Characteristics';
import VisaSection from './form/sections/01a_visa'
import FamilySection from './form/sections/02_family';
import SpokenLanguagesSection from './form/sections/05_SpokenLanguages';
import EducationSection from './form/sections/06_Education';
import JobSection from './form/sections/07_Job';
import OtherSection from './form/sections/08_other';
import { useCreateCandidateMutation, useUpdateCandidateMutation } from '../../../services/crmApi'
import { Typography } from '@mui/material'
import { groupHasErrors } from '../../../utils/validation'
import validSchema from './joiSchema'
import useUnsavedWarning from '../../../hooks/useUnsavedWarning';

interface IProps {
  mode: "new" | "edit"
  candidate: ICandidateAttributes
}

const AdvancedCandidateFormPage: React.FC<IProps> = (props) => {

  const form = useForm<ICandidateAttributes>({
    defaultValues: setNullToEmptyString(props.candidate),
    resolver: validSchema,
    // set on validate mode to 'onChange' to validate on every change
    // mode: 'onBlur',
    // mode: 'onChange',
    reValidateMode: 'onSubmit'
  })

  // Choose the right mutation based on the mode
  const submitQueryHook = props.mode === "new" ? useCreateCandidateMutation : useUpdateCandidateMutation
  const [submit, { isLoading, isError, isSuccess, data }] = submitQueryHook()

  // After successful submit, follow redirect
  React.useEffect(() => {
    if (isSuccess && data) {
      window.location.href = data.redirect
    }
  }, [isSuccess, data])

  // Handle form submit errors
  React.useEffect(() => {
    if (isError) {
      alert("Errore durante il salvataggio")
    }
  }, [isError])

  const { errors } = form.formState
  // const values = form.watch()
  // console.log(errors)
  // console.log(values)

  useEffect(() => {
    if (errors) {
      console.log("Validation errs:", errors)
    }
  }, [errors])

  useUnsavedWarning(form.formState.isDirty)

  // const myhandleSubmit = (data) => {
  //   console.log("Submit data:", data)
  // }


  return (
    <FormProvider {...form}>
      <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
        {props.mode === "new" ? "Nuovo candidato" : "Modifica candidato"}
      </Typography>
      <form onSubmit={form.handleSubmit(submit)}>
        <Tabs
          sections={[
            {
              title: 'Anagrafica',
              component: <AnagraphicSection />,
              error: groupHasErrors(errors,
                [
                  "name",
                  "surname",
                  "nickname",
                  "birthdate",
                  "gender",
                  "mail",
                  'country',
                  'citizenship',
                  "it_arrival_year",
                  "notes_attributes",
                ]
              ),
            },
            {
              title: 'Indirizzo',
              component: <AddressSection />,
              error: groupHasErrors(errors,
                [
                  "full_address",
                  "street",
                  "street_number",
                  "zip",
                  "city",
                  "province",
                  "address_country",
                  "latitude",
                  "longitude",
                ]
              ),
            },
            {
              title: 'Esperienza',
              component: <EmploymentSection />,
              error: groupHasErrors(errors, ["employments_attributes"]),
            },
            {
              title: 'Caratteristiche',
              component: <CharacteristicsSection />,
              error: groupHasErrors(errors,
                [
                  "driving_license_exp_date",
                  'cooking_skills',
                  'ironing_skills',
                ]
              ),
            },
            {
              title: 'P. Sogg.',
              component: <VisaSection />,
              error: groupHasErrors(errors,
                [
                  'visa_release_reason',
                  "visa_expiration_date",
                  "visa_letter_send_date",
                ]
              ),
            },
            {
              title: 'Famiglia',
              component: <FamilySection />,
              error: groupHasErrors(errors,
                [
                  "relatives_attributes",
                  'family_status'
                ]
              ),
            },
            {
              title: 'Lingue',
              component: <SpokenLanguagesSection />,
              error: groupHasErrors(errors, ["spoken_languages_attributes"]),
            },
            {
              title: 'Istruzione',
              component: <EducationSection />,
              error: groupHasErrors(errors,
                [
                  "course_ids",
                  "degree_ids",
                  "certifications_other",
                ]
              ),
            },
            {
              title: 'Lavoro',
              component: <JobSection />,
              error: groupHasErrors(errors,
                [
                  "job_ids",
                  "availability_ids",
                  "esired_work_time_start",
                  "esired_work_time_end",
                  "desired_salary",
                ]
              ),
            },
            {
              title: 'Altro',
              component: <OtherSection />,
              error: groupHasErrors(errors, ["rating"]),
            }
          ]}
        ></Tabs>
        <Stack direction="row" justifyContent="flex-end" sx={{ p: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Salva
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider >
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AdvancedCandidateFormPage {...props} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
