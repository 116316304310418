import React, { useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Stack
} from '@mui/material';
import { useForm, useFieldArray, Controller, useWatch, useFormContext, FormProvider } from 'react-hook-form';
import { SortableAccordionsContainer, SortableAccordion } from './SortableAccordions'; // Assuming both files are in the same directory
import { Joi } from "../../utils/validation"
import { joiResolver } from '@hookform/resolvers/joi'

// TIP: test schemas with https://joi.dev/tester/

const schema = Joi.object({
    items: Joi.array()
        .items(
            Joi.object({
                value: Joi.string().when('_destroy', {
                    is: true,
                    then: Joi.allow('')
                }),
                _destroy: Joi.boolean()
            }).unknown()
        )
        .required(),
}).unknown() // fields not specified in this schema will be ignored by the resolver
const joiSchema = joiResolver(schema)



interface FormData {
    items: { id: string, value: string, _destroy: boolean }[];
}

const FormWithSortableAccordions: React.FC = () => {
    const form = useForm<FormData>({
        defaultValues: {
            items: [
                { id: "1", value: 'Item one', _destroy: false },
                { id: "2", value: 'Item two', _destroy: false },
                { id: "3", value: 'Item three', _destroy: false },
            ]
        },
        resolver: joiSchema
    });

    const { fields, append, prepend, move } = useFieldArray({ control: form.control, name: 'items', keyName: "form_key" });

    const onSubmit = (data: FormData) => {
        console.log(data);
    };

    const handleReorder = (oldIndex: number, newIndex: number) => {
        move(oldIndex, newIndex);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <SortableAccordionsContainer onReorder={handleReorder} itemIds={fields.map((field) => field.form_key)}>
                    {fields.map((field, index) => (
                        <AccordionWrapper key={field.id} index={index} id={field.form_key} />
                    ))}
                </SortableAccordionsContainer>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        onClick={() => prepend({ id: Date.now().toString(), value: 'Prepended', _destroy: false })}
                        sx={{ mr: 1 }}
                    >
                        Prepend item
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => append({ id: Date.now().toString(), value: 'Appended', _destroy: false })}
                        sx={{ mr: 1 }}
                    >
                        Append item
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
            </form>
        </FormProvider>
    );
};


// Wrap accordion to limit the useWatch rerender scope to this component instead of whole array
const AccordionWrapper = ({ index, id }) => {

    const { control, setValue, formState: { errors } } = useFormContext();

    const value = useWatch({ name: `items.${index}.value` });
    const _destroy = useWatch({ name: `items.${index}._destroy` });

    const setDestroyed = (destroyed: boolean) => {
        setValue(`items.${index}._destroy`, destroyed)
    }

    return (
        <SortableAccordion
            key={id}
            id={id}
            onDelete={() => setDestroyed(true)}
            onRestore={() => setDestroyed(false)}
            title={`Item with value ${value} and id ${id}`}
            isDeleted={_destroy}
            isError={!!errors?.items?.[index]}
        >
            <Stack gap={2}>
                <Controller
                    name={`items.${index}.value`}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label={`Item ${index + 1} Value`}
                            error={!!error}
                            required
                            helperText={error?.message}
                        />
                    )}
                />
                <Controller
                    name={`items.${index}._destroy`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label={`destroy?`}
                        />
                    )}
                />
            </Stack>
        </SortableAccordion>
    )
}

export default FormWithSortableAccordions;