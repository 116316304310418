import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import { Provider } from 'react-redux'
import { store } from '../../store'
import {
  useCreateReferenceMutation,
  useDestroyReferenceMutation
} from '../../services/crmApi'
import { Btn } from '../../utils/Btn'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import RapidFormBody from './rapidForm/RapidFormBody'
import useUnsavedWarning from '../../hooks/useUnsavedWarning';
import LoadingButton from '@mui/lab/LoadingButton'

const ReferenceFormPage = (props) => {
  const { redirect } = props

  const [createReference, { isLoading, isSuccess, isError }] =
    useCreateReferenceMutation()
  const [
    destroyReference,
    {
      isLoading: isLoadingDestroy,
      isSuccess: isSuccessDestroy,
      isError: isErrorDestroy
    }
  ] = useDestroyReferenceMutation()

  useEffect(() => {
    if (isSuccess || isSuccessDestroy) {
      window.onbeforeunload = null
      window.location.href = redirect
    }
  }, [isSuccess, isSuccessDestroy])

  // Warn user if there are connection errors
  useEffect(() => {
    if (isError || isErrorDestroy) {
      alert('Si è verificato un errore. Controlla di essere connesso.')
    }
  }, [isError, isErrorDestroy])

  const submitReference = (data) => {
    // Add hidden fields
    data = { ...data, employment_id: props.reference.employment_id }
    console.log(data)
    createReference(data)
  }

  return (
    <RapidFormBody defaultValues={props.reference} onSubmit={submitReference}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 2 }}
      >
        <Btn
          askConfirm
          confirmTitle="Elimina referenza"
          confirmMsg="Sei sicuro di voler cancellare questa referenza? L'azione non può essere annullata."
          onClick={() => destroyReference(props.reference)}
          loading={isLoadingDestroy}
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
        >
          Elimina
        </Btn>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          startIcon={<CheckIcon />}
        >
          Salva
        </LoadingButton>
      </Stack>
    </RapidFormBody>

  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <Provider store={store}>
    <ReferenceFormPage {...props}></ReferenceFormPage>
  </Provider>
)
