import React from 'react'
import { ICustomerAttributes } from '../../../types'
import { useForm, FormProvider } from 'react-hook-form'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BrowserRouter } from 'react-router-dom'
import setNullToEmptyString from '../../../utils/setNullToEmptyString'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import { useCreateCustomerMutation, useUpdateCustomerMutation } from '../../../services/crmApi'
import { Typography } from '@mui/material'
import useUnsavedWarning from '../../../hooks/useUnsavedWarning';
import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'

interface IProps {
  mode: "new" | "edit"
  customer: ICustomerAttributes
}

const LeadsFormPage: React.FC<IProps> = (props) => {

  const form = useForm<ICustomerAttributes>({
    defaultValues: setNullToEmptyString(props.customer),
    // resolver: validSchema,
  })

  // Choose the right mutation based on the mode
  const submitQueryHook = props.mode === "new" ? useCreateCustomerMutation : useUpdateCustomerMutation
  const [submit, { isLoading, isError, isSuccess, data }] = submitQueryHook()

  // After successful submit, follow redirect
  React.useEffect(() => {
    if (isSuccess && data) {
      window.location.href = data.redirect
    }
  }, [isSuccess, data])

  // Handle form submit errors
  React.useEffect(() => {
    if (isError) {
      alert("Errore durante il salvataggio")
    }
  }, [isError])

  useUnsavedWarning(form.formState.isDirty)


  return (
    <FormProvider {...form}>
      <Typography variant="h4" component="h1" sx={{ mb: 3 }}>
        {props.mode === "new" ? "Nuovo potenziale cliente" : "Modifica potenziale cliente"}
      </Typography>
      <form onSubmit={form.handleSubmit(submit)}>
        <Stack gap={2}>

          <Controller
            name="name"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome"
                fullWidth
              />
            )}
          />
          <Controller
            name="surname"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Cognome"
                fullWidth
              />
            )}
          />
          <Controller
            name="business_name"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Ragione sociale"
                fullWidth
              />
            )}
          />
          <Controller
            name="notes"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Note interne"
                fullWidth
                minRows={3}
                multiline
              />
            )}
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end" sx={{ pt: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Salva
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider >
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <LeadsFormPage {...props} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
