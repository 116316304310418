import React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  useCreateOrUpdateSearchMutation
} from '../../../services/crmApi'
import { useForm, Controller } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import setNullToEmptyString from '../../../utils/setNullToEmptyString'
import LoadingButton from '@mui/lab/LoadingButton'
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'
import Fetcher from '../../../utils/Fetcher'

interface Props {
}

export default function NotesDialog({ }: Props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {/* List item */}
      <ListItem disablePadding>
        <ListItemButton role={undefined} onClick={handleClickOpen}>
          <ListItemIcon>
            <TextSnippetIcon />
          </ListItemIcon>
          <Fetcher.SearchModel
            render={({ data }) => (
              <ListItemText id="toggle-fav-label" primary={data.notes || 'Nessuna nota'}
                secondary="Clicca per modificare"
              />
            )}
            loadingFallback={() => <span>Caricamento...</span>}
          />
        </ListItemButton>
      </ListItem>
      {/* Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modifica note</DialogTitle>

        <Fetcher.SearchModel
          render={({ data }) => (
            <FormDialog
              initialValues={setNullToEmptyString(data)}
              onSuccess={handleClose}
            />
          )}
          loadingFallback={() => <DialogContent>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>}
        />
      </Dialog>
    </>
  )
}

function FormDialog({
  initialValues,
  onSuccess,
}: {
  initialValues: any
  onSuccess: () => void
}) {
  const { handleSubmit, control } = useForm({ defaultValues: initialValues })

  const [triggerUpdate, { isLoading, isSuccess, isError }] =
    useCreateOrUpdateSearchMutation()

  // Handle success and error
  React.useEffect(() => {
    // Handle success
    if (isSuccess) {
      onSuccess()
      return
    }
    // Handle error
    if (isError) {
      alert('Errore durante il salvataggio delle note')
      return
    }
  }, [isSuccess, isError, onSuccess])



  return (
    <form onSubmit={handleSubmit(triggerUpdate)}>
      <DialogContent>
        <Stack gap={2} sx={{ minWidth: '500px' }}>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                label="Note"
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                {...field}
              />
            )}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            fullWidth
          >
            Salva
          </LoadingButton>
        </Stack>
      </DialogContent>
    </form>
  )
}
