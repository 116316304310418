import React, { useState, ReactNode, createContext, useContext } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    Box,
    Stack,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestoreIcon from '@mui/icons-material/Restore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableAccordionProps {
    id: string;
    title: string;
    isDeleted: boolean;
    isError: boolean;
    onDelete?: () => void;
    onRestore?: () => void;
    children: ReactNode;
    defaultExpanded?: boolean;
    sortButtons?: boolean;
}

interface SortableAccordionContextProps {
    onMoveUp: () => void;
    onMoveDown: () => void;
    isFirst?: boolean;
    isLast?: boolean;
}

const SortableAccordionContext = createContext<SortableAccordionContextProps>({
    onMoveUp: () => { },
    onMoveDown: () => { },
    isFirst: false,
    isLast: false
});

const SortableAccordion: React.FC<SortableAccordionProps> = ({
    id,
    title,
    children,
    isDeleted,
    isError,
    onDelete,
    onRestore,
    defaultExpanded = false,
    sortButtons = false
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (!isDeleted) {
            setExpanded(isExpanded);
        }
    };

    const handleButtonClick = (event: React.MouseEvent, action: () => void) => {
        event.stopPropagation();
        action();
    };

    // COnsume the context
    const { onMoveUp, onMoveDown, isFirst, isLast } = useContext(SortableAccordionContext);

    return (
        <div ref={setNodeRef} style={style}>
            <Accordion
                expanded={expanded && !isDeleted}
                onChange={handleAccordionChange}
                sx={{
                    opacity: isDeleted ? 0.5 : 1,
                    transition: 'opacity 0.3s, box-shadow 0.3s',
                    '&:hover': { opacity: isDeleted ? 0.7 : 1 },
                    ...(isError && {
                        boxShadow: '0 0 0 2px #ff6b6b',
                        backgroundColor: '#fff0f0',
                    }),
                }}
            >
                <AccordionSummary
                    expandIcon={!isDeleted && <ExpandMoreIcon />}
                    aria-controls={`panel-${id}-content`}
                    id={`panel-${id}-header`}
                >
                    <Box display="flex" alignItems="center" width="100%">
                        {sortButtons ? (
                            <Box>
                                <Tooltip title="Move Up">
                                    <span>
                                        <IconButton
                                            onClick={(e) => handleButtonClick(e, onMoveUp)}
                                            size="small"
                                            disabled={isFirst}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Move Down">
                                    <span>
                                        <IconButton
                                            onClick={(e) => handleButtonClick(e, onMoveDown)}
                                            size="small"
                                            disabled={isLast}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        ) : (
                            <IconButton
                                {...attributes}
                                {...listeners}
                                sx={{ cursor: 'grab', mr: 1 }}
                            >
                                <DragIndicatorIcon />
                            </IconButton>
                        )}
                        <Typography sx={{
                            flexGrow: 1,
                            textDecoration: isDeleted ? 'line-through' : 'none',
                            color: isError ? '#d32f2f' : 'inherit',
                        }}>
                            {title}
                        </Typography>
                        {isError && (
                            <Tooltip title="Contains errors">
                                <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
                            </Tooltip>
                        )}
                        <Tooltip title={isDeleted ? "Ripristina" : "Elimina"}>
                            <IconButton onClick={(e) => handleButtonClick(e, isDeleted ? onRestore : onDelete)} size="small">
                                {isDeleted ? <RestoreIcon /> : <DeleteIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {/*
                        Do not render content when collapsed. this is done for performance reason.
                        Note: this will break field-level form validation. It is necessary to use a form-level form validator such
                        as react-hook-form "resolver" with JoiSchema or similar
                    */}
                    {expanded && children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

interface SortableAccordionsContainerProps {
    children: React.ReactElement<SortableAccordionProps>[];
    itemIds: string[];
    onReorder: (oldIndex: number, newIndex: number) => void;
}

const SortableAccordionsContainer: React.FC<SortableAccordionsContainerProps> = ({
    children,
    onReorder,
    itemIds
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = itemIds.indexOf(active.id as string);
            const newIndex = itemIds.indexOf(over!.id as string);
            onReorder(oldIndex, newIndex);
        }
    };

    const handleMoveUp = (id: string) => {
        const index = itemIds.indexOf(id);
        if (index > 0) {
            onReorder(index, index - 1);
        }
    };

    const handleMoveDown = (id: string) => {
        const index = itemIds.indexOf(id);
        if (index < itemIds.length - 1) {
            onReorder(index, index + 1);
        }
    };

    const isFirst = (id: string) => {
        const index = itemIds.indexOf(id);
        return index === 0;
    };

    const isLast = (id: string) => {
        const index = itemIds.indexOf(id);
        return index === itemIds.length - 1;
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={itemIds}
                strategy={verticalListSortingStrategy}
            >
                <Stack gap={1} width={1}>
                    {React.Children.map(children, (child) =>
                        <SortableAccordionContext.Provider
                            value={{
                                onMoveUp: () => handleMoveUp(child.key as string),
                                onMoveDown: () => handleMoveDown(child.key as string),
                                isFirst: isFirst(child.key as string),
                                isLast: isLast(child.key as string)
                            }}
                        >
                            {child}
                        </SortableAccordionContext.Provider>
                    )}
                </Stack>
            </SortableContext>
        </DndContext>
    );
};

export { SortableAccordionsContainer, SortableAccordion };