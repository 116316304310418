import React from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import Carousel from '../../carousel/Carousel'
import DialogFiltersForm from './DialogFiltersForm'
import Fetcher from '../../../utils/Fetcher'
import SortIcon from '@mui/icons-material/Sort'
import Checkbox from '@mui/material/Checkbox'
import { ISearchAttributes } from '../../../types'
import { useSearch } from '../../searches/SearchContext'
import NotesDialog from './NotesDialog';
import ContractDialog from './ContractDialog'
import DateFilterDialog from './DateFilterDialog'
export default function SearchBar() {
  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <b>
            <Fetcher.SearchModel
              render={({ data }) => <span>{data.name}</span>}
              loadingFallback={() => <span>Ricerca</span>}
            />
          </b>
        </ListSubheader>
      }
    >
      <ListItem>
        <ListItemIcon>
          <PersonSearchIcon />
        </ListItemIcon>
        <ListItemText>
          <Fetcher.SearchResults
            render={({ data }) => <>{data.total} Risultati</>}
            loadingFallback={() => <span>Caricamento...</span>}
          />
        </ListItemText>
      </ListItem>

      <SortToggle />
      <DateFilterDialog />
      <FavOnlyToggle />
      <SaveSearchToggle />
      <ContractDialog />
      <NotesDialog />
      <ListItem sx={{ mt: 3 }}>
        <DialogFiltersForm />
      </ListItem>
      <ListItem>
        <Carousel />
      </ListItem>
    </List>
  );
}

function SortToggle(): JSX.Element {
  const {
    query: { data: search, isLoading },
    mutation: [mutate]
  } = useSearch()

  const handleToggleSort = () => {
    if (search) {
      mutate({
        ...search,
        sort: search.sort === 'by_date' ? 'by_score' : 'by_date'
      } as ISearchAttributes)
    }
  }

  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={handleToggleSort}>
        <ListItemIcon>
          <SortIcon />
        </ListItemIcon>
        <ListItemText
          id="toggle-fav-label"
          primary={
            isLoading
              ? 'Caricamento ...'
              : search.sort === 'by_date'
                ? 'Dal più recente'
                : 'Dal più pertinente'
          }
          secondary="Clicca per modificare"
        />
      </ListItemButton>
    </ListItem>
  )
}

function FavOnlyToggle(): JSX.Element {
  const {
    query: { data: search },
    mutation: [mutate]
  } = useSearch()

  const handleToggleFavOnly = () => {
    if (search) {
      mutate({
        ...search,
        is_fav_only: !search.is_fav_only
      } as ISearchAttributes)
    }
  }

  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={handleToggleFavOnly}>
        {search && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={search.is_fav_only}
              disableRipple
              inputProps={{ 'aria-labelledby': 'toggle-fav-label' }}
            />
          </ListItemIcon>
        )}
        <ListItemText id="toggle-fav-label" primary="Mostra solo preferiti"

        />
      </ListItemButton>
    </ListItem>
  )
}


function SaveSearchToggle(): JSX.Element {
  const {
    query: { data: search },
    mutation: [mutate]
  } = useSearch()

  const handleToggle = () => {
    if (search) {
      mutate({
        ...search,
        saved: !search.saved
      } as ISearchAttributes)
    }
  }

  return (
    <ListItem disablePadding>
      <ListItemButton role={undefined} onClick={handleToggle}>
        {search && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={search.saved}
              disableRipple
              inputProps={{ 'aria-labelledby': 'toggle-saved-label' }}
            />
          </ListItemIcon>
        )}
        <ListItemText id="toggle-saved-label" primary="Salva in elenco ricerche"

        />
      </ListItemButton>
    </ListItem>
  )
}
