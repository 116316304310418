import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  ICandidateAttributes
} from '../../../../../types'
import {
  FormControlLabel
} from '@mui/material'

import Select from '../../../../input/select/Select'
import { Checkbox } from '@mui/material'
import DateInput from '../../../../input/DateInput'

const VisaSection: React.FC<{}> = ({ }) => {
  const form = useFormContext<ICandidateAttributes>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Documenti</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={<Checkbox />}
          label="Possiede P. Sogggiorno"
          {...form.register('visa_presence')}
          checked={form.watch('visa_presence')}
        />
      </Grid>

      {form.watch('visa_presence') &&
        <>

          {/* VISA release reason */}
          <Grid item xs={12} sm={6}>
            <Controller
              name='visa_release_reason'
              control={form.control}
              render={({ field }) => (
                <Select
                  label="Motivo rilascio"
                  // TODO: this enum is duplicated in the frontend.Move it to the backend
                  options={[
                    { label: 'Studio', value: 'study' },
                    { label: 'Lavoro', value: 'work' },
                    { label: 'Turismo', value: 'tourism' },
                    { label: 'Cure mediche', value: 'medical' },
                    { label: 'Attesa occupazione', value: 'unemployment' },
                    { label: 'Motivi familiari', value: 'family' },
                    { label: 'Minore età', value: 'minor' },
                    { label: 'Asilo politico', value: 'asylum' },
                    { label: 'Altro', value: 'other' },
                  ]}
                  {...field}
                  size="small"
                  fullWidth
                // required
                // error={!!form.formState.errors?.visa_release_reason}
                // helperText={form.formState.errors?.visa_release_reason?.message}
                />
              )}
            />
          </Grid>

          {/* Is VISA unlimited? */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox />}
              label="P. Sogg. Illimitato"
              {...form.register('is_visa_unlimited')}
              checked={form.watch('is_visa_unlimited')}
            />
          </Grid>

          {!form.watch('is_visa_unlimited') &&
            <>

              {/* VISA expiration date */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="visa_expiration_date"
                  control={form.control}
                  rules={{
                    // Check date year is between 1900 and 2100
                    validate: (val) => {
                      const date = new Date(val)
                      if (date.getFullYear() > 1900 && date.getFullYear() < 2100) {
                        return true
                      } else {
                        return 'La data deve essere compresa tra il 1900 e il 2100'
                      }
                    }
                  }}
                  render={({ field }) => (
                    <DateInput
                      label="Scadenza"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!form.formState.errors?.visa_expiration_date,
                          helperText: form.formState.errors?.visa_expiration_date?.message,
                          size: 'small',
                          sx: { mb: 1 }
                        }
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>

              {/* Renew letter sent? */}
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Raccomandata rinnovo inviata"
                  {...form.register('is_visa_letter_sent')}
                  checked={form.watch('is_visa_letter_sent')}
                />
              </Grid>

              {/* Renew letter sentd date */}
              {form.watch('is_visa_letter_sent') &&
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="visa_letter_send_date"
                    control={form.control}
                    rules={{
                      // Check date year is between 1900 and 2100
                      validate: (val) => {
                        const date = new Date(val)
                        if (date.getFullYear() > 1900 && date.getFullYear() < 2100) {
                          return true
                        } else {
                          return 'La data deve essere compresa tra il 1900 e il 2100'
                        }
                      }
                    }}
                    render={({ field }) => (
                      <DateInput
                        label="Data invio raccomandata"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!form.formState.errors?.visa_letter_send_date,
                            helperText: form.formState.errors?.visa_letter_send_date?.message,
                            size: 'small',
                            sx: { mb: 1 }
                          }
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>}
            </>
          }
        </>
      }


    </Grid>
  )
}

export default VisaSection
