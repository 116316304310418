import ReactOnRails from 'react-on-rails'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import CandidatesIndex from '../bundles/client/components/candidates/index/index'
import FileUpload from '../bundles/client/components/cvs/FileUpload'
import ProfilePicturePage from '../bundles/client/components/candidates/profilePicture/ProfilePicturePage'
import FileList from '../bundles/client/components/cvs/FileList'
import ReferenceFormPage from '../bundles/client/components/references/ReferenceFormPage'
import ReferenceRapidFormBody from '../bundles/client/components/references/rapidForm/RapidFormBody'
import ReactTestPage from '../bundles/client/components/developer/ReactTestPage'
import ContractForm from '../bundles/client/components/contract/ContractForm'
import AdvancedCandidateFormPage from '../bundles/client/components/candidates/edit/AdvancedCandidateFormPage'
import PendingIndexPage from '../bundles/client/components/candidates/pending/PendingIndexPage'
import LeadsFormPage from '../bundles/client/components/customers/leads/LeadsFormPage'
import LeadsIndexPage from '../bundles/client/components/customers/leads/LeadsIndexPage'
import UploadPdfAttachmPage from '../bundles/client/components/cvs/UploadPdfAttachmPage'
import ContractAttachmentList from '../bundles/client/components/contract/ContractAttachmentList'

// Load sentry for error tracking and performance monitoring in the client
if (process.env.SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: 'https://94d87a1d03e545418e4b25ced7946236@o1248657.ingest.sentry.io/4504445751328768',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV
  })
}

// Register component for rails view helper
ReactOnRails.register({
  ReferenceFormPage,
  ReferenceRapidFormBody,
  FileList,
  AdvancedCandidateFormPage,
  CandidatesIndex,
  ReactTestPage,
  ContractForm,
  FileUpload,
  ProfilePicturePage,
  PendingIndexPage,
  LeadsFormPage,
  LeadsIndexPage,
  UploadPdfAttachmPage,
  ContractAttachmentList
})
