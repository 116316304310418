import React, { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import { ISearchCandidateResponse } from '../../../types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useSearchParams } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import ListItemText from '@mui/material/ListItemText'
import Fetcher from '../../../utils/Fetcher'
import { EmptyResultsPlaceholder } from '../index/tabpanels/EmptyResultsPlaceholder'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { useUpdateCandidateMutation } from '../../../services/crmApi'
import LoadingButton from '@mui/lab/LoadingButton'
import { CANDIDATE_VISIBLE_STEPS } from '../../../enums'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';

interface Props {
    data?: ISearchCandidateResponse,
}

const PendingCandidatesTable: React.FC<Props> = ({ data }) => {


    return data ? (
        // No results
        data.results.length === 0 ? (
            <EmptyResultsPlaceholder />
        ) : (
            // Results found
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell align="right">Ultima modifica</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRows data={data} />
                    </TableBody>
                </Table>
            </TableContainer>
        )
    ) : (
        // Results are not loaded yet
        <Stack
            sx={{ height: '300px', textAlign: 'center', py: 5, px: 3 }}
            alignItems="center"
            justifyContent="center"
            gap={2}
        >
            <HourglassEmptyOutlinedIcon fontSize="large" />
            <Typography variant="h6">Caricamento...</Typography>
        </Stack>
    )
}

function TableRows({ data }: { data: ISearchCandidateResponse }) {
    const [openId, setOpenId] = React.useState(null)

    return (
        <>
            {data.results.map((r) => (
                <Row
                    result={r}
                    key={r.id}
                    open={r.id === openId}
                    onClick={() => setOpenId(r.id === openId ? null : r.id)}
                />
            ))}
        </>
    )
}

interface IRowProps {
    result: ISearchCandidateResponse['results'][0]
    open: boolean
    onClick: () => void
}
function Row({ result, open, onClick }: IRowProps): JSX.Element {

    const [updateCandidate, { isError, isSuccess, isLoading }] = useUpdateCandidateMutation()

    const handleNextStep = () => {
        // Get next step from CANDIDATE_VISIBLE_STEPS
        const currStepIndex = CANDIDATE_VISIBLE_STEPS.findIndex(step => step.value === result.step)
        const nextStep = CANDIDATE_VISIBLE_STEPS[currStepIndex + 1]

        updateCandidate({
            id: result.id,
            step: nextStep.value.toString()
        })
    }

    // // Show error / success message
    // useEffect(() => {
    //     if (isError) {
    //         //@ts-ignore-next-line
    //         alert("Errore durante il salvataggio")
    //     }
    //     if (isSuccess) {
    //         //@ts-ignore-next-line
    //         alert("Candidato aggiornato")
    //     }
    // }
    //     , [isError, isSuccess])


    return (
        <>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td': { borderBottom: 'none' }
                }}
                onClick={onClick}
            >
                <TableCell>
                    <ListItemText
                        primary={result.name}
                        secondary={
                            <Typography variant="body2">{result.surname}</Typography>
                        }
                    />
                </TableCell>
                <TableCell align="right">
                    <Typography variant="body2">
                        {new Date(result.updated_at).toLocaleDateString('it-IT', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit'
                        })}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
                    colSpan={2}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ mt: 1, mb: 5 }}>
                            <Stack direction={{ md: 'row' }} gap={1} sx={{ mb: 2 }}>
                                <Button
                                    variant="contained"
                                    component="a"
                                    href={`/candidates/${result.id}`}
                                    sx={{ flexGrow: 1 }}
                                    startIcon={<AccountBoxOutlinedIcon />}
                                >
                                    Vedi profilo
                                </Button>
                                <Button
                                    variant="contained"
                                    component="a"
                                    href={`/candidates/${result.id}/edit_advanced`}
                                    sx={{ flexGrow: 1 }}
                                    startIcon={<EditOutlinedIcon />}
                                >
                                    Modifica
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    sx={{ flexGrow: 1 }}
                                    color="warning"
                                    loading={isLoading}
                                    onClick={() => handleNextStep()}
                                    startIcon={<SkipNextOutlinedIcon />}
                                >
                                    Next step
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export { PendingCandidatesTable }
