import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  ICandidateAttributes
} from '../../../../../types'
import {
  FormControlLabel
} from '@mui/material'

import { REF } from '../../../../../enums'
import Select from '../../../../input/select/Select'
import { Checkbox } from '@mui/material'
import DateInput from '../../../../input/DateInput'

const CharacteristicsSection: React.FC<{}> = ({ }) => {
  const form = useFormContext<ICandidateAttributes>()



  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Caratteristiche</Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={<Checkbox />}
          label="Possiede patente"
          {...form.register('driving_license')}
          checked={form.watch('driving_license')}
        />
      </Grid>
      {form.watch('driving_license') &&
        <Grid item xs={12} md={6}>
          <Controller
            name="driving_license_exp_date"
            control={form.control}
            render={({ field }) => (
              <DateInput
                label="Scad. patente"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!form.formState.errors?.driving_license_exp_date,
                    helperText: form.formState.errors?.driving_license_exp_date?.message,
                    size: 'small',
                    sx: { mb: 1 }
                  }
                }}
                {...field}
              />
            )}
          />
        </Grid>}
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Possiede auto"
          {...form.register('car')}
          checked={form.watch('car')}
        />
      </Grid>
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Allergia gatto"
          {...form.register('allergy_cat')}
          checked={form.watch('allergy_cat')}
        />
      </Grid>
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Allergia cane"
          {...form.register('allergy_dog')}
          checked={form.watch('allergy_dog')}
        />
      </Grid>
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Fumatore"
          {...form.register('smoker')}
          checked={form.watch('smoker')}
        />
      </Grid>
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Sa nuotare"
          {...form.register('swim')}
          checked={form.watch('swim')}
        />
      </Grid>
      <Grid item xs={12}>

        <FormControlLabel
          control={<Checkbox />}
          label="Rifiuta corso ET"
          {...form.register('refuses_et')}
          checked={form.watch('refuses_et')}
        />
      </Grid>


      <Grid item xs={12} sm={6}>
        <Controller
          name='cooking_skills'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Liv. cucina"
              options={REF.cooking_skills}
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name='ironing_skills'
          control={form.control}
          render={({ field }) => (
            <Select
              size="small"
              label="Liv. stiro"
              options={REF.ironing_skills}
              {...field}
              fullWidth
            />
          )}
        />
      </Grid>



    </Grid>
  )
}

export default CharacteristicsSection
