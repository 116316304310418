import React, { useCallback, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@mui/material/Unstable_Grid2'
import {
  IEmploymentAttributes,
  INestedArrayAttributes
} from '../../../../../../types'
import {
  FormControlLabel
} from '@mui/material'
import {
  useGetAvailabilitiesQuery,
  useGetJobsQuery,
  useGetContractByIdQuery,
  useLazyGetContractsQuery,
} from '../../../../../../services/crmApi'
import FetchSelectMulti from '../../../../../input/select/FetchSelectMulti'
import FetchSelect from '../../../../../input/select/FetchSelect'
import { ControlledTelInput } from '../../../../../input/TelInput'
import { REF } from '../../../../../../enums'
import Select from '../../../../../input/select/Select'
import { Checkbox } from '@mui/material'
import SearchSelect from '../../../../../input/select/SearchSelect'
import ControlledTextField from '../../../../../input/text/ControlledTextField';
import ControlledSelect from '../../../../../input/select/ControlledSelect'

const EmploymentFormFields: React.FC<{
  index: number
}> = React.memo((props) => {
  const { register, watch,
    control, setValue, formState
  } = useFormContext()

  // 70 years ago to this year
  const yearsOptions = useMemo(() => Array.from(Array(70).keys()).map((val) => ({
    label: (new Date().getFullYear() - val).toString(),
    value: (new Date().getFullYear() - val).toString()
  })), [])

  // 24 hours
  const hoursOptions = useMemo(() => Array.from(Array(24), (_, i) => i).map((i) => ({ label: i.toString(), value: i.toString() })), [])

  // 100 - 6000€
  const salaryOptions = useMemo(() => Array.from(Array(60), (_, i) => (i + 1) * 100).map((i) => ({ label: `${i} €`, value: i.toString() })), [])


  const employmentFieldKey = `employments_attributes.${props.index}`

  const employmentErrors =
    formState.errors?.employments_attributes?.[props.index.toString()]

  // On contract change update job_ids and availability_ids
  const { data: contract } = useGetContractByIdQuery(watch(`${employmentFieldKey}.contract_id`))
  const updateContractDetails = useCallback(() => {
    if (contract) {
      if (watch(`${employmentFieldKey}.job_ids`).length === 0) {
        setValue(`${employmentFieldKey}.job_ids`, contract.master_search.job_ids)
      }
      if (watch(`${employmentFieldKey}.availability_id`) === "") {
        setValue(`${employmentFieldKey}.availability_id`, contract.master_search.availability_ids?.[0] || "")
      }
      if (watch(`${employmentFieldKey}.family_name`) === "") {
        setValue(`${employmentFieldKey}.family_name`, contract.customer.full_name)
      }
      if (watch(`${employmentFieldKey}.contract.name_with_date`) === "") {
        setValue(`${employmentFieldKey}.contract.name_with_date`, contract.name_with_date)
      }
    }
  }, [contract, watch, setValue, employmentFieldKey])

  React.useEffect(() => {
    updateContractDetails()
  }, [updateContractDetails])


  return (
    <Grid container spacing={1.3}>
      <Grid xs={12}>
        <SearchSelect
          label="Contratto associato"
          valueFieldName={`${employmentFieldKey}.contract_id`}
          labelFieldName={`${employmentFieldKey}.contract.name_with_date`}
          control={control}
          setValue={setValue}
          lazyQuery={useLazyGetContractsQuery}
          inputProps={{ size: 'small' }}
        />
      </Grid>
      {/* Data inizio */}
      <Grid xs={6} sm={3}>
        <ControlledSelect
          name={`${employmentFieldKey}.started_at`}
          label="Data inizio"
          options={yearsOptions}
          size="small"
          error={!!employmentErrors?.started_at}
          helperText={employmentErrors?.started_at?.message}
          fullWidth
          required
        />
      </Grid>
      {/* Data fine */}
      <Grid xs={6} sm={3}>
        <ControlledSelect
          name={`${employmentFieldKey}.ended_at`}
          fullWidth
          label="Data fine"
          options={yearsOptions}
          size="small"
          error={!!employmentErrors?.ended_at}
          helperText={
            employmentErrors?.ended_at?.message ||
            "Lascia vuoto se ancora in corso"
          }
        />
      </Grid>
      {/* Nome datore di lavoro */}
      <Grid xs={12} sm={6}>
        <ControlledTextField
          name={`${employmentFieldKey}.family_name`}
          label="Nome datore di lavoro"
          size="small"
          required
          placeholder="Famiglia Berlusconi"
          error={!!employmentErrors?.family_name}
          helperText={employmentErrors?.family_name?.message} />
      </Grid>
      {/* Mansioni */}
      <Grid xs={12} sm={6}>
        <Controller
          name={`${employmentFieldKey}.job_ids`}
          control={control}
          render={({ field }) => (
            <FetchSelectMulti
              label="Mansioni"
              options={useGetJobsQuery}
              fullWidth size="small"
              error={!!employmentErrors?.job_ids}
              helperText={!!employmentErrors?.job_ids && "Devi selezionare almeno una mansione, o compilare il campo 'Mansioni (altro)"}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Mansioni (altro) */}
      <Grid xs={12} sm={6}>
        <ControlledTextField name={`${employmentFieldKey}.job_other`} label="Mansioni (altro)" size="small" placeholder="Mansioni non presenti nella lista" />
      </Grid>
      {/* Disponibilità */}
      <Grid xs={12} sm={6}>
        <Controller
          name={`${employmentFieldKey}.availability_id`}
          control={control}
          render={({ field }) => (
            <FetchSelect
              label="Disponibilità"
              options={useGetAvailabilitiesQuery}
              size="small"
              fullWidth
              required
              error={!!employmentErrors?.availability_id}
              helperText={!!employmentErrors?.availability_id && "Devi selezionare una disponibilità"}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Dalle ore */}
      <Grid xs={6} sm={3}>
        <ControlledSelect
          name={`${employmentFieldKey}.work_time_start`}
          label="Ora inizio"
          fullWidth
          allowEmpty
          options={hoursOptions}
          size="small"
        />
      </Grid>
      {/* Alle ore */}
      <Grid xs={6} sm={3}>
        <Controller
          name={`${employmentFieldKey}.work_time_end`}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Ora fine"
              fullWidth
              options={hoursOptions}
              size="small"
            />
          )}
        />
      </Grid>
      {/* Tel Referente */}
      <Grid xs={12} sm={6}>
        <ControlledTelInput
          controllerProps={{
            name: `${employmentFieldKey}.ref_phone`,
            control: control
          }}
          telInputRenderProps={({ field }) => ({
            label: 'Tel referente',
            size: 'small'
          })}
        />
      </Grid>
      {/* Nome referente */}
      <Grid xs={12} sm={6}>
        <ControlledTextField name={`${employmentFieldKey}.ref_name`} label="Nome referente" size="small" />
      </Grid>
      {/* Stipendio */}
      <Grid xs={3}>
        <ControlledSelect
          name={`${employmentFieldKey}.salary`}
          label="Stipendio"
          fullWidth
          options={salaryOptions}
          error={!!formState.errors?.desired_salary}
          size="small"
        />
      </Grid>
      {/* Perchè è finita */}
      <Grid xs={9}>
        <ControlledSelect name={`${employmentFieldKey}.end_reason`} label="Perchè è finita" options={REF.end_reason} size="small" fullWidth />
      </Grid>
      {/* note interne */}
      <Grid xs={12}>
        <ControlledTextField name={`${employmentFieldKey}.notes`} multiline minRows={1} label="Note interne" size="small" placeholder="Scrivi qui le tue note private. Non saranno visibili nel CV generato." />
      </Grid>
      {/* note pubbliche */}
      <Grid xs={12}>
        <ControlledTextField name={`${employmentFieldKey}.notes_public`} multiline minRows={1} label="Note pubbliche" size="small" placeholder="Scrivi qui le note pubbliche. Saranno visibili nel CV generato." />
      </Grid>
      {/* Checkboxes */}
      <Grid xs={12}>
        <FormControlLabel
          control={<Checkbox />}
          label="Mostra nel CV pubblico"
          {...register(`${employmentFieldKey}.show_in_cv`)}
          checked={watch(`${employmentFieldKey}.show_in_cv`)}
        />
      </Grid>
    </Grid>
  )
})


export default EmploymentFormFields;