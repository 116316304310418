import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Select from '../../../input/select/Select'
import SearchSelect from '../../../input/select/SearchSelect'
import { todayDate } from '../../../../utils/date'
import { CONTRACT_STATUS_OPTIONS } from '../../../../enums'
import { useLazyGetCustomerAutocompleteQuery } from '../../../../services/crmApi'

function BaseData() {
  const {
    formState: { errors },
    control,
    setValue
  } = useFormContext()

  return (
    <Stack gap={2}>
      <SearchSelect
        label="Cliente"
        valueFieldName="customer_id"
        labelFieldName="customer.full_name"
        control={control}
        setValue={setValue}
        lazyQuery={useLazyGetCustomerAutocompleteQuery}
        inputProps={{
          required: true,
          //@ts-ignore
          helperText: errors.customer?.full_name?.message,
          //@ts-ignore
          error: !!errors.customer?.full_name
        }}
      />
      <Controller
        name={`status`}
        control={control}
        render={({ field }) => (
          <Select
            label="Stato"
            // CONTRACT_STATUS_OPTIONS except "sent"
            options={CONTRACT_STATUS_OPTIONS.filter(
              (opt) => opt.value !== 'sent'
            )}
            fullWidth
            required
            error={!!errors.status}
            helperText={errors.status && errors.status?.message.toString()}
            {...field}
          />
        )}
      />
      <Controller
        name="signed_at"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Firmato il"
            fullWidth
            placeholder="GG/MM/AAAA"
            error={!!errors.signed_at}
            helperText={
              errors.signed_at && (errors?.signed_at?.message as string)
            }
          />
        )}
      />
      {/* On click, set signed_at date to today in format DD/MM/YYYY */}
      <Link
        component="button"
        type="button"
        align="right"
        onClick={() => setValue('signed_at', todayDate())}
      >
        Usa data odierna
      </Link>
      <Controller
        name="notes"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Note pubbliche"
            multiline
            minRows={3}
            fullWidth
          />
        )}
      />
    </Stack>
  )
}

export default BaseData
