import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useForm, useFieldArray, Controller, set } from 'react-hook-form'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";


const autocompleteService = { current: null };

export default function GoogleMaps({ control, defaultInputValue, fullWidth, onPlaceChange, ...props }) {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleInputChange = (e, newInputValue) => {
    setValue(newInputValue);
  };

  // const handleSelect = ({ description }) =>
  //   () => {
  //     setValue(description, false);
  //     clearSuggestions();

  //     // Get latitude and longitude via utility functions
  //     getGeocode({ address: description })
  //       .then(results => getLatLng(results[0]))
  //       .then(({ lat, lng }) => onSelect({ lat, lng }))
  //       .catch(error => console.log('😱 Error: ', error));
  //   };

  return (
    <Controller
      control={control}
      {...props}
      render={({ field }) =>
        <Autocomplete
          {...field}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
          filterOptions={(x) => x}
          options={data}
          freeSolo
          noOptionsText="Nessun risultato. Digita un indirizzo valido."
          onChange={(event, newValue) => {
            // User selected a location from the dropdown OR cleared
            // the input with the clear button or backspace
            // New value is an object with place metadata or null or empty string
            // Update geocomplete hook
            setValue(newValue?.description || "", false);
            clearSuggestions();
            // give a string value to the controller
            field.onChange(newValue?.description || "")
            // Call the callback function passed as prop
            onPlaceChange(newValue);
          }}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField {...params} label="Indirizzo" />
          )}
        />}
    />
  );
}