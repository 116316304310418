import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import SelectChip from '../../../input/select/SelectMulti'
import Stack from '@mui/material/Stack'
import {
  useGetAvailabilitiesQuery,
  useGetJobsQuery
} from '../../../../services/crmApi'

interface Props {
  subformPrefix?: string
}
function Job({ subformPrefix = '' }: Props) {
  const { data: jobsOptions } = useGetJobsQuery({})
  const { data: availabilitiesOptions } = useGetAvailabilitiesQuery({})
  const { control } = useFormContext()

  const addPrefix = (name: string) => {
    return subformPrefix + name
  }

  return (
    <Stack gap={2}>
      {jobsOptions && (
        <Controller
          name={addPrefix('job_ids')}
          control={control}
          render={({ field }) => {
            return (
              <SelectChip
                {...field}
                label="Mansioni"
                options={jobsOptions}
                fullWidth
              />
            )
          }}
        />
      )}
      {availabilitiesOptions && (
        <Controller
          name={addPrefix('availability_ids')}
          control={control}
          render={({ field }) => (
            <SelectChip
              label="Disponibilità"
              options={availabilitiesOptions}
              fullWidth
              {...field}
            />
          )}
        />
      )}
    </Stack>
  )
}

export default Job
