import joiCore from 'joi'
import joiDate from '@joi/date'
import { FieldErrorsImpl } from 'react-hook-form'

const Joi = (joiCore.extend(joiDate) as typeof joiCore).defaults((schema) => {
  return schema.options({
    abortEarly: false,
    messages: {
      'any.required': 'Campo obbligatorio',
      'base.default': 'Campo obbligatorio',
      'string.empty': 'Campo obbligatorio',
      'object.base': 'Campo obbligatorio',
      'date.base': 'Data non valida',
      'date.format': 'La data deve essere nel formato GG/MM/AAAA',
      'date.min': 'Data troppo vecchia',
      'date.max': 'Data troppo futura'
    }
  })
})

const groupHasErrors = (
  errors: Partial<FieldErrorsImpl<any>>,
  fieldNames: string[]
) => fieldNames.some((fieldName) => errors[fieldName])

export { Joi, groupHasErrors }
