import React from 'react'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { REF } from '../../../../enums'

const FormSection = () => (
  <RapidFormSection.ChipSection
    title="Referente"
    bgColor="#8E6B8E"
    fields={[
      {
        name: 'gender',
        label: '♀️ Sesso',
        options: REF.gender,
        description: 'Sesso della persona che rilascia la referenza'
      },
      {
        name: 'weight',
        label: '📶 Affidabilità',
        options: REF.weight,
        description:
          'Peso della referenza. Più è affidabile, più alto sarà il peso di questa referenza'
      },
      {
        name: 'satisfaction',
        label: '🤗 Liv. soddifazione',
        options: REF.satisfaction,
        description:
          'Grado di soddisfazione della persona che lascia la referenza'
      }
    ]}
  />
)

export default FormSection
