import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import setNullToEmptyString from '../../utils/setNullToEmptyString'
import Stack from '@mui/material/Stack'
import Tabs from '../../utils/Tabs'
import { useCreateOrUpdateContractMutation } from '../../services/crmApi'
import FiltersForm from '../searches/form/FiltersForm'
import LoadingButton from '@mui/lab/LoadingButton'
import validSchema from './joiSchema'
import { groupHasErrors } from '../../utils/validation'
import BaseData from './form/sections/BaseData'

interface Props {
  contract: any
  masterSearch: any
  redirect: string
  // back: string
}

function ContractBaseSearch({
  contract,
  masterSearch,
  redirect,
  ...rest
}: Props): JSX.Element {
  contract = setNullToEmptyString(contract)
  masterSearch = setNullToEmptyString(masterSearch)

  contract['master_search_attributes'] = masterSearch

  return (
    <Stack gap={4}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href={back}
        >
          <ArrowBackIosNewIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Torna al contratto
        </Link>
      </Breadcrumbs> */}

      {/* <Typography variant="h4" component="h1" gutterBottom>
        Modifica richieste cliente
      </Typography> */}
      <ContractForm
        defaultValues={contract}
        // onMutationSuccess={handleMutationSuccess}
        // showSettings={false}
      />
    </Stack>
  )
}
interface ContractFormProps {
  defaultValues: any
}

function ContractForm({ defaultValues }: ContractFormProps): JSX.Element {
  const [createOrUpdate, { data, isSuccess, isLoading, isError }] =
    useCreateOrUpdateContractMutation()

  const form = useForm({ defaultValues, resolver: validSchema })
  const {
    handleSubmit,
    formState: { isDirty, errors }
  } = form

  React.useEffect(() => {
    if (isSuccess && data) {
      window.onbeforeunload = null
      window.location.href = data.redirect
    } else if (isError) {
      alert("Errore durante l'invio. Controlla di essere connesso e riprova.")
    }
  }, [isSuccess, data, isError])

  React.useEffect(() => {
    window.onbeforeunload = isDirty
      ? () => 'Ci sono modifiche non salvate.'
      : null
  }, [isDirty])

  React.useEffect(() => {
    // errors is an object with a key for each field
    // field has errors if it has a messages array
    // if there are errors, show an alert
    const hasErrors = Object.entries(errors).some(([k, v]) => v?.message)
    if (hasErrors) {
      alert('Ci sono errori nel form. Controlla i campi evidenziati in rosso.')
    }
  }, [errors])

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(createOrUpdate)}>
        <Tabs
          sections={[
            {
              title: 'Dati base',
              component: <BaseData />,
              error: groupHasErrors(errors, ['customer', 'status', 'signed_at'])
            },
            {
              title: 'Anagrafica',
              component: (
                <FiltersForm.Anagraphic
                  subformPrefix="master_search_attributes."
                  viewMode="contract"
                />
              )
            },
            // {
            //   title: 'Lingue',
            //   component: //     <FiltersForm.Location subformPrefix="master_search_attributes." />
            //   ),
            //   error: groupHasErrors(errors, ['customer', 'status', 'signed_at'])
            // },
            {
              title: 'Mansioni',
              component: (
                <FiltersForm.Job subformPrefix="master_search_attributes." />
              )
              // error: groupHasErrors(errors, ['customer', 'status', 'signed_at'])
            },
            {
              title: 'Personalità',
              component: (
                <FiltersForm.PersonalityScores subformPrefix="master_search_attributes." />
              )
            },
            // {
            //   title: 'Posizione',
            //   component: //     <FiltersForm.Location subformPrefix="master_search_attributes." />
            //   ),
            //   error: groupHasErrors(FiltersForm.Location.FIELD_NAMES)
            // },
            {
              title: 'Colf',
              component: (
                <FiltersForm.ColfScores subformPrefix="master_search_attributes." />
              )
            },
            {
              title: 'Badante',
              component: (
                <FiltersForm.CaretakerScores subformPrefix="master_search_attributes." />
              )
            },
            {
              title: 'Tata',
              component: (
                <FiltersForm.NannyScores subformPrefix="master_search_attributes." />
              )
            }
          ]}
        ></Tabs>
        <Stack direction="row" justifyContent="flex-end" sx={{ p: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Salva
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ContractBaseSearch {...props} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
