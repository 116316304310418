import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { default as MuiSelect } from '@mui/material/Select'
import { nanoid } from 'nanoid'
import { ISelectOption } from '../../../types'

export interface SelectProps extends React.ComponentProps<typeof MuiSelect> {
  required?: boolean
  label: string
  options: ISelectOption[]
  fullWidth?: boolean
  value: string | number | boolean
  allowEmpty?: boolean
  helperText?: string
  error?: boolean
  favourites?: (string | number | boolean)[],
}

const Select: React.FC<SelectProps> = React.forwardRef<
  HTMLInputElement,
  SelectProps
>(
  (
    {
      required,
      label,
      options,
      fullWidth,
      value,
      allowEmpty = true,
      helperText,
      error,
      favourites = [],
      size,
      ...props
    },
    ref
  ) => {
    const fieldId = nanoid()


    // Sort options by favourites
    const isFav = (opt: ISelectOption) => favourites.includes(opt.value)
    const favSort = (a: ISelectOption, b: ISelectOption) => {
      return isFav(a) && isFav(b) ? 0 : isFav(a) ? -1 : isFav(b) ? 1 : 0
    }

    const favouritesOpts = options.slice().filter(isFav).sort(favSort)
    const otherOpts = options.slice().filter((opt) => !isFav(opt))

    return (
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        error={error}
        size={size}
      >
        <InputLabel id={`select-${fieldId}-label`}>{label}</InputLabel>
        <MuiSelect
          labelId={`select-${fieldId}-label`}
          label={label}
          value={value || ''}
          ref={ref}
          {...props}
        >
          {allowEmpty &&
            <MenuItem value="" key="emptyval">
              <em>Nessuna selezione</em>
            </MenuItem>
          }
          {/* Favourites */}
          {favouritesOpts.length > 0 && <MenuItem disabled>--- Preferiti---</MenuItem>}
          {favouritesOpts.map((option: ISelectOption) => (
            <MenuItem key={option.value.toString()} value={option.value as any}> {/* Type does not allow boolean, but it works */}
              {option.label}
            </MenuItem>
          ))}
          {/* Other options */}
          {favouritesOpts.length > 0 && <MenuItem disabled>--- Altro ---</MenuItem>}

          {otherOpts.map((option: ISelectOption) => (
            <MenuItem key={option.value.toString()} value={option.value as any}> {/* Type does not allow boolean, but it works */}
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
)

export default Select
