import React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { ISelectOption } from '../../../types'
import FormHelperText from '@mui/material/FormHelperText'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles(
  value: (string | number | boolean),
  selectedValues: (string | number | boolean)[],
  theme: Theme
) {
  return {
    fontWeight:
      selectedValues.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export interface SelectMultiProps extends React.ComponentProps<typeof Select> {
  label: string
  value: (string | number | boolean)[]
  options: ISelectOption[]
  onChange: (event) => void
  favourites?: (string | number | boolean)[]
  fullWidth: boolean
  helperText?: string,
}

const SelectMulti = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      favourites = [],
      fullWidth,
      helperText,
      size,
      ...props
    }: SelectMultiProps,
    ref
  ) => {
    const theme = useTheme()

    // Sort options by favourites
    const isFav = (opt: ISelectOption) => favourites.includes(opt.value)
    const favSort = (a: ISelectOption, b: ISelectOption) => {
      return isFav(a) && isFav(b) ? 0 : isFav(a) ? -1 : isFav(b) ? 1 : 0
    }

    const { options } = props
    // const options: ISelectOption[] = props.options.map((opt) => ({
    //   ...opt,
    //   value: opt.value.toString()
    // }))

    const favouritesOpts = options.slice().filter(isFav).sort(favSort)
    const otherOpts = options.slice().filter((opt) => !isFav(opt))


    const labelId = `select-chip-multi-${label}`


    const renderSelectedOpts = (selected: (string | number | boolean)[]) => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map((value) => (
          <Chip
            key={`chip-${value}`}
            label={options.find((o) => o.value === value)?.label}
            size="small"
          />
        ))}
      </Box>
    )

    const renderOpt = (opt: ISelectOption) => (
      <MenuItem
        key={`menuitem-${opt.value}`}
        value={opt.value as any} // Type does not allow boolean, but it works
        style={getStyles(opt.value, value, theme)}
      >
        {opt.label}
      </MenuItem>
    )

    return (
      <FormControl fullWidth={fullWidth} size={size}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          {...props}
          labelId={labelId}
          multiple
          value={value}
          onChange={onChange}
          input={<OutlinedInput label={label} />}
          renderValue={renderSelectedOpts}
          MenuProps={MenuProps}
        >
          {favouritesOpts.length > 0 && <MenuItem disabled>--- Preferiti---</MenuItem>}
          {favouritesOpts.map(renderOpt)}
          {/* Other options */}
          {favouritesOpts.length > 0 && <MenuItem disabled>--- Altro ---</MenuItem>}
          {otherOpts.map(renderOpt)}
        </Select>
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      </FormControl>
    )
  }
)

export default SelectMulti
