import { Joi } from '../../../utils/validation'
import { joiResolver } from '@hookform/resolvers/joi'

// TIP: test schemas with https://joi.dev/tester/

const schema = Joi.object({
  name: Joi.string().required(),
  surname: Joi.string().required(),
  birthdate: Joi.date().required().min('1900-01-01').max('2060-01-01'),
  gender: Joi.string().required(),

  notes_attributes: Joi.array()
    .items(
      Joi.object({
        text: Joi.string().when('_destroy', {
          is: true,
          then: Joi.allow('')
        }),
        _destroy: Joi.boolean()
      }).unknown()
    )
    .required(),
  // mail: Joi.string()
  //   .email({ tlds: { allow: false } }) // We do not have the list installed
  //   .allow('')
  //   .optional(),

  street: Joi.string().required(),
  city: Joi.string().required(),
  address_country: Joi.string().required(),

  driving_license_exp_date: Joi.alternatives([
    Joi.date().min('1900-01-01').max('2060-01-01'),
    Joi.string().allow('')
  ]).required(),

  // require emplument_attributes.availability_id to be present if emplument_attributes _destroy === false
  employments_attributes: Joi.array()
    .items(
      Joi.object({
        started_at: Joi.string().when('_destroy', {
          is: true,
          then: Joi.allow('')
        }),
        availability_id: Joi.number().when('_destroy', {
          is: true,
          then: Joi.allow('')
        }),
        //TODO: handle job_ids+job_other
        // job_other: Joi.string().allow(''),
        // job_ids: Joi.array().items(Joi.number()).when('_destroy', {
        //   is: true,
        //   then: Joi.allow('')
        // }),
        family_name: Joi.string().when('_destroy', {
          is: true,
          then: Joi.allow('')
        }),
        _destroy: Joi.boolean()
      }).unknown()
    )
    .required(),
  // Require language_attributes.language_id to be present if language_attributes _destroy === false
  spoken_languages_attributes: Joi.array()
    .items(
      Joi.object({
        language_id: Joi.when('_destroy', {
          is: true,
          then: Joi.allow(''),
          otherwise: Joi.number()
        }),
        _destroy: Joi.boolean()
      }).unknown()
    )
    .required(),

  //relatives_attributes
  relatives_attributes: Joi.array()
    .items(
      Joi.object({
        age: Joi.when('_destroy', {
          is: true,
          then: Joi.allow(''),
          otherwise: Joi.number()
        }),
        _destroy: Joi.boolean()
      }).unknown()
    )
    .required()

  // visa_presence: Joi.boolean().required(),
  // visa_release_reason: Joi.string().when('visa_presence', {
  //   is: false,
  //   then: Joi.allow('')
  // })
}).unknown() // fields not specified in this schema will be ignored by the resolver
export default joiResolver(schema)
