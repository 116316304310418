import React from 'react'
import TextField from '@mui/material/TextField'
import { ChipSuggestions } from '../chip/ChipSuggestions'
import { Stack } from '@mui/material'

type RapidTextFieldProps = {
  options: string[]
  onChange: (val: string) => void
} & React.ComponentProps<typeof TextField>

/**
 * A TextField with a list of clickable chips below it.
 * The chips are used to quickly fill the TextField.
 * @param options The list of options to show as chips
 * @param value The value of the TextField
 * @param onChange The function to call when the TextField value changes
 * @param other The other props to pass to the TextField
 */
const RapidTextField = React.forwardRef<HTMLInputElement, RapidTextFieldProps>(
  ({ options, value, onChange, ...other }, ref) => {
    const onChipClick = (option: string) => {
      // Append the clicked option to the TextField value
      let newValue = value ? `${value} ${option}` : option
      // Remove any duplicate spaces
      newValue = newValue.replace(/\s\s+/g, ' ')
      // Update the TextField value
      // calling onchange with React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> parameter
      onChange(newValue)
    }

    return (
      <Stack sx={{ height: '100%' }}>
        <TextField
          value={value}
          onChange={onChange}
          {...other}
          sx={{ flexGrow: 1, mb: 1 }}
          // Add height: 100% to .MuiInputBase-root
          // to make the TextField fill the parent container
          InputProps={{ sx: { height: '100%' } }}
          // Add height: 100% to .MuiInputBase-input
          // to make the TextField fill the parent container
          inputProps={{ style: { height: '100%' } }}
        />
        <ChipSuggestions
          onChange={onChipClick}
          options={options}
          chipProps={{ size: 'small', variant: 'outlined' }}
        />
      </Stack>
    )
  }
)

export default RapidTextField
