import { Joi } from '../../utils/validation'
import { joiResolver } from '@hookform/resolvers/joi'

// TIP: test schemas with https://joi.dev/tester/

const schema = Joi.object({
  status: Joi.string().required(),
  signed_at: Joi.when('status', {
    is: 'signed',
    then: Joi.date()
      .required()
      .format('DD/MM/YYYY')
      .min('1900-01-01')
      .max('2060-01-01')
  }),
  customer: {
    full_name: Joi.string().required()
  }
}).unknown() // fields not specified in this schema will be ignored by the resolver
export default joiResolver(schema)
