import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from '@mui/material/utils'
import { Controller } from 'react-hook-form'
import { AutocompleteOption } from '../../../types'

interface Props
  extends Omit<React.ComponentProps<typeof Controller>, 'name' | 'render'> {
  label: string
  valueFieldName: string
  labelFieldName: string
  control: any
  setValue: any
  lazyQuery: any
  inputProps?: React.ComponentProps<typeof TextField>
}

export default function SearchSelect({
  label,
  valueFieldName,
  labelFieldName,
  control,
  setValue,
  lazyQuery,
  inputProps,
  ...props
}: Props) {
  // Use debounced trigger to fetch max 1 request every N millisecs (throttling)
  const [trigger, { data: options, isFetching }] = lazyQuery()
  const debouncedTrigger = debounce(trigger, 300)

  // Fetch results on user typing
  const handleInputChange = (_, newInputValue: string) => {
    debouncedTrigger(newInputValue)
  }

  const renderOption = (props, option: AutocompleteOption, index) => {
    // Some option have the same text, so we need to override the key
    // to prevent list item duplication
    return (
      <li {...props} key={`listItem-${option.id}`}>
        {option.text}
      </li>
    )
  }

  return (
    <Controller
      {...props}
      name={labelFieldName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          disabled={inputProps?.disabled}
          {...field}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.text
          }
          options={options || []}
          loading={isFetching}
          onInputChange={handleInputChange}
          onChange={(e, value: AutocompleteOption | null) => {
            let newVal: AutocompleteOption = value || { text: '', id: '' } // replace null with ""
            field.onChange(newVal.text)
            // Autocomplete uses text, but to emulate a select we need to update a field
            // containing the value (model id) of the option
            setValue(valueFieldName, newVal.id)
          }}
          noOptionsText="Nessun risultato"
          renderInput={(params) => (
            <TextField
              {...params}
              {...inputProps}
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          renderOption={renderOption}
        />
      )}
    />
  )
}
