import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCreateAttachmentMutation } from '../../services/crmApi'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    LinearProgress,
    Typography
} from '@mui/material'
import { Container } from '@mui/system'
import useDirectUpload from '../../utils/useDirectUpload'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import { BrowserRouter } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

interface PdfFormProps {
    candidateId: number
}

interface PdfFormData {
    pdf: { id: string; file: File }
}

const UploadPdfAttachmPage: React.FC<PdfFormProps> = ({ candidateId }) => {

    const [fileName, setFileName] = React.useState<string>('')

    const { control, handleSubmit, setValue, getValues, getFieldState } =
        useForm<PdfFormData>({
            defaultValues: {
                pdf: {
                    id: Date.now().toString(),
                    file: null
                }
            }
        })

    const [
        submitAttachment,
        {
            isLoading: isAttachLoading,
            isSuccess: isAttachSuccess,
            isError: isAttachError,
            data: attachData
        }
    ] = useCreateAttachmentMutation()

    const [
        triggerDirectUpload,
        {
            isAllSuccess: isUploadSuccess,
            isAnyError: isUploadError,
            isAnyUploading: isUploading,
            uploadStates
        }
    ] = useDirectUpload()

    const handleDirectUpload = async (data: PdfFormData) => {
        const file = data.pdf.file
        triggerDirectUpload([file])
    }

    // On upload success, submit the signed ids to the server
    useEffect(() => {
        if (isUploadSuccess) {
            // Check that uploads contains all the blobs
            if (uploadStates.some((u) => !u.blob)) {
                // This should never happen. But maybe it's better to be safe than sorry
                alert(
                    'Il file non è stato caricato. Controlla la connessione e riprova.'
                )
                Sentry.captureMessage('File upload error', {
                    extra: {
                        candidateId,
                        pdf: getValues('pdf'),
                        uploadStates,
                        isUploadSuccess
                    }
                })
                return
            }
            let blob_signed_ids = uploadStates.map((u) => u.blob.signed_id)
            submitAttachment({
                candidateId,
                blob_signed_ids
            })
        }
    }, [isUploadSuccess, uploadStates, submitAttachment, candidateId])

    // On submit success, redirect according to server response
    useEffect(() => {
        if (isAttachSuccess) {
            //@ts-ignore-next-line
            notice('CV creato con successo')
            window.location.href = attachData.redirect
        }
    }, [isAttachSuccess, attachData])

    // On upload or submit error, show an alert
    useEffect(() => {
        if (isUploadError) {
            alert(
                'Errore durante il caricamento del file. Controlla di essere connesso.'
            )
        } else if (isAttachError) {
            alert("Errore durante l'invio del file. Controlla di essere connesso.")
        }
    }, [isUploadError, isAttachError])

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0]
        if (file) {
            console.log(file)
            setFileName(file.name)
            setValue(`pdf.file`, file)
        }
    }

    const selectedFile: boolean = !!fileName

    return (
        <Container maxWidth="sm">
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: "center", my: 3 }}>
                        <AttachFileOutlinedIcon fontSize='large' sx={{ mr: 2, mb: 1 }} />
                        <Typography variant="h5" component="h1">
                            Allega file PDF
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit(handleDirectUpload)}>

                        <input
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            accept="application/pdf"
                            onChange={(event) =>
                                handleFileChange(
                                    event as React.ChangeEvent<HTMLInputElement>
                                )
                            }
                        />



                        <Box sx={{ flexGrow: 1 }}>

                            {getFieldState(`pdf.file`).error && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {getFieldState(`pdf.file`).error?.message}
                                </Alert>
                            )}

                            <label htmlFor="file-upload" style={{ width: '100%' }}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    component="span"
                                    disabled={isUploading || isAttachLoading}
                                    sx={{
                                        border: '1px dashed',
                                        borderColor: 'grey.400',
                                        borderRadius: '5px',
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >

                                    {fileName
                                        ? <InsertDriveFileIcon sx={{ fontSize: '3rem', mb: 1 }} />
                                        : <FolderOpenOutlinedIcon sx={{ fontSize: '3rem', mb: 1 }} />
                                    }
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {fileName || "Clicca per selezionare un file PDF"}
                                    </Typography>
                                </Button>

                                {uploadStates && uploadStates[0].progress && (
                                    <LinearProgress
                                        variant={uploadStates[0].progress === 100 ? 'indeterminate' : 'determinate'}
                                        value={uploadStates[0].progress}
                                    />
                                )}
                            </label>

                        </Box>

                        <Button
                            type="submit"
                            disabled={isUploading || isAttachLoading || !selectedFile}
                            fullWidth
                            sx={{ mt: 2 }}
                            startIcon={<CloudUploadIcon />}
                            variant="contained"
                        >
                            Carica
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container >
    )
}



export default (props) => (
    // Wrap in store to allow the use of RTK Query
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <UploadPdfAttachmPage {...props}></UploadPdfAttachmPage>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
)
