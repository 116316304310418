import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useGetLangsQuery } from '../../../../services/crmApi'
import Select from '../../../input/select/Select'
import { ISpokenLanguageAttributes } from '../../../../types'

// TODO: Get from i18n file
export const SPOKEN_LANGUAGES_LEVELS = [
  { label: 'Base', value: 'basic' },
  { label: 'Autonomo', value: 'good' },
  { label: 'Avanzato', value: 'excellent' },
  { label: 'Madrelingua', value: 'native' }
]

function Language(props) {
  const { control, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: 'spoken_languages_attributes',
    control
  })
  const { data: options } = useGetLangsQuery({})

  const markFieldForDeletion = (index) => {
    setValue(`spoken_languages_attributes.${index}._destroy`, true)
  }

  return (
    <Stack gap={2}>
      {options &&
        fields.map((item, index) => (
          <Stack
            direction="row"
            gap={2}
            key={index}
            sx={
              watch(`spoken_languages_attributes.${index}._destroy`)
                ? { textDecoration: 'line-through' }
                : {}
            }
          >
            <Controller
              name={`spoken_languages_attributes.${index}.language_id`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label="Lingua"
                  options={options}
                  fullWidth
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name={`spoken_languages_attributes.${index}.level`}
              control={control}
              render={({ field }) => (
                <Select
                  label="Livello"
                  options={SPOKEN_LANGUAGES_LEVELS}
                  fullWidth
                  {...field}
                />
              )}
            />
            <Button onClick={() => markFieldForDeletion(index)}>X</Button>
          </Stack>
        ))}
      <Button
        onClick={() => append({ language_id: '', level: '', _destroy: false })}
      >
        Aggiungi lingua
      </Button>
    </Stack>
  )
}

export default Language
