import React from 'react'
import Select, { SelectProps } from './Select'

interface FetchSelectProps extends Omit<SelectProps, 'options'> {
  // Options accepts hooks such as useGetAvailabilitiesQuery
  // TODO: improve this type
  options: any
}

const FetchSelect: React.FC<FetchSelectProps> = React.forwardRef<
  HTMLInputElement,
  FetchSelectProps
>(({ options, ...props }, ref) => {
  const { data, isError, isLoading } = options()

  return (
    isError ? (
      <>'Error loading options'</>
    ) :
      isLoading ? (
        <>'Loading options....'</>
      ) : data ? (
        <Select options={data} {...props} ref={ref} />
      ) : (
        <>No options found</>
      ))
})

export default FetchSelect
