import { useState } from 'react'
import { DirectUpload } from '@rails/activestorage'
import {nanoid} from 'nanoid'

function useDirectUpload() {
  const [uploadStates, setUploadStates] = useState()

  const trigger = async (files) => {

    // Assign a localId to each file. This is used to track the progress of each file
    // in our state. We can't use the file name itself because it's not unique.
    const uploads = files.map(file => ({
      file,
      localId: nanoid()
    }))

    setUploadStates(uploads.map(u => ({
      file: u.file, // Reference to the file
      localId: u.localId, // Unique id for this file. Used to update the state
      progress: 0,
      error: undefined, // Error message
      blob: undefined, // The blob object returned from the server
    })))


    // Create an Uploader for each file
    const uploaders = uploads.map(u => {
      const uploader = new Uploader(u.file, u.localId)
      return uploader
    })

    // start uploading and return a promise that resolves when all uploads are complete
    return Promise.all(uploaders.map((uploader) => uploader.start()))
  }

  class Uploader {

    constructor(file, localId) {
      this.file = file
      this.localId = localId
      this.uploader = new DirectUpload(file, '/rails/active_storage/direct_uploads', this)
    }

    start() {
      return new Promise((resolve, reject) => {
        this.uploader.create((error, blob) => {
          if (error) {
            // Set error and clear blobs for this file
            setUploadStates((prev) =>
              prev.map(u =>
                u.localId === this.localId ? {
                  ...u,
                  blob: null,
                  error
                } : u)
            )
            reject(error)
          } else {
            // Set blob and clear errors for this file
            setUploadStates((prev) =>
              prev.map(u =>
                u.localId === this.localId ? {
                  ...u,
                  blob: blob,
                  error: null
                } : u)
            )
            resolve(blob)
          }
        })
      })
    }

    directUploadWillStoreFileWithXHR(request) {
      // Add progress event listener
      request.upload.addEventListener('progress', (event) => {
        this.directUploadDidProgress(event)
      })
    }

    directUploadDidProgress(event) {
      // Update progress for this file
      setUploadStates((prev) =>
        prev.map(u =>
          u.localId === this.localId ? {
            ...u,
            progress: Math.round((event.loaded / event.total) * 100)
          } : u)
      )
    }
  }

  const isAllSuccess = uploadStates !== undefined && !uploadStates.some(val => val.blob === null || val.blob === undefined)
  const isAnyError = uploadStates !== undefined && uploadStates.some(val => !!val.error)
  const isAnyUploading = uploadStates !== undefined && !isAnyError && uploadStates.some(val => val.progress < 100)

  return [
    trigger,
    {
      uploadStates,
      isAllSuccess,
      isAnyError,
      isAnyUploading,
    }
  ]
}


export default useDirectUpload
