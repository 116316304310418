/**
  DEPRECATED: use addDestroyPseudoFieldToAttributes instead
  Prepare array of attributes adding _destroy field for use in react-hook-form
*/
export function addDestroyPseudoField<T>(
  attributes: T
): T & { _destroy: boolean } {
  return {
    ...attributes,
    _destroy: false
  }
}

/**
  Accepts key which is a string, returns a function that
  accepts an object and returns a new object with the key
  replaced with `${key}_attributes` and the value of the key
*/
// export function renameKey(original: string, replacement: string) {
//   return (obj: any) => {
//     const { [original]: value, ...rest } = obj
//     return { ...rest, [replacement]: value }
//   }
// }

/**
  Deeply scans an object and adds _destroy: false to all objects that are part of an array with the key matching the pattern /_attributes$/
*/

// export function addDestroyPseudoFieldToAttributes(attributes: object): object {
//   return Object.entries(attributes).reduce(
//     (acc, [key, value]) => ({
//       ...acc,
//       [key]:
//         Array.isArray(value) && key.match(/_attributes$/)
//           ? value.map(addDestroyPseudoField)
//           : value
//     }),
//     {}
//   )
// }
