import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ReactCrop, { type Crop } from 'react-image-crop'
import { Buffer } from 'buffer'

// @ts-ignore
window.Buffer = Buffer

export default function AlertDialog({ imgSrc, onSubmit }) {
  const imgRef = useRef<HTMLImageElement>(null)

  // Temporary crop, center of pic by default
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })

  function handleSubmit() {
    if (crop) {
      // create a canvas element to draw the cropped image
      const canvas = document.createElement('canvas')

      // get the image element
      const image = imgRef.current

      // draw the image on the canvas
      if (image) {
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        // Set maximum dimensions
        const maxWidth = 400; // Max width for the image
        const maxHeight = 400; // Max height for the image
        let width = crop.width * scaleX; // Current image width
        let height = crop.height * scaleY; // Current image height

        // Check if the current width is larger than the max
        if (width > maxWidth) {
          // Recalculate the ratio
          const ratio = maxWidth / width;
          width = maxWidth;
          height = height * ratio;
        }

        // Check if the current height is larger than the max
        if (height > maxHeight) {
          // Recalculate the ratio
          const ratio = maxHeight / height;
          height = maxHeight;
          width = width * ratio;
        }

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
          ctx.imageSmoothingQuality = 'high'

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            width,
            height
          )
        }

        const base64Image = canvas.toDataURL('image/png') // can be changed to jpeg/jpg etc

        if (base64Image) {
          // @ts-ignore
          const fileType = base64Image.split(';')[0].split(':')[1] || 'image/png'

          const buffer = Buffer.from(
            base64Image.replace(/^data:image\/\w+;base64,/, ''),
            'base64'
          )
          const file = new File([buffer], 'profile_image_crop', {
            type: fileType
          })
          onSubmit(file)
        }
      }
    }
  }


  return (
    <Dialog
      open={imgSrc !== undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Taglio immagine'}</DialogTitle>
      <DialogContent>
        <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
          <img ref={imgRef} src={imgSrc} />
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Annulla</Button> */}
        <Button onClick={handleSubmit} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
