import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useGetSearchResultsQuery, useGetSearchQuery } from '../services/crmApi'
import { ISearchCandidateResponse } from '../types'

interface FetcherProps {
  fetch: any
  render: (any) => JSX.Element
  loadingFallback?: (any) => JSX.Element
  errorFallback?: (any) => JSX.Element
}

interface DefaultEndpointFetcherProps extends Omit<FetcherProps, 'fetch'> {}

const DefaultError = (data) => (
  <>Si è verificato un errore. Controlla la connesione e riprova.</>
)
const DefaultLoading = (data) => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress />
  </div>
)

function Fetcher({
  fetch,
  render,
  loadingFallback = DefaultLoading,
  errorFallback = DefaultError
}: FetcherProps): JSX.Element {
  const returned = fetch()
  const { data, isError } = returned

  return isError
    ? errorFallback(returned)
    : data
    ? render(returned)
    : loadingFallback(returned)
}

const SearchResults: React.FC<DefaultEndpointFetcherProps> = ({ ...props }) => {
  let [searchParams, _] = useSearchParams()

  const fetch = () => {
    const { isLoading, isFetching, ...rest } = useGetSearchResultsQuery({
      id: parseInt(searchParams.get('search')) || undefined, // Search ID or undefined
      page: parseInt(searchParams.get('page')) || 1 // Page number or 1
    })
    return {
      isLoading: isLoading || isFetching,
      isFetching,
      ...rest
    }
  }

  return <Fetcher {...props} fetch={fetch} />
}

const SearchModel: React.FC<DefaultEndpointFetcherProps> = ({ ...props }) => {
  let [searchParams, _] = useSearchParams()

  return (
    <Fetcher
      {...props}
      fetch={() => useGetSearchQuery(searchParams.get('search'))}
    />
  )
}

Fetcher.SearchResults = SearchResults
Fetcher.SearchModel = SearchModel

export default Fetcher
