import Grid from '@mui/system/Unstable_Grid'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { REF } from '../../../../enums'
import { ChipRadioInput } from '../../../input/chip/ChipInput'
import { RapidFormGrid } from '../../../input/RapidForm/RapidFormGrid'
import { RapidFormSection } from '../../../input/RapidForm/RapidFormSection'
import { RapidFormTile } from '../../../input/RapidForm/RapidFormTile'
import FocusTrapTextField from '../../../input/FocusTrapTextField'

const FormSection = () => {
  const { control } = useFormContext()

  return (
    <RapidFormSection title={'Altro'} bgColor={'#6CB9AA'}>
      <RapidFormGrid>
        <Grid xs={6}>
          <RapidFormTile label="🛑 Perchè è finita">
            <Controller
              name="end_reason"
              control={control}
              render={({ field }) => (
                <ChipRadioInput {...field} options={REF.end_reason} />
              )}
            />
          </RapidFormTile>
        </Grid>
        <Grid xs={6}>
          <RapidFormTile label="📝 Note">
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <FocusTrapTextField
                  fullWidth
                  size="small"
                  multiline
                  maxRows={4}
                  {...field}
                />
              )}
            />
          </RapidFormTile>
        </Grid>
      </RapidFormGrid>
    </RapidFormSection>
  )
}

export default FormSection
