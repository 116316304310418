import React from 'react'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Stack } from '@mui/system'
import {
  ICandidateAttributes,
  IRelativeAttributes,
  INestedArrayAttributes
} from '../../../../../types'
import {
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  CardHeader
} from '@mui/material'

import Select from '../../../../input/select/Select'
import { Checkbox } from '@mui/material'
import EscalatorWarningOutlinedIcon from '@mui/icons-material/EscalatorWarningOutlined';
import FaceIcon from '@mui/icons-material/Face';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';

const FamilySection: React.FC<{}> = ({ }) => {

  const form = useFormContext<ICandidateAttributes>()
  const { fields, append } = useFieldArray({
    control: form.control,
    name: 'relatives_attributes',
    keyName: 'form_key' // Rename key to avoid conflicts with `id` field
  })

  const handleAddRelative = () => {
    append({ id: '', age: null, cohabit: false, notes: '', _destroy: false })
  }

  const relativeFields = (member: (IRelativeAttributes & INestedArrayAttributes), index: number) => {

    if (form.watch(`relatives_attributes.${index}._destroy`)) return null

    return (
      <Grid xs={12} key={member.form_key}>
        <Card>
          {/* Card title */}
          <CardHeader
            title={`Figlio`}
            action={
              <IconButton
                color="error"
                onClick={() => form.setValue(`relatives_attributes.${index}._destroy`, true)}
              >
                <ClearOutlinedIcon />
              </IconButton>
            }
            avatar={
              <FaceIcon />
            }
            sx={{ pb: 0.5 }}
          />
          <CardContent sx={{ py: 0.5 }}>
            <Grid container spacing={1}>




              {/* Age */}
              <Grid xs={3} md={3}>
                <Controller
                  name={`relatives_attributes.${index}.age`}
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Età"
                      fullWidth
                      required
                      options={
                        // Range 1-99
                        Array.from(Array(100), (_, i) => i).map((i) => ({
                          label: i === 0 ? "< 1 anno" : i.toString(),
                          value: i
                        }))
                      }
                      error={!!form.formState.errors?.relatives_attributes?.[index]?.age}
                      helperText={!!form.formState.errors?.relatives_attributes?.[index]?.age && "Campo obbligatorio"}
                      size="small"
                    />
                  )}
                />
              </Grid>

              {/* Notes */}
              <Grid xs={9} md={9}>
                <Controller
                  name={`relatives_attributes.${index}.notes`}
                  control={form.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Note"
                      minRows={1}
                      fullWidth
                      multiline
                      size="small"
                    />
                  )}
                />
              </Grid>

              {/* Cohabits */}
              <Grid xs={12} md={12}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Figlio convivente"
                  {...form.register(`relatives_attributes.${index}.cohabit` as const)}
                  checked={form.watch(`relatives_attributes.${index}.cohabit` as const)}
                />

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid >

    )
  }


  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h6">Coniuge</Typography>
      </Grid>

      {/* Status */}
      <Grid xs={12} sm={6}>
        <Controller
          name='family_status'
          control={form.control}
          render={({ field }) => (
            <Select
              label="Stato civile"
              // #TODO: this enum is duplicated in the frontend. Move it to the backend
              options={[
                { label: 'Single', value: 'single' },
                { label: 'Sposato', value: 'married' },
                { label: 'Divorziato', value: 'divorced' },
                { label: 'Vedovo', value: 'widow' },
                { label: 'Altro', value: 'other' },
              ]}
              {...field}
              size="small"
              fullWidth
            />
          )}
        />
      </Grid>

      {/* Partner cohabits? */}
      <Grid xs={12} sm={6}>
        <FormControlLabel
          control={<Checkbox />}
          label="Coniuge convivente"
          {...form.register('partner_cohabit')}
          checked={form.watch('partner_cohabit')}
        />
      </Grid>

      {/* Children */}
      <Grid xs={12}>
        <Typography variant="h6">Figli</Typography>
      </Grid>
      {fields.length === 0 &&
        <Grid xs={12}>
          <Stack direction="column" alignItems={"center"} spacing={1} sx={{
            border: '1px dashed',
            borderColor: 'grey.400',
            borderRadius: '5px',
            p: 2
          }}>
            <EscalatorWarningOutlinedIcon sx={{ fontSize: 40 }} />
            <Typography variant="caption">Nessun figlio inserito</Typography>
          </Stack>
        </Grid>}

      {fields.map(relativeFields)}

      <Grid xs={12}>

        <Button
          variant="outlined"
          onClick={handleAddRelative}
          fullWidth
          startIcon={<PlaylistAddOutlinedIcon />}
        >
          Aggiungi figlio
        </Button>
      </Grid>


    </Grid >
  )
}



export default FamilySection
