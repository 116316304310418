import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import COUNTRIES_OPTIONS from '../../../../locales/countries'
import Select from '../../../input/select/Select'
import SelectMulti from '../../../input/select/SelectMulti'
import Slider from '../../../input/Slider'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { addBreadcrumb } from '@sentry/browser'
import { CountrySelectMulti } from '../../../input/select/CountrySelect'

// TODO: Get from i18n file
const RATING_OPTIONS = [
  { label: 'Scartato', value: 'rejected' },
  { label: 'Basso', value: 'low' },
  { label: 'Medio', value: 'medium' },
  { label: 'Alto', value: 'good' },
  { label: 'Nessuno', value: 'none' }
]
// TODO: Get from i18n file
const GENDER_OPTIONS = [
  { value: 'male', label: 'Includi solo uomimi' },
  { value: 'female', label: 'Includi solo donne' }
]

function Anagraphic({
  subformPrefix = '',
  viewMode = 'search'
}: {
  subformPrefix?: string
  viewMode?: 'search' | 'contract'
}) {
  const { control } = useFormContext()

  const addPrefix = (name: string) => {
    return subformPrefix + name
  }

  return (
    <Grid container spacing={2}>
      {viewMode === 'search' && (
        <>
          <Grid item xs={12} md={6}>
            <Controller
              name={addPrefix('fuzzymatch')}
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Ricerca libera" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={addPrefix('tel')}
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Cellulare" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={addPrefix('allowed_ratings')}
              control={control}
              render={({ field }) => (
                <SelectMulti
                  label="Rating"
                  options={RATING_OPTIONS}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={viewMode === 'search' ? 6 : 12}>
        <Controller
          name={addPrefix('gender')}
          control={control}
          render={({ field }) => (
            <Select
              label="Sesso"
              options={GENDER_OPTIONS}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name={addPrefix('incl_countries')}
          control={control}
          render={({ field }) => (
            <CountrySelectMulti
              label="Nazioni (Includi)"
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name={addPrefix('excl_countries')}
          control={control}
          render={({ field }) => (
            <CountrySelectMulti
              label="Nazioni (Escludi)"
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ px: 3 }}>
          <Controller
            name={addPrefix('age_range2')}
            control={control}
            defaultValue={[0, 99]}
            render={({ field }) => (
              <Slider
                {...field}
                label="Range di età"
                min={0}
                max={99}
                step={1}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Anagraphic
