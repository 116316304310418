import React, { useEffect, useState } from 'react'
import { Mode, useFieldArray, useForm } from 'react-hook-form'
import { useAttachProfilePictureMutation } from '../../../services/crmApi'
import {
  Alert,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Typography
} from '@mui/material'
import {
  Delete,
  AddCircle,
  ArrowUpward,
  ArrowDownward,
  CheckCircle
} from '@mui/icons-material'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { BrowserRouter } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { Container } from '@mui/system'
import useDirectUpload from '../../../utils/useDirectUpload'
// import joiSchema from './joiSchema'
import LoadingButton from '@mui/lab/LoadingButton'
import * as Sentry from '@sentry/react'
import { ModelId } from '../../../types'
import CropDialog from './CropDialog'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
const ProfilePicturePage: React.FC<{
  candidateId: ModelId
  currentProfilePictureUrl: string
}> = ({ candidateId, currentProfilePictureUrl }) => {
  const [croppedImg, setCroppedImg] = useState<File>()
  const [fullImgB64, setFullImgB64] = useState<string>()

  const [
    triggerDirectUpload,
    {
      isAllSuccess: isUploadSuccess,
      isAnyError: isUploadError,
      isAnyUploading: isUploading,
      uploadStates
    }
  ] = useDirectUpload()

  const [attachProfilePicture, { data, isLoading, isError, isSuccess }] =
    useAttachProfilePictureMutation()

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    // Convert file to base64 string and save it
    // into the state. This will cause the crop
    // modal to show up.
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setFullImgB64(reader.result?.toString() || '')
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onCropComplete = (cropped: File) => {
    // close the modal
    setFullImgB64(undefined)
    // Update the image value to the cropped image
    setCroppedImg(cropped)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (croppedImg) {
      triggerDirectUpload([croppedImg])
    } else {
      alert('Seleziona un file')
    }
  }

  // On upload success, submit the signed ids to the server
  useEffect(() => {
    if (isUploadSuccess) {
      // Check that uploads contains all the blobs
      if (uploadStates.some((u) => !u.blob)) {
        // This should never happen. But maybe it's better to be safe than sorry
        alert(
          'Alcuni file non sono stati caricati. Controlla la connessione e riprova.'
        )
        Sentry.captureMessage('File upload error', {
          extra: {
            candidateId,
            uploadStates,
            isUploadSuccess
          }
        })
        return
      }
      let blob_signed_ids = uploadStates.map((u) => u.blob.signed_id)
      attachProfilePicture({
        candidateId: candidateId,
        profile_picture_blob_id: blob_signed_ids[0] as string
      })
    }
  }, [isUploadSuccess, uploadStates, attachProfilePicture, candidateId])

  // // On submit success, redirect according to server response
  useEffect(() => {
    if (isUploadError) {
      // Error while uploading the blob to the server
      alert(
        'Errore durante il caricamento dei file. Controlla di essere connesso.'
      )
    } else if (isSuccess) {
      // Blob was successfully attached to the candidate
      //@ts-ignore-next-line
      notice('Immagine salvata con successo')
      // Reload page
      window.location.reload()
    } else if (isError) {
      // Error while attaching the blob to the candidate
      alert(
        "Errore durante il salvataggio dell'immagine. Controlla di essere connesso."
      )
    }
  }, [isUploadError, isSuccess, isError, data])

  return (
    <Container maxWidth="sm">
      <Button
        startIcon={<ArrowBackOutlinedIcon />}
        component="a"
        href={`/candidates/${candidateId}`}
        sx={{ mb: 2 }}
      >
        Torna al profilo
      </Button>

      <CropDialog imgSrc={fullImgB64} onSubmit={onCropComplete} />
      <form onSubmit={onSubmit}>
        <Stack
          spacing={2}
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          alignItems="center"
        >
          <img
            src={currentProfilePictureUrl}
            style={{
              width: '100%',
              maxWidth: '250px',
              height: 'auto',
              objectFit: 'cover'
            }}
          />
          <Stack>
            <Typography variant="h5" component="h1" gutterBottom>
              Aggiorna foto profilo
            </Typography>
            <input
              style={{ flexGrow: 1 }}
              type="file"
              accept="image/jpg,image/jpeg,image/png"
              onChange={onSelectFile}
            />
            {uploadStates && uploadStates[0].error && (
              <Alert severity="error" sx={{ mb: 1 }}>
                {uploadStates[0].error}
              </Alert>
            )}
            <LoadingButton
              type="submit"
              fullWidth
              loading={isUploading}
              variant="contained"
              startIcon={<CheckCircle />}
              sx={{ mt: 2 }}
            >
              Salva
            </LoadingButton>
            <Button
              fullWidth
              variant="contained"
              startIcon={<DeleteOutlinedIcon />}
              sx={{ mt: 2 }}
              color="error"
              component="a"
              href={`/candidates/${candidateId}/delete_profile_picture`}
            >
              Rimuovi foto
            </Button>
          </Stack>
        </Stack>
      </form>
    </Container>
  )
}

export default (props) => (
  // Wrap in store to allow the use of RTK Query
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ProfilePicturePage {...props}></ProfilePicturePage>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
