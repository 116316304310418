import React from 'react'
import { default as MuiTabs } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ErrorIcon from '@mui/icons-material/Error'
import { useSearchParams } from 'react-router-dom'
interface TabPanelProps extends React.ComponentProps<typeof Box> {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return value === index ? (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ p: 3 }}
      {...other}
    >
      {children}
    </Box>
  ) : null
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

interface RenderTabProps {
  onError: (hasError: boolean) => void
}

interface TabsProps {
  sections: Array<{
    title: string
    component: React.ReactNode
    error?: boolean
  }>
}

export default function ({ sections }: TabsProps) {
  // If the URL contains the query parameter `add_contract_empl`, go to the employments tab to rapidly add a new employment
  const [params] = useSearchParams()
  const defaultTab: number = params.get('add_contract_empl') ? 2 : 0
  const [value, setValue] = React.useState(defaultTab)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }


  return (
    <Stack
      sx={{
        minHeight: 224
      }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <MuiTabs
        orientation={window.innerWidth < 600 ? 'horizontal' : 'vertical'}
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {sections.map((section, index) => (
          <Tab
            label={section.title}
            sx={section.error ? { color: 'red !important' } : {}}
            icon={section.error ? <ErrorIcon /> : undefined}
            iconPosition="end"
            key={section.title}
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>
      {sections.map(({ title, component }, index) => (
        <TabPanel
          index={index}
          value={value}
          key={title}
          sx={{ width: '100%', px: { sm: 3 }, py: 2 }}
        >
          {component}
        </TabPanel>
      ))}
    </Stack>
  )
}
