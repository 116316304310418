import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFiltersContext } from '../useFiltersContext'
import Slider from '../../../input/Slider'
import Stack from '@mui/material/Stack'
import { IRefFieldProps } from '../../../../types'
import { REF_FIELDS } from '../../../../enums'

interface Props {
  fields: IRefFieldProps[]
  subformPrefix?: string
}

function Scores({ fields, subformPrefix = '' }: Props) {
  const { control } = useFormContext()

  const mapOptionsToMarks = (option, index) => ({
    value: index,
    label: option.label
  })

  return (
    <Stack sx={{ px: 3 }} gap={4}>
      {fields.map(({ name, label, options }) => (
        <Controller
          key={name}
          name={`${subformPrefix}${name}_score`}
          control={control}
          render={({ field }) => (
            <Slider
              {...field}
              boldLabel
              valueLabelDisplay="off"
              label={label}
              min={0}
              max={options.length - 1}
              step={0.05}
              marks={options.map(mapOptionsToMarks)}
            />
          )}
        />
      ))}
    </Stack>
  )
}

const PersonalityScores = (props) => (
  <Scores fields={REF_FIELDS.personality} {...props} />
)
const ColfScores = (props) => <Scores fields={REF_FIELDS.colf} {...props} />
const CaretakerScores = (props) => (
  <Scores fields={REF_FIELDS.caretaker} {...props} />
)
const NannyScores = (props) => <Scores fields={REF_FIELDS.nanny} {...props} />

export { PersonalityScores, ColfScores, CaretakerScores, NannyScores }
