import React from 'react'
import { useFormContext } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'

function Characteristic(props) {
  const { register, watch } = useFormContext()

  return (
    <Stack>
      <FormControlLabel
        control={<Checkbox />}
        label="Possiede patente"
        {...register('require_driving_license')}
        checked={watch('require_driving_license')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Possiede auto"
        {...register('require_car')}
        checked={watch('require_car')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Escludi allergici gatto"
        {...register('allergy_cat')}
        checked={watch('allergy_cat')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Escludi allergici cane"
        {...register('allergy_dog')}
        checked={watch('allergy_dog')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Escludi fumatori"
        {...register('excl_if_smoker')}
        checked={watch('excl_if_smoker')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Deve saper nuotare"
        {...register('require_swim')}
        checked={watch('require_swim')}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Escludi chi rifiuta ET"
        {...register('excl_if_refuses_et')}
        checked={watch('excl_if_refuses_et')}
      />
    </Stack>
  )
}

export default Characteristic
