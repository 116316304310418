import React from 'react'
import FiltersForm, { FiltersFormProps } from './FiltersForm'
import Tabs from '../../../utils/Tabs'

interface Props extends Omit<FiltersFormProps, 'children'> {
  showSettings?: boolean
}

const TabbedFiltersForm: React.FC<Props> = ({
  showSettings = true,
  ...props
}): JSX.Element => {
  const sections = [
    { title: 'Anagrafica', component: <FiltersForm.Anagraphic /> },
    { title: 'Lingue', component: <FiltersForm.Language /> },
    { title: 'Mansioni', component: <FiltersForm.Job /> },
    { title: 'Istruzione', component: <FiltersForm.Education /> },
    {
      title: 'Caratteristiche',
      component: <FiltersForm.Characteristic />
    },
    { title: 'Posizione', component: <FiltersForm.Location /> },
    {
      title: 'Personalità',
      component: <FiltersForm.PersonalityScores />
    },
    {
      title: 'Colf',
      component: <FiltersForm.ColfScores />
    },
    {
      title: 'Badante',
      component: <FiltersForm.CaretakerScores />
    },
    {
      title: 'Tata',
      component: <FiltersForm.NannyScores />
    }
  ]

  if (showSettings) {
    sections.push({
      title: 'Impostazioni',
      component: <FiltersForm.Settings />
    })
  }

  return (
    <FiltersForm {...props}>
      <Tabs sections={sections} />
      <FiltersForm.Actions />
    </FiltersForm>
  )
}

export default TabbedFiltersForm
