import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Stack from '@mui/material/Stack'

function Settings(props) {
  const { control } = useFormContext()

  return (
    <Stack gap={2}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField label="Nome ricerca" fullWidth {...field} />
        )}
      />
    </Stack>
  )
}

export default Settings
