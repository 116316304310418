import React, { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import { ISearchCandidateResponse } from '../../../../types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useSearchParams } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import ListItemText from '@mui/material/ListItemText'
import Fetcher from '../../../../utils/Fetcher'
import EmployDialog from '../EmployDialog'
import { EmptyResultsPlaceholder } from './EmptyResultsPlaceholder'

interface Props {
  data?: ISearchCandidateResponse
}

const ResultsTabPanel: React.FC<Props> = ({ data }) => {
  let [searchParams, setSearchParams] = useSearchParams()

  // Update page when user clicks on pagination
  const handleChangePage = (_, newPage) => {
    searchParams.set('page', newPage) // MUI pagination is zero-based, API is one-based
    setSearchParams(searchParams)
  }

  // on response arrival, update page to the one returned by the API
  useEffect(() => {
    if (data && data.currentPage !== parseInt(searchParams.get('page'))) {
      searchParams.set('page', data.currentPage.toString())
      setSearchParams(searchParams)
    }
  }, [data])

  const description = (
    <Typography>
      {data ? `${data.total} risultati` : 'Caricamento risultati...'}
    </Typography>
  )

  return data ? (
    data.results.length === 0 ? (
      <EmptyResultsPlaceholder />
    ) : (
      <>
        <Pagination
          onChange={handleChangePage}
          count={Math.ceil(data.total / data.perPage)}
          page={data.currentPage}
          sx={{ p: 2 }}
        />
        <TableContainer sx={{ bgcolor: 'background.paper' }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell>Nome</TableCell>
                {data.showScore && <TableCell align="right">Pertinenza</TableCell>}
                <TableCell align="right">Ultima modifica</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRows data={data} />
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  ) : (
    <></>
  )
}

function TableRows({ data }: { data?: ISearchCandidateResponse }) {
  const [openId, setOpenId] = React.useState(null)

  return (
    <>
      {data
        ? data.results.map((r) => (
          <Row
            result={r}
            key={r.id}
            open={r.id === openId}
            onClick={() => setOpenId(r.id === openId ? null : r.id)}
            showScore={data.showScore}
          />
        ))
        : // {/* Results are not loaded yet */}
        // {/* 20 rows of skeleton */}
        [...Array(20)].map((_, i) => (
          <TableRow
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              <Skeleton variant="text" sx={{ width: '50%' }} />
              <Skeleton variant="text" sx={{ width: '30%' }} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" sx={{ width: '40%' }} />
            </TableCell>
          </TableRow>
        ))}
    </>
  )
}

interface IRowProps {
  showScore?: boolean
  result: ISearchCandidateResponse['results'][0]
  open: boolean
  onClick: () => void
}
function Row({ showScore, result, open, onClick }: IRowProps): JSX.Element {
  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '& td': { borderBottom: 'none' }
        }}
        onClick={onClick}
      >
        <TableCell>
          <ListItemText
            primary={result.name}
            secondary={
              <Typography variant="body2">{result.surname}</Typography>
            }
          />
        </TableCell>

        {showScore && <TableCell align="right">
          <Typography variant="body2">{result.score}</Typography>
        </TableCell>}

        <TableCell align="right">
          <Typography variant="body2">
            {new Date(result.updated_at).toLocaleDateString('it-IT', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit'
            })}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={showScore ? 3 : 2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 1, mb: 5 }}>
              <Stack direction={{ md: 'row' }} gap={1} sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  component="a"
                  href={`/candidates/${result.id}`}
                  sx={{ flexGrow: 1 }}
                >
                  Vedi profilo
                </Button>
                {/* <AddToFavoritesBtn
                  candidateId={result.id}
                  isFavorite={result.is_favorite[0]}
                /> */}
                <EmployDialog candidateId={result.id} />
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default (props) => (
  <Fetcher.SearchResults
    render={({ data }) => <ResultsTabPanel data={data} {...props} />}
    loadingFallback={() => <ResultsTabPanel {...props} />}
  />
)
