function todayDate(): string {
  let d = new Date()
  return normalizeDate(d.getDate(), d.getMonth() + 1, d.getFullYear())
}

function normalizeDate(
  dd: number,
  mm: number,
  yyyy: number,
  sep: string = '/'
): string {
  let ddStr: string = (dd < 10 ? '0' : '') + dd
  let mmStr = (mm < 10 ? '0' : '') + mm
  return ddStr + sep + mmStr + sep + yyyy.toString()
}

export { todayDate }
